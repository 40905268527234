import { styled } from "styled-components";

import MicrosoftIcon from "./assets/logo_microsoft.png";
import GoogleIcon from "./assets/logo_google.png";
import AppleIcon from "./assets/logo_apple.png";
import UserIcon from "./assets/user.svg";
import AccountIcon from "./assets/account.svg";
import AgentsIcon from "./assets/agents.svg";
import CampaignIcon from "./assets/campaign.svg";
import ChannelIcon from "./assets/channel.svg";
import DashboardIcon from "./assets/dashboard.svg";
import ImportsIcon from "./assets/imports.png";
import IntegrationIcon from "./assets/integration.svg";
import ConciergeIcon from "./assets/concierge_floating_button.svg";
import NotificationIcon from "./assets/notification.svg";
import LogoutIcon from "./assets/logout.svg";
import ContactIcon from "./assets/contacts.svg";
import BrainImage from "./assets/brain.png";
import WarningIcon from "./assets/warning.svg";
import CloseIcon from "./assets/x_icon.svg";
import ThumbsUpIcon from "./assets/thumbs_up.svg";
import ThumbsDownIcon from "./assets/thumbs_down.svg";
import Head1Icon from "./assets/head_1.svg";
import NewChatIcon from "./assets/new_chat.svg";
import ArrowRightIcon from "./assets/arrow_right.svg";
import DeleteRedIcon from "./assets/delete_red.svg";
import ArchiveIcon from "./assets/archive.svg";
import RenameIcon from "./assets/rename.svg";
import FavoriteIcon from "./assets/favorite.svg";
import EditPencilIcon from "./assets/edit_pencil.svg"
import EditBlue from "./assets/edit_blue.svg"
import NoteIcon from "./assets/notes.svg";
import AIIcon from "./assets/ai_icon.svg";
import ConversationIcon from "./assets/conversation.svg";
import ExpandContent from "./assets/expand_content.svg";
import InstructionsEditIcon from './assets/instructions_edit_icon.svg';
import VisibilityIcon from './assets/visibility_icon.svg';
import ReloadInstructions from "./assets/reload_instructions.svg"
import LogoAimsByAnn from "./assets/logo_aims_by_ann.svg";
import DeleteGrayIcon from "./assets/delete_gray.svg"
import FileIcon from "./assets/file_icon.svg";
import FileV2Icon from './assets/file_v2.svg';
import CalendarIcon from './assets/calendar_icon.svg';
import DownloadIcon from './assets/download_icon.svg';
import ScheduleIcon from './assets/schedule_icon.svg';
import SMSIcon from './assets/sms_icon.svg';
import EmailIcon from './assets/email_icon.svg';
import CircleAddIcon from './assets/circle_add_icon.svg';
import DefaultTeamIcon from './assets/default_team.svg';
import ArrowBackOutlined from "./assets/arrow_back_outlined.svg";
import ArrowForwardOutlined from "./assets/arrow_forward_outlined.svg";
import AnnIsotype from "./assets/ann_isotype.svg";
import UserAdmin from "./assets/user_admin.svg";
import MainTemplate from "./assets/main_template.svg";
import PencilCard from "./assets/ic_outline-edit.svg"
import Plus from "./assets/plus.svg";
import ExpandMoreOutlined from "./assets/expand_more_outlined.svg";
import PencilDots from "./assets/pencil_dots.svg";
import CarbonUserAvatarFilled from "./assets/carbon_user_avatar_filled.svg";
import MdiCompany from './assets/mdi_company.svg';
import EllipseIndicatorActive from './assets/ellipse_indicator_active.svg';
import EllipseIndicatorInactive from './assets/ellipse_indicator_inactive.svg';
import ArrowDownIcon from './assets/thick_arrow_down.svg';
import ArrowUpIcon from './assets/thick_arrow_up.svg';
import TuneIcon from './assets/tune.svg';
import Magnifier from './assets/lupa.svg'
import ExpandMoreOutlinedBlack from "./assets/expand_more_outlined_black.svg";
import BracketsIcon from "./assets/brackets.svg";
import GearIcon from "./assets/gear.svg";
import ColoredTrashCanIcon from "./assets/colored_trashcan.svg";
import PaperUpload from "./assets/paper_upload.svg";

import AIEditSpark from "./assets/ai_edit_spark.svg";
import DeleteFilled from "./assets/delete_filled.svg";
import Info from "./assets/info.svg";
import Align from "./assets/align.svg"
import Sort from "./assets/sortby.svg"
import Duplicate from "./assets/duplicate_icon.svg"
import Edit from "./assets/edit_icon.svg"
import SettingsIcon from "./assets/settings.svg"
import RoundedSquareMail from "./assets/icon_mail.svg"
import DuplicateOutline from "./assets/duplicate_outline.svg"
import EditOutline from "./assets/edit_outline.svg"
import DeleteOutline from "./assets/delete_outline.svg"
import NotificationsOutlined from "./assets/icon_notification.svg"
import DotsIcon from "./assets/icons_dots.svg"
import ArrowRightOutlined from "./assets/next_page_icon.svg"
import ArrowLeftOutlined from "./assets/back_page_icon.svg"
import CloseIconGray from "./assets/x_icon_gray.svg"
import SearchIcon from "./assets/search_icon.svg"
import CloseLight from "./assets/x_icon_light.svg"
import Building from "./assets/building.svg"

import {useMemo, useState} from "react";

const IconEmun = {
    microsoft: 'microsoft',
    google: 'google',
    apple: 'apple',
    user: 'user',
    account: 'account',
    agents: 'agents',
    campaign: 'campaign',
    channel: 'channel',
    dashboard: 'dashboard',
    imports: 'imports',
    integration: 'integration',
    notification: 'notification',
    contacts: 'contacts',
    logout: 'logout',
    concierge: 'concierge',
    brain: 'brain',
    warning: 'warning',
    close: 'close',
    thumbsUp: 'thumbsUp',
    thumbsDown: 'thumbsDown',
    head1: 'head1',
    newChat: 'newChat',
    arrowRight: 'arrowRight',
    deleteRed: 'deleteRed',
    archive: 'archive',
    rename: 'rename',
    favorite: 'favorite',
    file: 'file',
    editPencil: 'editPencil',
    note: 'note',
    ai: 'ai',
    conversation: 'conversation',
    expand: 'expand',
    instructionsEdit: 'instructionsEdit',
    visibility: 'visibility',
    fileV2: 'fileV2',
    calendar: 'calendar',
    download: 'download',
    schedule: 'schedule',
    sms: 'sms',
    email: 'email',
    circleAdd: 'circleAdd',
    reloadInstructions: 'reloadInstructions',
    aimsByAnn: 'aimsByAnn',
    defaultTeam: 'defaultTeam',
    arrowBackOutlined: 'arrowBackOutlined',
    arrowForwardOutlined: 'arrowForwardOutlined',
    deleteGray: 'deleteGray',
    annIsotype: 'annIsotype',
    userAdmin: 'userAdmin',
    mainTemplate: 'mainTemplate',
    pencilCard: 'pencilCard',
    plus: 'plus',
    expandMoreOutlined: 'expandMoreOutlined',
    pencilDots: 'pencilDots',
    carbonUserAvatarFilled: 'carbonUserAvatarFilled',
    mdiCompany: 'mdiCompany',
    ellipseIndicatorActive: 'ellipseIndicatorActive',
    ellipseIndicatorInactive: 'ellipseIndicatorInactive',
    arrowDown: 'arrowDown',
    arrowUp: 'arrowUp',
    tuneIcon: 'tuneIcon',
    magnifier: 'magnifier',
    expandMoreOutlinedBlack: 'expandMoreOutlinedBlack',
    gear: 'gear',
    brackets: 'brackets',
    coloredTrashcan: 'coloredTrashcan',
    paperUpload: 'paperUpload',
    aiEditSpark: 'aiEditSpark',
    deleteFilled: 'deleteFilled',
    info: 'info',
    align: 'align',
    sort: 'sort',
    duplicate: 'duplicate',
    edit: 'edit',
    settingsIcon: 'settingsIcon',
    roundedSquareMail: 'roundedSquareMail',
    duplicateOutline: 'duplicateOutline',
    editOutline: 'editOutline',
    deleteOutline: 'deleteOutline',
    notificationsOutlined: 'notificationsOutlined',
    dotsIcon: 'dotsIcon',
    arrowRightOutlined: 'arrowRightOutlined',
    arrowLeftOutlined: 'arrowLeftOutlined',
    closeIconGray: 'closeIconGray',
    searchIcon: 'search',
    closeLight: 'closeLight',
    editBlue: 'editBlue',
    building: 'building',
};


const IconStrategy = {
    'microsoft': MicrosoftIcon,
    'google': GoogleIcon,
    'apple': AppleIcon,
    'user': UserIcon,
    'account': AccountIcon,
    'agents': AgentsIcon,
    'campaign': CampaignIcon,
    'channel': ChannelIcon,
    'dashboard': DashboardIcon,
    'imports': ImportsIcon,
    'integration': IntegrationIcon,
    'notification': NotificationIcon,
    'contacts': ContactIcon,
    'logout': LogoutIcon,
    'concierge': ConciergeIcon,
    'brain': BrainImage,
    'warning': WarningIcon,
    'close': CloseIcon,
    'thumbsUp': ThumbsUpIcon,
    'thumbsDown': ThumbsDownIcon,
    'head1': Head1Icon,
    'newChat': NewChatIcon,
    'arrowRight': ArrowRightIcon,
    'deleteRed': DeleteRedIcon,
    'archive': ArchiveIcon,
    'rename': RenameIcon,
    'favorite': FavoriteIcon,
    'file': FileIcon,
    'editPencil': EditPencilIcon,
    'note': NoteIcon,
    'ai': AIIcon,
    'conversation': ConversationIcon,
    'expand': ExpandContent,
    'instructionsEdit': InstructionsEditIcon,
    'visibility': VisibilityIcon,
    'fileV2': FileV2Icon,
    'calendar': CalendarIcon,
    'download': DownloadIcon,
    'schedule': ScheduleIcon,
    'sms': SMSIcon,
    'email': EmailIcon,
    'circleAdd': CircleAddIcon,
    'reloadInstructions': ReloadInstructions,
    'aimsByAnn': LogoAimsByAnn,
    'defaultTeam': DefaultTeamIcon,
    'arrowBackOutlined': ArrowBackOutlined,
    'arrowForwardOutlined': ArrowForwardOutlined,
    'deleteGray': DeleteGrayIcon,
    'annIsotype': AnnIsotype,
    'userAdmin': UserAdmin,
    'mainTemplate': MainTemplate,
    'pencilCard': PencilCard,
    'plus': Plus,
    'expandMoreOutlined': ExpandMoreOutlined,
    'pencilDots': PencilDots,
    'carbonUserAvatarFilled': CarbonUserAvatarFilled,
    'mdiCompany': MdiCompany,
    'ellipseIndicatorActive': EllipseIndicatorActive,
    'ellipseIndicatorInactive': EllipseIndicatorInactive,
    'arrowDown': ArrowDownIcon,
    'arrowUp': ArrowUpIcon,
    'tuneIcon': TuneIcon,
    'magnifier': Magnifier,
    'expandMoreOutlinedBlack': ExpandMoreOutlinedBlack,
    'brackets': BracketsIcon,
    'gear': GearIcon,
    'coloredTrashcan': ColoredTrashCanIcon,
    'paperUpload': PaperUpload,
    'aiEditSpark': AIEditSpark,
    'deleteFilled': DeleteFilled,
    'info': Info,
    'align': Align,
    'sort': Sort,
    'duplicate': Duplicate,
    'edit': Edit,
    'settingsIcon': SettingsIcon,
    'roundedSquareMail': RoundedSquareMail,
    'duplicateOutline': DuplicateOutline,
    'editOutline': EditOutline,
    'deleteOutline': DeleteOutline,
    'notificationsOutlined': NotificationsOutlined,
    'dotsIcon': DotsIcon,
    'arrowRightOutlined': ArrowRightOutlined,
    'arrowLeftOutlined': ArrowLeftOutlined,
    'closeIconGray': CloseIconGray,
    'search': SearchIcon,
    'closeLight': CloseLight,
    'editBlue': EditBlue,
    'building': Building,

};

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    width: ${props => props.width || "auto"};
    height: ${props => props.height || "auto"};
    margin: ${props => props.margin || '0'};
    
    img {
        width: 100%;
        object-fit: cover;
    }
`;

const Icon = ({name, width, height, margin, className }) => {
    const [icons] = useState(IconEmun);

    const onExistName = useMemo(() => {
        return Object.keys(icons).includes(name);
    }, [icons, name]);

    return (
        <IconWrapper width={width} height={height} margin={margin} className={className}>
            {onExistName && <img src={IconStrategy[name]} alt={""}/>}
        </IconWrapper>
    );
}

export default Icon;
