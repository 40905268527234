import { action, makeObservable, observable, toJS } from "mobx";
import { API } from "aws-amplify";
import { getCustomers, putUser, getUsers } from "../../../graphql/queries";
import { PermissionsScopes, PermissionsScopesIndex } from "../../../pages/v2/UsersPage/NewUserPage/consts";

class NewUserStore {
    firstName = '';
    lastName = '';
    phone = '';
    email = '';
    account = '';
    role = '';
    id = "";
    isLoading = false;
    isError = false;
    phoneError = "";
    emailError = "";
    formError = "";
    isSuccessfullModalOpen = false;
    initialPermissionsCatalog = [];
    permissionsCatalog = [];
    intermediatePermissionsStructure = {}
    permissionsLoaded = false;
    permissionsOption = '';
    groups = [];

    constructor() {
        makeObservable(this, {
            firstName: observable,
            lastName: observable,
            phone: observable,
            email: observable,
            account: observable,
            role: observable,
            id: observable,
            isLoading: observable,
            isError: observable,
            phoneError: observable,
            emailError: observable,
            permissionsCatalog: observable,
            permissionsOption: observable,
            intermediatePermissionsStructure: observable,
            permissionsLoaded: observable,
            formError: observable,
            onChangeFirstName: action,
            onChangeLastName: action,
            onChangePhone: action,
            onChangeEmail: action,
            onChangeAccount: action,
            onValidateTextField: action,
            onChangeRole: action,
            onCompleteCreation: action,
            setIsSuccessfulModalOpen: action,
            isSuccessfullModalOpen: observable,
            updatePermissionsCatalog: action,
            setPermissionsOption: action,
            fetchGroups: action,
        });
    }

    onChangeFirstName = (value) => {
        this.formError = "";
        this.firstName = value.trim();
    }

    onChangeLastName = (value) => {
        this.formError = "";
        this.lastName = value.trim();
    }

    onChangeEmail = (value) => {
        this.formError = "";
        this.email = value.trim();
    }

    onChangePhone = (value) => {
        this.phoneError = "";
        const phoneRegex = /^[0-9]+$/;
        const formattedPhone = phoneRegex.test(value.trim());

        if (value.trim().length === 0) {
            this.phone = value.trim();
            return;
        }

        if (formattedPhone) {
            this.phone = value.trim();
        }
    }

    onChangeAccount = (value) => {
        this.account = value;
    }

    onChangeRole = (value) => {
        this.role = value.trim();
    }

    setIsSuccessfulModalOpen (value)  {
        this.isSuccessfullModalOpen = value;
    };

    onValidateTextField = (field) => {
        if (field === 'phone') {
            const formattedPhone = this[field].trim();
            const phoneRegex = /^[0-9]{10}$/;

            if (!phoneRegex.test(formattedPhone)) {
                this.formError = "Please enter a valid phone number.";
                return false;
            } else {
                this.formError = "";
                return true;
            }
        } else if (field === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(this[field].trim())) {
                this.formError = "*Please provide the following information to proceed";
                return false;
            } else {
                this.formError = "";
                return true;
            }
        } else if (field === 'firstName') {
            if (this[field].trim().length === 0) {
                this.formError = "Please enter a first name.";
                return false;
            } else {
                this.formError = "";
                return true;
            }
        } else if (field === 'lastName') {
            if (this[field].trim().length === 0) {
                this.formError = "Please enter a last name.";
                return false;
            } else {
                this.formError = "";
                return true;
            }
        }
        return true;
    }

    onValidateForm = () => {
        const validFirstName = this.onValidateTextField('firstName');
        const validLastName = this.onValidateTextField('lastName');
        const validPhone = this.onValidateTextField('phone');

        if (validFirstName && validLastName && validPhone) {
            this.formError = "";
            return true;
        } else {
            this.formError = "Please fill in all fields correctly.";
            return false;
        }
    }
    
    onValidateObjectField = (field) => {
        return Object.keys(this[field]).length;
    }

    onCompleteCreation = async () => {
        this.isLoading = true;
        try {
            
            const input = {
                id: "",
                first_name: this.firstName,
                last_name: this.lastName,
                email: this.email,
                phone: '+1' + this.phone,
                customer: this.account,
                role_group: this.role || 'superadmin',
                metadata: "{}"
            };

            await API.graphql({
                query: putUser,
                variables: {input: input},
            });

            this.setIsSuccessfulModalOpen(true);
            this.onClearNewUser();
            this.isLoading = false;
            this.isError = false;
        } catch(err){
            this.isLoading = false;
            this.isError = true;
        }
         finally {
            this.setIsSuccessfulModalOpen(true);
        }
    }

    onClearNewUser(){
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.phone = '';
        this.account = '';
        this.role = '';
        this.id = "";
        this.isLoading = false;
        this.isError = false;
        this.phoneError = "";
        this.emailError = "";
        this.formError = "";
        this.isSuccessfullModalOpen = false;
        this.initialPermissionsCatalog = [];
        this.permissionsCatalog = [];
        this.intermediatePermissionsStructure = {}
        this.permissionsLoaded = false;
        this.permissionsOption = '';
    }

    updatePermissionsCatalog(value){
        this.permissionsLoaded = false;
        this.permissionsCatalog = value;
        this.initialPermissionsCatalog = [{ name: "All", code: "ALL", child_permissions: []}, ...value];
        this.setPermissionStructure(value);
        this.permissionsLoaded = true;
    }

    setPermissionStructure(data, initial=true){
        for(const parent of data){
            this.intermediatePermissionsStructure[parent.code] = {
                "set_permissions": parent.permission_levels.reduce((a, val) => ({ ...a, [val]: "none"}), {}),
                "available_scopes": parent.permission_levels.reduce((a, val) => (
                    { ...a, [val]: initial ? PermissionsScopes : [{name: 'None', value: 'none'}]}), {}
                ),
            }
            
            if(parent.child_permissions.length){
                this.setPermissionStructure(parent.child_permissions, false)
            }
        }
    }

    setPermissionLevels(parent, level, scope){
        if(parent.permission_levels.includes(level)){
            this.intermediatePermissionsStructure[parent.code]["set_permissions"][level] = scope;
        }
        
        if(parent.child_permissions.length){
            for(const child of parent.child_permissions){
                this.intermediatePermissionsStructure[child.code]["available_scopes"][level] = PermissionsScopes.slice(0, PermissionsScopesIndex[scope]+1);
                this.setPermissionLevels(child, level, scope);
            }
        }
    }

    filterPermissionDisplayed(value){
        if(value.code === 'ALL'){
            this.permissionsCatalog = this.initialPermissionsCatalog.filter(perm => perm.code !== 'ALL');
            return;
        }
        this.permissionsCatalog = [value];
        
    }

    setPermissionsOption(value) {
        this.permissionsOption = value;
    }

    fetchGroups = () => {
        
        this.isLoading = true;
        try {
            
            const groupsData = [
                { id: 1, name: 'Administrators Staff' },
                { id: 2, name: 'Dealership manager' },
                { id: 3, name: 'Managers' },
                { id: 4, name: 'Regional managers' },
                { id: 5, name: 'HR managers' }
            ];

            this.groups = groupsData;
            this.isLoading = false;
        } catch (error) {
            this.isError = true;
            this.isLoading = false;
        }
    }
}

export default NewUserStore;
