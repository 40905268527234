import { action, makeObservable, observable, flow } from "mobx";
import { API } from "aws-amplify";
import { getContacts } from "../../graphql/queries";

class UnifiedCustomerProfileStore {
    ucp = {};
    notifications = [];
    garage = [];
    selectedCar = null;
    scheduledAppointments = [];
    orders = [];
    parts = [];

    initialLoad = false;
    isLoading = false;
    isError = false;

    constructor() {
        makeObservable(this, {
            ucp: observable,
            initialLoad: observable,
            notifications: observable,
            garage: observable,
            selectedCar: observable,
            scheduledAppointments: observable,
            orders: observable,
            parts: observable,
            isLoading: observable,
            isError: observable,
        
            getUCP: flow,
            onClearUCP: action,
            getScheduledAppointments: action,
            getNotifications: action,
            getGarage: action,
            getOrders: action,
            getParts: action,
            setSelectedCar: action
        });
    }

    onClearUCP() {
        this.ucp = {};
        this.notifications = [];
        this.garage = [];
        this.selectedCar = null;
        this.scheduledAppointments = [];
        this.orders = [];
        this.parts = [];
    
        this.initialLoad = false;
        this.isError = false;
    }

    *getUCP(customer, segment_id) {
        try {
            this.isLoading = true;
            const response = yield API.graphql({
                query: getContacts,
                variables: {
                    input: { customer: customer, segment_id },
                },
            });

            const data = JSON.parse(response.data?.getContacts?.body);
            if(!data.hasOwnProperty(segment_id)) throw new Error();

            this.ucp = data[segment_id];
            this.initialLoad = true;
        } catch (error) {
            this.isError = true;
        } finally {
            this.isLoading = false;
        }
    }

    getScheduledAppointments(){
        this.scheduledAppointments = this.selectedCar.appointments;
    }

    getNotifications(){
        this.notifications = this.ucp.notifications.map((notification, idx) => ({...notification, id: idx+1}));
    }

    getGarage(){
        this.garage = this.ucp.cars.map(car => ({...car, complete_car_name: `${car.brand} ${car.model} ${car.year}`}));;
        this.selectedCar = this.garage[0];
    }

    getOrders(){
        this.orders = this.selectedCar.orders;
    }

    getParts(){
        if(this.selectedCar.parts){
            this.parts = this.selectedCar.parts.map((part, idx) => ({...part, id: idx+1}));
        }
    }

    setSelectedCar(idx){
        this.selectedCar = this.garage[idx];
    };
}

export default UnifiedCustomerProfileStore;
