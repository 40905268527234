/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUsers = /* GraphQL */ `
  query GetUsers($input: GetUserInput!) {
    getUsers(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putUser = /* GraphQL */ `
  query PutUser($input: PutUserInput!) {
    putUser(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  query DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getChannels = /* GraphQL */ `
  query GetChannels($input: getChannelInput!) {
    getChannels(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getKeys = /* GraphQL */ `
  query GetKeys($input: getKeyInput!) {
    getKeys(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putKey = /* GraphQL */ `
  query PutKey($input: PutKeyInput!) {
    putKey(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteKey = /* GraphQL */ `
  query DeleteKey($input: DeleteKeyInput!) {
    deleteKey(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers($input: GetCustomerInput) {
    getCustomers(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putCustomer = /* GraphQL */ `
  query PutCustomer($input: PutCustomerInput!) {
    putCustomer(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  query DeleteCustomer($input: DeleteAccountInput!) {
    deleteCustomer(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putChannel = /* GraphQL */ `
  query PutChannel($input: PutChannelInput!) {
    putChannel(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteChannel = /* GraphQL */ `
  query DeleteChannel($input: DeleteChannelInput!) {
    deleteChannel(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getUploadUrlFile = /* GraphQL */ `
  query GetUploadUrlFile($input: PutFileInput!) {
    getUploadUrlFile(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getUrlFile = /* GraphQL */ `
  query GetUrlFile($input: GetFileInput!) {
    getUrlFile(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putCampaigns = /* GraphQL */ `
  query PutCampaigns($input: putCampaignsInput!) {
    putCampaigns(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  query DeleteCampaign($input: deleteCampignsInput!) {
    deleteCampaign(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getCampaigns = /* GraphQL */ `
  query GetCampaigns($input: getCampignsInput!) {
    getCampaigns(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getDefaultProjects = /* GraphQL */ `
  query GetDefaultProjects($input: getCampignsDefaultProjectsInput!) {
    getDefaultProjects(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getContactFiles = /* GraphQL */ `
  query GetContactFiles($input: getContactFilesInput!) {
    getContactFiles(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putAgent = /* GraphQL */ `
  query PutAgent($input: PutAgentInput!) {
    putAgent(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getAgent = /* GraphQL */ `
  query GetAgent($input: GetAgentInput!) {
    getAgent(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteAgent = /* GraphQL */ `
  query DeleteAgent($input: DeleteAgentInput!) {
    deleteAgent(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getConversationChatAssistant = /* GraphQL */ `
  query GetConversationChatAssistant($input: AgentConversationInput!) {
    getConversationChatAssistant(input: $input) {
      id
      statusCode
      status
      body
      __typename
    }
  }
`;
export const getCampaignJourneys = /* GraphQL */ `
  query GetCampaignJourneys($input: GetJourneysInput!) {
    getCampaignJourneys(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putCampaignJourneys = /* GraphQL */ `
  query PutCampaignJourneys($input: PutJourneyInput!) {
    putCampaignJourneys(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getConversations = /* GraphQL */ `
  query GetConversations($input: GetConversationsInput!) {
    getConversations(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const putScrapping = /* GraphQL */ `
  query PutScrapping($input: PutScrappingInput!) {
    putScrapping(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteAgentFile = /* GraphQL */ `
  query DeleteAgentFile($input: DeleteAgentFileInput!) {
    deleteAgentFile(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getContacts = /* GraphQL */ `
  query GetContacts($input: getContactsInput!) {
    getContacts(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getContactsAnn = /* GraphQL */ `
  query GetContactsAnn($input: getContactsInput!) {
    getContactsAnn(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getChromeYears = /* GraphQL */ `
  query GetChromeYears($input: getChromeYearsInput!) {
    getChromeYears(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getChromeMakes = /* GraphQL */ `
  query GetChromeMakes($input: getChromeMakesInput!) {
    getChromeMakes(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getChromeModel = /* GraphQL */ `
  query GetChromeModel($input: getChromeModelInput!) {
    getChromeModel(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getChromeStyle = /* GraphQL */ `
  query GetChromeStyle($input: getChromeStyleInput!) {
    getChromeStyle(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getChromeStyleDetails = /* GraphQL */ `
  query GetChromeStyleDetails($input: getChromeStyleDetailsInput!) {
    getChromeStyleDetails(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getChromeVin = /* GraphQL */ `
  query GetChromeVin($input: getChromeVinInput!) {
    getChromeVin(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
export const getQuicksightEmbedUrl = /* GraphQL */ `
  query GetQuicksightEmbedUrl($input: getQuicksightEmbedUrlInput!) {
    getQuicksightEmbedUrl(input: $input) {
      statusCode
      body
      __typename
    }
  }
`;
