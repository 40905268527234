import React, {useEffect, useRef, useState, useLayoutEffect} from 'react';
import {API, graphqlOperation} from 'aws-amplify';
import {Button, Typography} from '@mui/material';
import {observer} from "mobx-react";
import Spinner from '../../components/common/Spinner';
import {getAgent, getConversationChatAssistant} from '../../graphql/queries';
import {onChatAssistantResponse} from "../../graphql/subscriptions";
import {useNavigate, useSearchParams} from 'react-router-dom';
import ChatWindowComponent from './components/ChatWindowComponent';
import AddIcon from '@mui/icons-material/Add';
import {ChatInput} from '../../elements';
import _ from "lodash";

const ChatTestingWindow = observer(({showHeader=true, customerSelected, agentName, agentId, agentDomain, inputWidth, showNewChatButton}) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [agentIsTyping, setAgentIsTyping] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [customer, setCustomer] = useState();
    const [agents, setAgents] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState("");
    const [agent_params, setAgent_params] = useState({
        message: "",
        campaign_id: "",
        campaign_version: "",
        agent: ""
    });
    const [attachment, setAttachment] = useState();
    const [chatWindowHeight, setChatWindowHeight] = useState(70);

    const [isSendMessageClicked, setIsSendMessageClicked] = useState(false);
    const [shouldCleanThread, setShouldCleanThread] = useState(false);

    const bottomRef = useRef(null);
    const textAreaRef = useRef();

    useEffect(() => {
        const customerFromURI = searchParams.get("customer")
        if(customerFromURI && !customerSelected){
            handleCustomerSelect(customerFromURI)
        }
    }, [searchParams.get("customer")])

    useEffect(() => {
        if(customerSelected){
            setCustomer(customerSelected);
            setAgents([{name: agentName, id: agentId, domain: agentDomain}]);
        }
    }, [customerSelected])

    useEffect(() => {
        if(agentName){
            setSelectedAgent({name: agentName, id: agentId, domain: agentDomain});
            setAgent_params((prevParams) => ({
                ...prevParams,
                agent: agentId
            }));
        }
    }, [agentName])

    useEffect(() => {
        const agentFromURI = searchParams.get("agent");
        if (agentFromURI && !agentName) {
            const agentSeledctedFromURI = agents.filter(a => a?.id.includes(agentFromURI)).pop()
            setSelectedAgent(agentSeledctedFromURI)

            setAgent_params((prevParams) => ({
                ...prevParams,
                agent: agentSeledctedFromURI?.id
            }));
        }
    }, [agents]);

    useLayoutEffect(() => {
        const textAreaWrapper = textAreaRef.current;
        if (textAreaWrapper) {
          const textArea = textAreaWrapper.getElementsByTagName('textarea')[0]
    
          textArea.style.height = "0px";
          const scrollHeight = textArea.scrollHeight;

          setChatWindowHeight(Math.max(58, 74 - (100 * scrollHeight / window.innerHeight)))
          textArea.style.height = scrollHeight + "px";
        }
      }, [newMessage]);

    const handleCustomerSelect = (selected) => {
        setCustomer(selected);
        fetchAgents(selected);
    };

    const fetchAgents = async (customer) => {
        try {
            const response = await API.graphql({
                query: getAgent,
                variables: {input: {customer_id: customer}},
            });
            setAgents(JSON.parse(response.data.getAgent.body));
        } catch (error) {
            console.error("Error getting Agents", error);
        }
    };

    const handleCleanThread = () => {
        setMessages([]);
        setAgentIsTyping(false);
        setShouldCleanThread(true);
    }

    const handleSendMessage = async () => {
        if (newMessage.trim() === '') return;

        setIsSendMessageClicked(true);

        let local_msgs = []
        local_msgs = _.union(messages, [])

        setNewMessage("");

        const params = {...agent_params, 
            message: newMessage, 
            customer_id: searchParams.get("customer") || customerSelected, 
            is_concierge: selectedAgent?.domain === 'concierge',
            attachment: JSON.stringify({}),
            create_new_thread: shouldCleanThread,
        };

        if(attachment){
            params['attachment'] = JSON.stringify({file_name: attachment['fileName'], data: attachment['data']})
        }

        try {
            
            let openAiAnswer = null;

            // Try sending the request a maximum of maxRetries times
            let retryAttempt = 0; // Counter for retry attempts
            try {
                let resultConversationOpenAi = await API.graphql({
                    query: getConversationChatAssistant,
                    variables: {input: params},
                });
                if (!resultConversationOpenAi.errors?.length) {
                    setAgentIsTyping(true);
                    const apiRespnse = resultConversationOpenAi.data.getConversationChatAssistant;

                    const parseResponseBody = (responseBody) => JSON.parse(responseBody);

                    local_msgs = _.union(local_msgs, [{message: newMessage, type: "sent"}])
                    let tmpMsg  = "";
                    let initialMessages = [...local_msgs];
                    let isStreaming = false;
                    setMessages(local_msgs);
                    setAttachment({});

                    // Subscribe to chat assistant response
                    let assistantResponseSub = API.graphql(
                        graphqlOperation(onChatAssistantResponse, {id: apiRespnse.id})
                    ).subscribe({
                        next: ({provider, value}) => {
                            const subApiRespnse = value.data.onChatAssistantResponse;
                            const body = parseResponseBody(
                                subApiRespnse.body
                            )
                            openAiAnswer = body.answer;
                            setShowSpinner(false);
                            setAgentIsTyping(false);

                            if (subApiRespnse.status == 'done') {
                                if(!isStreaming){
                                    const newMessages = [...initialMessages, {message: openAiAnswer, type: "received"}];
                                    setMessages(newMessages)
                                    initialMessages = [...newMessages];
                                }
                                assistantResponseSub.unsubscribe();
                                tmpMsg = '';
                                initialMessages = [...messages];
                                isStreaming = false;
                                setIsSendMessageClicked(false);
                            } else{
                                isStreaming = true;
                                tmpMsg += openAiAnswer
                                setMessages([...initialMessages, {message: tmpMsg, type: "received"}])
                            }
                        },
                        error: (error) => {
                            console.warn(error)
                            setShowSpinner(false);
                            setAgentIsTyping(false);
                            setMessages([
                                ...messages,
                                {
                                    message:
                                        "Oops! Something went wrong. Please try again.",
                                    type: "received",
                                },
                            ]);
                        }
                    });

                }
                setShouldCleanThread(false);
            } catch (err) {
                setMessages([
                    ...messages,
                    {
                        message:
                            "Oops! Something went wrong. Please try again.",
                        type: "received",
                    },
                ]);
            }
        } catch (error) {
            console.error(error);

            if (error.errors && error.errors[0].errorType === "Lambda:ExecutionTimeoutException") {
                setMessages([...messages, {
                    message: "Sorry, I'm taking longer than expected to get your answer. I'll notify my team to teach me more about this topic. main while, lets continue with other questions you have?",
                    type: 'received'
                }]);
            }
        }
    };

    // Use useEffect to scroll to the bottom whenever new messages arrive
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        bottomRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
    };

    return (
        <div className="container" style={{width: "100%", marginTop: "50px", marginLeft: "10px", marginRight: "10px"}}>
            <div className="chat-window">

                {showHeader &&  <div style={{
                    padding: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingTop: 0
                }}>
                    <Typography variant="h6" style={{marginBottom: 0}}>Chat
                        with {selectedAgent ? selectedAgent.name || "Agent" : "Agent"}</Typography>
                    <div style={{flexGrow: 1}}/>
                    <Button
                        variant="contained"
                        onClick={() => navigate('/assistants')}
                        startIcon={<AddIcon/>}
                        style={{textTransform: 'none', backgroundColor: 'black'}}
                    >
                        Create New Agent
                    </Button>
                </div>}

                <div className="chat-messages" style={{ overflowY: "auto"}}>
                    {/* Chat header with agent avatar and name */}
                    <ChatWindowComponent showSpinner={showSpinner} messages={messages}
                                         bottomRef={bottomRef} width={'90%'} height={`${chatWindowHeight}vh`} agentIsTyping={agentIsTyping}/>
                    {/*<div ref={bottomRef}/>*/}
                </div>
            </div>

            <div style={{padding: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div>
                    {showSpinner && <Spinner className="spinner"/>} {/* Show spinner if showSpinner is true */}
                </div>
                <ChatInput
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey && !showSpinner) {
                            e.preventDefault();
                            if(!isSendMessageClicked){
                                handleSendMessage();
                            }
                        }
                    }}
                    showSpinner={showSpinner}
                    newMessage={newMessage}
                    handleSendMessage={handleSendMessage}
                    onFileUploaded={setAttachment}
                    textAreaRef={textAreaRef}
                    disableSend={isSendMessageClicked}
                    onCleanThread={handleCleanThread}
                    width={inputWidth || "80%"}
                    showNewChatButton={showNewChatButton}
                />
            </div>
        </div>
    );
});

export default ChatTestingWindow;
