import { Grid } from "@mui/material";
import { API } from "aws-amplify";
import {observer} from "mobx-react";
import React, { useEffect, useState } from "react";
import CommonButton from "../../components/common/CommonButton";
import CommonTextField from "../../components/common/CommonTextField";
import { putCustomer, putScrapping } from "../../graphql/queries";
import {useStore} from "../../hooks";

const CustomerCreate = observer(({
  customerId,
  onFormSubmit,
  onCancel,
  initialData,
  isUpdate = false,
}) => {
  const { globalConstants } = useStore();
  const { onboardingTemplate } = globalConstants;

  const [formData, setFormData] = useState({
    ID: "",
    name: "",
    metadata: {},
    domains: [],
    state: "",
    city: "",
    street: "",
    site_url: "",
  });

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    } else {
      setFormData({
        ID: "",
        name: "",
        metadata: JSON.stringify({ onboarding: onboardingTemplate }),
        domains: [],
        state: "",
        city: "",
        street: "",
        site_url: "",
      });
    }
  }, [initialData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "domains") {
      const domainValues = value.split(",").map((domain) => domain.trim());

      setFormData({
        ...formData,
        [name]: domainValues,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const validateForm = () => {
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const { id, name, metadata, domains, state, city, street, site_url } =
      formData;

    const updatedFormData = {
      id,
      name,
      metadata,
      domains,
      state,
      city,
      street,
      site_url,
    };

    try {
      await API.graphql({
        query: putCustomer,
        variables: { input: updatedFormData },
      });

      if (onFormSubmit) {
        onFormSubmit();
      }

      setFormData({
        ID: "",
        name: "",
        metadata: {},
        domains: [],
        state: "",
        city: "",
        street: "",
        site_url: "",
      });

      // Send data to scrapper
      
      const customerID = (formData.name).replace(/\s/g, '').toLowerCase();

      const scrapping_data = {
        customer: customerID,
        URL: formData.domains[0]
      };

      const srapper_reponse = await API.graphql({
        query: putScrapping,
        variables: { input: scrapping_data },
      });

      console.log("scrapper_reponse", srapper_reponse);

      console.log("Customer Form Submission Success");
    } catch (error) {
      console.error("Error submitting customer form", error);
    }
  };

  return (
    <form onSubmit={handleFormSubmit} style={{ backgroundColor: "white" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CommonTextField
            label="Company Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            fullWidth
            required
            helperText={formData.name ? "" : "Company Name is required"}
          />
        </Grid>

        <Grid item xs={12}>
          <CommonTextField
            label="Domains"
            name="domains"
            value={formData.domains}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <CommonTextField
            label="Street"
            name="street"
            value={formData.street}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <CommonTextField
            label="City"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <CommonTextField
            label="State"
            name="state"
            value={formData.state}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <CommonTextField
            label="Site Url"
            name="site_url"
            value={formData.site_url}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
      </Grid>
      <br />
      <Grid item xs={12} container justifyContent="center" spacing={2}>
        <Grid item>
          <CommonButton label="Cancel" onClick={handleCancel} color="error" />
        </Grid>
        <Grid item>
          <CommonButton label={isUpdate ? "Update" : "Create"} type="submit" />
        </Grid>
      </Grid>
    </form>
  );
});

export default CustomerCreate;
