import {Icon, RoundedButton} from "../../../../../elements";
import React, {useCallback, useMemo, useState} from "react";
import {
  FilterByOptionsWrapper,
  ButtonCloseWrapper,
  FilterByWrapper,
  HeaderOptions,
  FilterOptions,
  FilterOption,
} from "./FilterBy.styled";
import CustomSelect from "../../../../../elements/select/CustomSelect";

const SortBy = ({onSelect}) => {
  const [openSortOptions, setOpenSortOptions] = useState(false);
  const [selectedSort, setSelectedSort] = useState({
    alphabetial: false,
    creationData: false,
    asc: false,
    desc: false,
  });

  const onHandleClick = () => {
    if(openSortOptions === false) { setOpenSortOptions(true); }
  };

  const onCloseFilter = () => {
    setOpenSortOptions(false);
  };

  const onHandleSelectedFilter = () => {
    setOpenSortOptions(false);
    onSelect && onSelect(selectedSort);
  };

  return (
    <FilterByWrapper onClick={onHandleClick}>
      <Icon name={"sort"} height={'24px'} width={'24px'} />
      <FilterByOptionsWrapper open={openSortOptions}>
        <ButtonCloseWrapper onClick={onCloseFilter}><Icon name={'close'} height={'16x'} width={'16px'} /></ButtonCloseWrapper>
        <HeaderOptions>Filters</HeaderOptions>
        <FilterOptions>
          <FilterOption>
            <h3>Alphabetical</h3>
          </FilterOption>
          <FilterOption>
            <h3>Creation Date</h3>
          </FilterOption>
          <FilterOption>
            <h3>Ascending</h3>
          </FilterOption>
          <FilterOption>
            <h3>Descending</h3>
          </FilterOption>
        </FilterOptions>
        <RoundedButton width={"302px"} height={"46px"} onClick={onHandleSelectedFilter}>Apply</RoundedButton>
      </FilterByOptionsWrapper>
    </FilterByWrapper>
  );
}

export default SortBy;