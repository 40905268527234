import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,

} from "@mui/material";
import { API } from "aws-amplify";
import FormControl from "@mui/material/FormControl";
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  createRow,
  useMaterialReactTable,
} from "material-react-table";
import { InputLabel, Select, MenuItem } from '@mui/material';

import Loading from "../../components/common/Loading";
import React, { useEffect, useMemo, useState } from "react";
import { getCampaigns, putCampaigns } from "../../graphql/queries";
import ConfirmationDialog from "../../components/common/CommonConfirmationDialog";
import { CustomBox } from "../../theme/DefaultTheme";
import {useStore} from "../../hooks";
import {observer} from "mobx-react";

const channelSelectionList = ["SMS", "VOICE", "WEBCHAT", "EMAIL", "QNA-WEBCHAT"]

const ChannelList = observer(() => {
  // Loading context
  const { changeTitle, authStore } = useStore();
  const { isLoading, isSuperAdmin, selectedAccount } = authStore;
  changeTitle('Channels');

  const [selectedRow, setSelectedRow] = useState(null);
  const [dialogOpenDelete, setDialogOpenDelete] = useState(false);

  const [campaigns, setCampaigns] = useState([]);
  const [campaign, setCampaign] = useState({});
  const [listChannels, setlistChannels] = useState([])
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [data, setData] = useState([]);
  const [isLoadingCampaignsError, setIsLoadingCampaignsError] = useState(false);
  const [isLoadingChannelsError, setIsLoadingChannelsError] = useState(false);
  const [isCampaign, setIsEditingCampaign] = useState(false)
  const [isFetchingCampaigns, setIsFetchingCampaigns] = useState(false);
  const [isFetchingChannels, setIsFetchingChannels] = useState(false);

  const [isEditingChannel, setIsEditingChannel] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    fetchCampaigns(selectedAccount.id);
    setlistChannels([]);
  }, [selectedAccount]);

  const handleCampaignChange = (e) => {
    const selectedValue = e.target.value
    setSelectedCampaign(selectedValue);
    if (selectedValue) {
      fetchChannels(selectedValue);
    }
  }

  // Fetch campaigns
  const fetchCampaigns = async (customer) => {
    try {
      setIsFetchingCampaigns(true);
      const response = await API.graphql({
        query: getCampaigns,
        variables: { input: { customer: customer } },
      });

      setCampaigns(JSON.parse(response.data?.getCampaigns?.body));
    } catch (error) {
      console.error("Error getting Campaigns", error);
      setIsLoadingCampaignsError(true);
    } finally {
      setIsFetchingCampaigns(false);
    }
  };

  // Fetch channels
  const fetchChannels = async (campaignObject) => {
    try {
      setIsFetchingChannels(true);
      delete campaignObject.active
      delete campaignObject.insert_date
      delete campaignObject.last_modified
      delete campaignObject.metadata
      campaignObject["market"] = ""
      setCampaign(campaignObject)
      const formatArray = Object.entries(campaignObject.channels).map(([channel, address]) => ({
        channel,
        address
      }));
      setlistChannels(formatArray)

    } catch (error) {
      console.error("Error getting Channels", error);
      setIsLoadingChannelsError(true);
    } finally {
      setIsFetchingChannels(false);
    }
  };

  const columns = useMemo(
    () => {
      const baseColumns = [

        {
          accessorKey: "channel",
          header: "Channel",
          editVariant: 'select',
          editSelectOptions: channelSelectionList,
          muiEditTextFieldProps: {
            select: true,
            required: false,
            error: !!validationErrors?.channel,
            helperText: validationErrors?.channel,
            onFocus: () =>
              setValidationErrors({
                ...validationErrors,
                channel: undefined,
              }),
          },
        },
        {
          accessorKey: "address",
          header: "Address",
          muiEditTextFieldProps: {
            type: "text",
            required: false,
            error: !!validationErrors?.address,
            helperText: validationErrors?.address,
            onFocus: () =>
              setValidationErrors({
                ...validationErrors,
                address: undefined,
              }),
          },
        },


      ];

      return baseColumns;
    },
    [validationErrors, isSuperAdmin]
  );

  // Call to put channels
  async function UsePutChannels(values) {
    try {
      setIsEditingChannel(true);
      // add new channel
      campaign.channels[values.channel] = values.address;
      // formating needed for appsync to get the object
      campaign.context = JSON.stringify(campaign.context);
      campaign.first_message = JSON.stringify(campaign.first_message);
      campaign.response_shape = JSON.stringify(campaign.response_shape);
      campaign.instructions = JSON.stringify(campaign.instructions);
      campaign.protected_intents = JSON.stringify(campaign.protected_intents);
      campaign.channels = JSON.stringify(campaign.channels);

      const response = await API.graphql({
        query: putCampaigns,
        variables: { input: campaign },
      });
      // formating needed to work with the object in javascript
      campaign.context = JSON.parse(campaign.context);
      campaign.first_message = JSON.parse(campaign.first_message);
      campaign.response_shape = JSON.parse(campaign.response_shape);
      campaign.instructions = JSON.parse(campaign.instructions);
      campaign.protected_intents = JSON.parse(campaign.protected_intents);
      campaign.channels = JSON.parse(campaign.channels);

      setCampaign(campaign);
      fetchChannels(campaign);
    } catch (error) {
      console.error("Error creating Channel", error);
      setIsLoadingChannelsError(true);
    } finally {
      setIsEditingChannel(false);
    }
  }

  // Call to delete channels
  async function UseDeleteChannels(values) {
    try {
      setIsEditingChannel(true);
      // get the value out
      delete campaign.channels[values.channel]
      // formating needed for appsync to get the object
      campaign.context = JSON.stringify(campaign.context);
      campaign.first_message = JSON.stringify(campaign.first_message);
      campaign.response_shape = JSON.stringify(campaign.response_shape);
      campaign.instructions = JSON.stringify(campaign.instructions);
      campaign.protected_intents = JSON.stringify(campaign.protected_intents);
      campaign.channels = JSON.stringify(campaign.channels);

      const response = await API.graphql({
        query: putCampaigns,
        variables: { input: campaign },
      });
      // formating needed to work with the object in javascript
      campaign.context = JSON.parse(campaign.context);
      campaign.first_message = JSON.parse(campaign.first_message);
      campaign.response_shape = JSON.parse(campaign.response_shape);
      campaign.instructions = JSON.parse(campaign.instructions);
      campaign.protected_intents = JSON.parse(campaign.protected_intents);
      campaign.channels = JSON.parse(campaign.channels);

      setCampaign(campaign);
      fetchChannels(campaign);
    } catch (error) {
      console.error("Error deleting Channel", error);
      setIsLoadingChannelsError(true);
    } finally {
      setIsEditingChannel(false);
    }
  }

  // Handle to create campaign without validations
  const handleCreateChannel = async ({ values, table }) => {
    await UsePutChannels(values);
    table.setCreatingRow(null); //exit creating mode
  };

  // Handle to update campaigns without validations
  const handleSaveChannel = async ({ values, table }) => {
    await UsePutChannels(values);
    table.setEditingRow(null);
  };

  const openDeleteConfirmModal = (row) => {
    setSelectedRow(row);
    setDialogOpenDelete(true);
  };

  const handleConfirmDelete = (row) => {
    if (row) {
      UseDeleteChannels(row.original);
    }
    setDialogOpenDelete(false);
  };

  const table = useMaterialReactTable({

    columns,
    data: listChannels,
    createDisplayMode: "modal", //default ('row', and 'custom' are also available)
    editDisplayMode: "modal", //default ('row', 'cell', 'table', and 'custom' are also available)
    enableExpanding: true,
    enableEditing: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingCampaignsError
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },

    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateChannel,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveChannel,
    //optionally customize modal content
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Create New Channel</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Edit Channel</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <CustomBox display="flex" justifyContent="space-between">
        <div>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="select-label">Campaign Selection</InputLabel>
            <Select
              name="campaignSelection"
              labelId="select-label"
              id="select"
              onChange={handleCampaignChange}
              value={selectedCampaign}
            >
              {campaigns.map((camp) => (
                <MenuItem
                  key={camp.id}
                  value={camp}>
                  {camp.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <Button
            variant="contained"
            onClick={() => {
              table.setCreatingRow(
                createRow(table, {
                  channel: "",
                  address: "",

                })
              );
            }}
          >
            Create New Channel
          </Button>

        </div>
      </CustomBox>
    ),
    state: {
      isLoading: isFetchingChannels || isFetchingCampaigns,
      isSaving: isEditingChannel,
      showAlertBanner: isLoadingChannelsError || isLoadingCampaignsError,
      showProgressBars: isFetchingChannels || isFetchingCampaigns,
    },
  });

  return (
    <div>
      <MaterialReactTable table={table} />
      <ConfirmationDialog
        open={dialogOpenDelete}
        onClose={() => setDialogOpenDelete(false)}
        message="Are you sure you want to delete this channel?"
        onConfirm={() => handleConfirmDelete(selectedRow)}
      />
    </div>);
});

export default ChannelList;
