export const useMockDataTriggerTab = () => {
  return [
    {
      status: 'Active',
      name: 'Massive 21',
      segment: 'Segment',
      contact: '100',
      sent: '900 - 1000',
      reply: '4 - 1000',
      start_date: new Date(),
      end_date: new Date()
    },
    {
      status: 'Finished',
      name: 'Massive 21',
      segment: 'Segment',
      contact: '100',
      sent: '900 - 1000',
      reply: '4 - 1000',
      start_date: new Date(),
      end_date: new Date()
    },
    {
      status: 'Draft',
      name: 'Massive 21',
      segment: 'Segment',
      contact: '100',
      sent: '900 - 1000',
      reply: '4 - 1000',
      start_date: new Date(),
      end_date: new Date()
    },
    {
      status: 'Paused',
      name: 'Massive 21',
      segment: 'Segment',
      contact: '100',
      sent: '900 - 1000',
      reply: '4 - 1000',
      start_date: new Date(),
      end_date: new Date()
    },
    {
      status: 'Stand By',
      name: 'Massive 21',
      segment: 'Segment',
      contact: '100',
      sent: '900 - 1000',
      reply: '4 - 1000',
      start_date: new Date(),
      end_date: new Date()
    }
  ]
}