import { action, makeObservable, observable } from "mobx";
import dayjs from 'dayjs';

class NewConversationStore {
  eventName = "";
  source = "";
  description = "";
  location = "";
  organizer = "";
  startDate = null;
  endDate = null;
  startTime = null;
  endTime = null;
  minEndTime = null;
  sourceOptions = [
    { name: 'CDK Open Repairs Orders', value: 'CDK_Open_Repairs_Orders' },
    { name: 'CDK Closed Repairs Orders', value: 'CDK_Closed_Repairs_Orders' },
    { name: 'VinSolutions Events', value: 'VinSolutions_Events' },
    { name: 'TCC Open Repairs Orders', value: 'TCC_Open_Repairs_Orders' },
    { name: 'TCC Closed Repairs Orders', value: 'TCC_Closed_Repairs_Orders' },
  ];

  constructor() {
    makeObservable(this, {
      eventName: observable,
      source: observable,
      description: observable,
      location: observable,
      organizer: observable,
      startDate: observable,
      endDate: observable,
      startTime: observable,
      endTime: observable,
      minEndTime: observable,
      
      
      fetchEventDetails: action,
      setEventDetails: action,
      updateFormField: action,
      canCreate: action,
      clear: action,
      onCreate: action,
    });
  }

  setEventDetails(data) {
    this.eventName = data.eventName;
    this.source = data.source;
    this.description = data.description;
    this.location = data.location;
    this.organizer = data.organizer;
    this.startDate = dayjs(data.startDate);
    this.endDate = dayjs(data.endDate);
    this.startTime = dayjs(data.startTime);
    this.endTime = dayjs(data.endTime);
  }

  fetchEventDetails() {
    
    

    try {
      
      const response = {
        eventName: "Updated Event",
        source: "VinSolutions Events",
        description: "This is an updated description for the event.",
        location: "San Francisco, CA",
        organizer: "Jane Smith",
        startDate: "2024-08-01T10:00:00",
        endDate: "2024-08-01T12:00:00",
        startTime: "2024-08-01T10:00:00",
        endTime: "2024-08-01T12:00:00"
      };

      this.setEventDetails(response);
      
    } catch (error) {
      
      
    }
  }

  updateFormField(field, value) {
    this[field] = value;
  }

  canCreate() {
    return this.eventName.trim() !== "" && this.startDate !== null;
  }

  clear() {
    this.source = "";
    this.eventName = "";
    this.description = "";
    this.location = "";
    this.organizer = "";
    this.startDate = dayjs();
    this.endDate = null;
    this.startTime = null;
    this.endTime = null;
    this.minEndTime = null;
  }

  onCreate(navigate) {
    if (this.canCreate()) {
      if (navigate) {
        navigate(`/v2/conversations/event_name`);
      }
      this.clear();
    }
  }
}

export default NewConversationStore;
