import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { getQuicksightEmbedUrl } from "../../graphql/queries";
const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

const DashboardsPage = () => {

    const [embedURL, setEmbedURL] = useState("")

    const fetchEmbedUrl = async () => {
        try {
            const response = await API.graphql({
                query: getQuicksightEmbedUrl,
                variables: {
                    input: {
                        "event_type": "GET_EMBED_URL",
                        "dashboard_id": "1b512e21-1678-49a4-9865-f27bf1a0d50f",
                        "user_arn": "arn:aws:quicksight:us-east-1:943437388571:user/default/david.cardenas@dealerlakes.com",
                        "initial_topic_id": "wYBOK5KsTG1YYwPsiBEWxutZp5TEd6ca",
                        "session_lifetime_in_minutes": 60
                    },
                },
            });
            const data = JSON.parse(response.data?.getQuicksightEmbedUrl?.body);
            setEmbedURL(data["generativeqna_url"])
            
        } catch (error) {
            console.error("Error fetching:", error);
        }
    };

    const embedQSearchBar = async() => {    
        const {
            createEmbeddingContext,
        } = QuickSightEmbedding;

        const embeddingContext = await createEmbeddingContext();

        const frameOptions = {
            url: embedURL,
            container: '#experience-container',
            height: "800px",
            width: "100%",
        };

        const embeddedDashboardExperience = await embeddingContext.embedQSearchBar(frameOptions, {});
    };

    useEffect(() => {
        fetchEmbedUrl()
    }, [])

    useEffect(() => {
        if(embedURL){
            embedQSearchBar()
        }
    }, [embedURL])

    return(
        <>
           <div id="experience-container"></div>

        </>
    )
}

export default DashboardsPage