import {action, flow, makeObservable, observable} from "mobx";
import { API } from "aws-amplify";
import {putCustomer, putScrapping} from "../../../graphql/queries";

class CreateOrEditCustomerStore {
  namePage = 'create';

  status = 'idle';

  id = '';
  name = '';
  address = '';
  city  = '';
  state = '';
  postalCode = '';
  postalCodeValid = true;
  domain = '';
  domainValid = false;

  isLoading = false;
  isError = false;

  constructor() {
    makeObservable(this, {
      status: observable,
      name: observable,
      address: observable,
      city: observable,
      state: observable,
      postalCode: observable,
      postalCodeValid: observable,
      domain: observable,
      domainValid: observable,
      isLoading: observable,
      isError: observable,
      onChangeName: action,
      onChangeAddress: action,
      onChangeCity: action,
      onChangeState: action,
      onChangePostalCode: action,
      onChangeDomain: action,
      reset: action,
      onSubmitData: flow,
      setPostalCodeValid: action,
      setDomainValid: action
    });
  }

  setDomainValid = (value) => {
    this.domainValid = value;
  }

  setPostalCodeValid = (value) => {
    this.postalCodeValid = value;
  }

  isNameComplete = () => {
    return this.name !== '' && this.name.length > 0;
  }

  isLocationComplete = () => {
    const existAddress = this.address !== '' && this.address.length > 0;
    const existCity = this.city !== '' && this.city.length > 0;
    const existState = this.state !== '' && this.state.length > 0;
    const existPostalCode = this.postalCode !== '' && this.postalCode.length > 0 && this.postalCodeValid;

    return existAddress && existCity && existState && existPostalCode;
  }

  isDomainComplete = () => {
    return this.domain !== '' && this.domain.length > 0 && this.domainValid;
  }

  onChangeName(value) {
    this.status = 'typing';
    this.name = value;
  }

  onChangeAddress(value) {
    this.status = 'typing';
    this.address = value;
  }

  onChangeCity(value) {
    this.status = 'typing';
    this.city = value;
  }

  onChangeState(value) {
    this.status = 'typing';
    this.state = value;
  }

  onChangePostalCode(value) {
    this.status = 'typing';
    this.postalCode = value;
  }

  onChangeDomain(value) {
    this.status = 'typing';
    this.domain = value;
  }

  *onSubmitData() {
    this.isLoading = true;
    try {
      const onBoardingTemplate = {
        isKeyReady: false,
        isCampaingReady: false,
        isChannelReady: false,
        isAssistantReady: false
      };

      const updatedFormData = {
        id: this.id,
        name: this.name,
        metadata: JSON.stringify({onboarding: onBoardingTemplate}),
        domains: [this.domain],
        site_url: this.domain,
        state: this.state,
        city: this.city,
        street: this.address,
        zip_code: this.postalCode
      };

      yield API.graphql({
        query: putCustomer,
        variables: {input: updatedFormData},
      });

      const customerID = (updatedFormData.name).replace(/\s/g, '').toLowerCase();

      // const scrappingData = {
      //   customer: customerID,
      //   URL: updatedFormData.domains[0]
      // };

      // const srapperResponse = yield API.graphql({
      //   query: putScrapping,
      //   variables: {input: scrappingData},
      // });

      this.status = "done";
    } catch (e) {
      if(e.errors.length && e.errors[0].errorType === 'Lambda:ExecutionTimeoutException'){
        this.status = "done";
        this.isLoading = false;
        this.isError = false;
      }else{
        this.isLoading = false;
        this.isError = true;
        this.status = "error";
      }
    }
  }

  reset() {
    this.name = '';
    this.address = '';
    this.city = '';
    this.state = '';
    this.postalCode = '';
    this.domain = '';
    this.status = "idle";
    this.isLoading = false;
    this.isError = false;
  }
}

export default CreateOrEditCustomerStore;
