import React, {useEffect, useRef, useState, useLayoutEffect} from 'react';
import {API, graphqlOperation} from 'aws-amplify';
import {observer} from "mobx-react";
import {getConversationChatAssistant} from '../../graphql/queries';
import {onChatAssistantResponse} from "../../graphql/subscriptions";
import ChatWindowComponent from './ChatWindowComponent';
import {ChatInput} from '../../elements';
import _ from "lodash";
import { ChatBoxContainer, ChatMessagesWrapper, ChatInputWrapper } from './ChatBox.styled';

const ChatBox = observer(({customerSelected, agentId, agentDomain, inputWidth, showNewChatButton}) => {
    const [agentIsTyping, setAgentIsTyping] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [selectedAgent, setSelectedAgent] = useState("");
    const [agent_params, setAgent_params] = useState({
        message: "",
        campaign_id: "",
        campaign_version: "",
        agent: ""
    });
    const [attachment, setAttachment] = useState();
    const [isSendMessageClicked, setIsSendMessageClicked] = useState(false);
    const [shouldCleanThread, setShouldCleanThread] = useState(false);

    const bottomRef = useRef(null);
    const textAreaRef = useRef();

    useEffect(() => {
        if(agentId){
            setSelectedAgent({id: agentId, domain: agentDomain});
            setAgent_params((prevParams) => ({
                ...prevParams,
                agent: agentId
            }));
        }
    }, [agentId])

    useLayoutEffect(() => {
        const textAreaWrapper = textAreaRef.current;
        if (textAreaWrapper) {
          const textArea = textAreaWrapper.getElementsByTagName('textarea')[0]
    
          textArea.style.height = "0px";
          const scrollHeight = textArea.scrollHeight;

          textArea.style.height = scrollHeight + "px";
        }
      }, [newMessage]);

    const handleCleanThread = () => {
        setMessages([]);
        setAgentIsTyping(false);
        setShouldCleanThread(true);
    }

    const setErrorInChat = () => {
        setAgentIsTyping(false);
        setMessages([
            ...messages,
            {
                message:
                    "Oops! Something went wrong. Please try again.",
                type: "received",
            },
        ]);
    }

    const handleSendMessage = async () => {
        if (newMessage.trim() === '') return;

        setIsSendMessageClicked(true);

        let local_msgs = []
        local_msgs = _.union(messages, [])

        setNewMessage("");

        const params = {...agent_params, 
            message: newMessage, 
            customer_id: customerSelected, 
            is_concierge: selectedAgent?.domain === 'concierge',
            attachment: JSON.stringify({}),
            create_new_thread: shouldCleanThread,
        };

        if(attachment){
            params['attachment'] = JSON.stringify({file_name: attachment['fileName'], data: attachment['data']})
        }

        let openAiAnswer = null;
        try {
            let resultConversationOpenAi = await API.graphql({
                query: getConversationChatAssistant,
                variables: {input: params},
            });
            if (!resultConversationOpenAi.errors?.length) {
                setAgentIsTyping(true);
                const apiRespnse = resultConversationOpenAi.data.getConversationChatAssistant;

                const parseResponseBody = (responseBody) => JSON.parse(responseBody);

                local_msgs = _.union(local_msgs, [{message: newMessage, type: "sent"}])
                let tmpMsg  = "";
                let initialMessages = [...local_msgs];
                let isStreaming = false;
                setMessages(local_msgs);
                setAttachment({});

                // Subscribe to chat assistant response
                let assistantResponseSub = API.graphql(
                    graphqlOperation(onChatAssistantResponse, {id: apiRespnse.id})
                ).subscribe({
                    next: ({provider, value}) => {
                        const subApiRespnse = value.data.onChatAssistantResponse;
                        const body = parseResponseBody(
                            subApiRespnse.body
                        )
                        openAiAnswer = body.answer;
                        setAgentIsTyping(false);

                        if (subApiRespnse.status == 'done') {
                            if(!isStreaming){
                                const newMessages = [...initialMessages, {message: openAiAnswer, type: "received"}];
                                setMessages(newMessages)
                                initialMessages = [...newMessages];
                            }
                            assistantResponseSub.unsubscribe();
                            tmpMsg = '';
                            initialMessages = [...messages];
                            isStreaming = false;
                            setIsSendMessageClicked(false);
                        } else{
                            isStreaming = true;
                            tmpMsg += openAiAnswer
                            setMessages([...initialMessages, {message: tmpMsg, type: "received"}])
                        }
                    },
                    error: (error) => setErrorInChat()
                });

            }
            setShouldCleanThread(false);
        } catch (err) {
            setErrorInChat();
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        bottomRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
    };


    return (
        <ChatBoxContainer>
            <ChatMessagesWrapper>
                <ChatWindowComponent messages={messages}
                    bottomRef={bottomRef} width={'inherit'} height={'inherit'} agentIsTyping={agentIsTyping} backgroundColor={'#FAFAFA'}/>
            </ChatMessagesWrapper>

            <ChatInputWrapper>
                <ChatInput
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey && !agentIsTyping) {
                            e.preventDefault();
                            if(!isSendMessageClicked){
                                handleSendMessage();
                            }
                        }
                    }}
                    newMessage={newMessage}
                    handleSendMessage={handleSendMessage}
                    onFileUploaded={setAttachment}
                    textAreaRef={textAreaRef}
                    disableSend={isSendMessageClicked}
                    onCleanThread={handleCleanThread}
                    width={inputWidth || "80%"}
                    showNewChatButton={showNewChatButton}
                />
            </ChatInputWrapper>
        </ChatBoxContainer>
    );
});

export default ChatBox;
