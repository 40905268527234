import { API } from "@aws-amplify/api";
import { InputLabel, MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React, { useEffect, useState } from "react";
import { getCustomers } from "../../graphql/queries";

const CustomerSelect = (props) => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const {initialCustomer, defaultCustomer} = props;

  const fetchCustomers = async () => {
    try {
      const response = await API.graphql({
        query: getCustomers,
        variables: {
          input: { id: "" },
        },
      });
      const customerList = JSON.parse(response.data?.getCustomers?.body).map(
        ({ name, id }) => ({ name, id })
      );
      const customerSelectList = [...customerList];
      setCustomers(customerSelectList);
    } catch (error) {
      console.error("Error getting customers", error);
    }
  };

  const selectCustomer = (customer) => {
    setSelectedCustomer(customer);
    props.onChange(customer);
  }

  useEffect(() => {
    if(initialCustomer){
      selectCustomer(initialCustomer)
    }
    fetchCustomers();
  }, []);

  useEffect(() => {
    if(defaultCustomer){
      selectCustomer(defaultCustomer)
    }
  }, [defaultCustomer])

  const handleCustomerSelect = (e) => {
    selectCustomer(e.target.value);
  };

  return (
    <div>
      {!initialCustomer && (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="select-label">Select an Account</InputLabel>
          <Select
            labelId="customer-select-label"
            id="customer-select"
            onChange={handleCustomerSelect}
            value={selectedCustomer}
          >
            {customers.map((customer) => (
              <MenuItem key={customer.id} value={customer.id}>
                {customer.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

export default CustomerSelect;