import { API } from "aws-amplify";
import { action, makeObservable, observable, flow, toJS } from "mobx";
import { getUploadUrlFile, getUrlFile } from "../graphql/queries";
import axios from "axios";



class TemplateEditorStore {
  isError = false;
  isLoading = false;

  variables = [];
  from = "";
  to = "";
  subject = "";
  cc = "";
  bcc = "";
  description = "";
  payload = {};
  templateJson = null;
  updatingTemplate = false;

  constructor() {
    makeObservable(this, {
        variables: observable,
        from: observable,
        to: observable,
        subject: observable,
        cc: observable,
        bcc: observable,
        description: observable,
        payload: observable,
        templateJson: observable,
        updatingTemplate: observable,
        newVariable: action,
        updateVariableName: action,
        updateVariableVariable: action,
        updateVariableType: action,
        updateVariableValue: action,
        removeVariable: action,
        updateGeneralField: action,
        updateVariableValueDynamic: action,
        savePayload: action,
        saveTemplate: flow,
        uploadFileToS3: flow,
        loadTemplate: flow,
        clearTemplate: action
    });
  }

  updateGeneralField(field, value){
    this[field] = value;
  }

  newVariable() {
    this.variables.push(
        {
            name: "",
            variable: "",
            type: "constant",
            value: ""
        }
    )
  }

  updateVariableName(idx, value) {
    this.variables[idx].name = value;
  }

  updateVariableVariable(idx, value) {
    this.variables[idx].variable = value;
  }

  updateVariableType(idx, value) {
    this.variables[idx].type = value;
  }

  updateVariableValue(idx, value) {
    this.variables[idx].value = value;
  }

  updateVariableValueDynamic(idx, value, source=false) {
    this.variables[idx].value = {};
    if(source){
      this.variables[idx].value['source'] = value;
    }else{
      this.variables[idx].value['field'] = value;
    }
  }

  removeVariable(idx) {
    this.variables.splice(idx, 1)
  }

  savePayload(){
    const bindingArray = this.variables.map(variable => ({
      name: variable.name,
      type: variable.type,
      variable: `{{${variable.variable}}}`,
      origin: variable.type === 'constant' ? 'fixed' : variable.value?.source,
      value: variable.type === 'constant' ? variable.value : variable.value?.field,
    }))

    const payloadArray = [
      {field: "from", value: this.from},
      {field: "to", value: this.to},
      {field: "subject", value: this.subject},
      {field: "cc", value: this.cc},
      {field: "bcc", value: this.bcc},
      {field: "description", value: this.description},
    ]

    this.payload = {
      binding: bindingArray,
      payload: payloadArray
    }

  }

  *loadTemplate(){
    try {
      this.isLoading = true;
      this.isError = false;
      const response = yield API.graphql({
        query: getUrlFile,
        variables: {
          input: {
            customer: 'bmwretail#iwkhezg1', //TODO: this is intended to be hardcoded
            file_name: 'template.json', //TODO: this is intended to be hardcoded
            file_route: 'email_templates',
          },
        },
      });
  
      const uploadUrl = JSON.parse(response.data.getUrlFile?.body);
  
      const res = yield axios({ url: uploadUrl, method: 'GET', responseType: 'blob' })
      const blobRes = new Blob([res.data]);
      const streamer = yield blobRes.text()
      
      this.templateJson = JSON.parse(streamer)
    } catch (error) {
      this.isError = true;
    } finally{
      this.isLoading = false;
    }
  }

  *uploadFileToS3(content, type, name){
    const blobFile = new Blob([content], {type})

    const response = yield API.graphql({
      query: getUploadUrlFile,
      variables: {
        input: {
          customer: 'bmwretail#iwkhezg1', //TODO: this is intended to be hardcoded
          file_name: name,
          file_type: type,
          file_route: 'email_templates',
          overwrite: true
        },
      },
    });

    const uploadUrl = JSON.parse(response.data.getUploadUrlFile?.body);
    const config = {
      headers: {
        "Content-Type":type,
      },
    };

    yield axios.put(uploadUrl, blobFile, config);

  }

  *saveTemplate(html, json){
    try {
      this.updatingTemplate = true;
      this.isError = false;
      yield this.uploadFileToS3(JSON.stringify(json, null, 2), 'application/json', 'template.json'); //TODO: this is intended to be hardcoded
      yield this.uploadFileToS3(html, 'text/html', 'template.html'); //TODO: this is intended to be hardcoded
    } catch (error) {
      this.isError = true;
    } finally{
      this.updatingTemplate = false;
    }
  }

  clearTemplate(){
    this.isError = false;
    this.isLoading = false;
    this.updatingTemplate = false;
  
    this.variables = [];
    this.from = "";
    this.to = "";
    this.subject = "";
    this.cc = "";
    this.bcc = "";
    this.description = "";
    this.payload = {};
    this.templateJson = null;
  }

}

export default TemplateEditorStore;