import styled, { createGlobalStyle } from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.colors.primaryWhite};
  padding: 56px;
  border-radius: 8px;
  width: 800px;
  height: 600px;
  gap: 8px;
`;

const ModalContent = styled.div`
  position: relative;
  flex: 1;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.primaryWhite};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.thumbGray};
    border-radius: 5px;
    pointer-events: none;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${(props) => props.theme.colors.thumbGray};
  }
`;

const ModalImage = styled.img`
  width: 100px;
  height: auto;
  display: block;
  margin: auto;
`;

const ModalTitle = styled.h4`
    text-align: center;
    margin-bottom: 5px;
`;

const ModalCreatedBy = styled.p`
    margin: 0;
    font-size: 10px;
    color: gray;
    text-align: center;
`;

const ModalDescriptionContainer = styled.div`
    display: flex;
    height: 62px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    text-align:center;
`;

const ModalDescription = styled.p`
    font-size: 10px;
    color: gray;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    text-overflow: ellipsis;
`;

const ModalConversationsAndMembersContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    text-align: center;
`;

const ModalMembersContainer = styled.div`
    border-left: 1px solid #${props => props.theme.colors.border};
`;

const ModalConversations = styled.p`
    margin-bottom: 10px;
`;

const ModalConversationsAndMembersTitle = styled.h5`
    text-align: center;
    margin: 0;
`;

const ModalConversationsAndMembersDescription = styled.p`
    text-align: center;
    font-size: 10px;
    color: gray;
    margin: 0;
`;

const ModalMembers = styled.p`
    margin-bottom: 10px;
`;

const ModalStarters = styled.div`
    padding: 0 14px;
    font-size: 11px;
    margin-top: 5%;
`;

const ModalActions = styled.div`
    padding: 0 14px;
    font-size: 11px;
`;

const KnowledgeBaseTableContainer = styled.div`
    padding: 0 20px;
`;

const StartChatButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2%;
`;

const StartButtonText = styled.span`
    font-weight: normal !important;
    font-size: 11px;
`;

const CustomGenericListStyle = createGlobalStyle`
    #starters div span {
        display: none;
    }

    #starters p:nth-child(1) {
        font-size: 13px;
    }

    #agent-actions div div p:nth-child(1) {
        font-size: 13px;
    }

    #agent-actions > div > div > span {
        display: none;
    }

    #agent-actions > div > ul > div {
        border: 1px solid #D9D9D9;
    }

    #starters > ul > div > button {
        display: none;
    }

    #startchat-container>button {
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    #startchat-container > button > svg {
        width: 15px;
        height: 15px;
    }

    #knowledge-base > div:first-child > p {
        font-size: 13px;
    }

    #knowledge-base > div:nth-child(2) > div > table > thead > tr > th {
        font-size: 13px;
        font-weight: normal;
    }

    #knowledge-base > div:nth-child(2) > div > table > thead > tr > th:last-child {
        display: none;
    }

    #knowledge-base > div:nth-child(2) > div > table > tbody > tr > td {
        font-size: 11px;
        font-weight: normal;
    }

    #knowledge-base > div:nth-child(2) > div > table > tbody > tr > td:last-child {
        display: none;
    }

    #knowledge-base label:nth-child(1) {
        font-size: 11px;
        font-weight: normal;
    }

    #knowledge-base div:nth-child(2) > div:nth-child(2) > div > div > div > div > div {
        font-size: 12px;
    }

    #knowledge-base div:nth-child(2) > div:nth-child(2) > div > div > div > span {
        font-size: 12px;
    }

    #knowledge-base div:nth-child(2) > div:nth-child(2) > div > div > div {
        padding: 0;
    }

    #knowledge-base div:nth-child(2) > div:nth-child(2) {
        min-height: 2rem;
    }

    #knowledge-base > div:nth-child(1) p {
        display: none;
    }

    #vertical-button-container > div:first-child {
        width: auto;
    }

    #vertical-button-container > div:first-child > img {
        margin-top: 10px;
        height: 15px;
    }

    #vertical-button-container > div > ul {
        right: 0px;
        top: 30px;
        background: #FAFAFA;
        padding: 0;
        border-radius: 8px;
    }

    #vertical-button-container > div > ul > li{
        border: 1px solid #CACACA;
        color: #929292;
        padding-top: 2px;
        padding-bottom: 2px;
    }

    #vertical-button-container > div > ul > li img{
        display: none;
    }
`;

const ModalAccessHeaderTitle = styled.h5`
    font-weight: bold;
    padding-left: 14px;
`;

const VerticalButtonsContainer = styled.div`
  position: absolute;
  width: 24px;
  padding: 0 1.5px;
  top: 0px;
  right: 10px;
`;

const ModalFooter = styled.div`
    height: 48x;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.colors.primaryWhite};
    width: 100%;
`;

export {
  ModalOverlay,
  ModalWrapper,
  ModalContent,
  ModalImage,
  ModalTitle,
  ModalCreatedBy,
  ModalDescription,
  ModalDescriptionContainer,
  ModalConversationsAndMembersContainer,
  ModalConversationsAndMembersTitle,
  ModalConversationsAndMembersDescription,
  ModalConversations,
  ModalMembersContainer,
  ModalMembers,
  ModalStarters,
  ModalActions,
  CustomGenericListStyle,
  StartButtonText,
  KnowledgeBaseTableContainer,
  StartChatButtonContainer,
  ModalAccessHeaderTitle,
  VerticalButtonsContainer,
  ModalFooter
};