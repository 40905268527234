import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Amplify, Auth } from "aws-amplify";
import * as React from "react";
import awsconfig from "../../aws-exports";
import "./App.css";
import { ConciergeChat } from '../../pages/Chatbox/components/ConciergeChat';
import {
    Wrapper,
    Menu,
    SubMenu,
    Content,
    Header,
    MenuWrapper,
    TitlePage,
    FloatingChatWrapper,
    OpenConciergeButton,
    CloseConciergeButton,
    PageFooter,
    AccountSelectorWrapper,
    AccountSelectorInitials,
    AccountSelectorContent,
    AccountSelectorContentData
} from "./App.styled";
import {useCallback, useEffect, useState} from "react";
import {Icon} from "../../elements";
import {Outlet, useLocation, Link} from "react-router-dom";
import {observer} from "mobx-react";
import {useStore} from "../../hooks";
import SettingsItems from "../../elements/settings/SettingsItems";
import SettingsIcons from "../../elements/icon/assets/SettingsIcon.svg";
import { Loading } from "../loading";

Amplify.configure(awsconfig);

const theme = createTheme({
    palette: {
        primary: {
            main: "#3D3D3D",
        },
        secondary: {
            main: "#FF5722",
        },
    },
});

const App = observer((/*{ signOut, user }*/) => {
    const [isMenuCollapsed, setIsMenuCollapsed] = useState(true);
    const [isConciergeCollapsed, setIsConciergeCollapsed] = useState(true);
    const { authStore, title } = useStore();
    const location = useLocation();
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);

    useEffect(() => {
        if (authStore.isLoading) {
            authStore.getGlobalInformation();
        }
    }, [authStore]);

    useEffect(() => {
        setIsSettingsOpen(false);
    }, [isMenuCollapsed]);

    const toggleMenu = (value) => {
        setIsMenuCollapsed(value);
    };

    const handleSignOut = () => {
        Auth.signOut()
          .then((data) => authStore.clear())
          .catch((error)=> console.log("error signing out: ", error));
    }

    const handleSettingsClick = () => {
        setIsSettingsOpen(!isSettingsOpen);
    }

    const handleAccountSelector = useCallback((id) => {
        authStore.pickAccount(id);
    },[authStore]);

    return authStore.isLoading ? <Loading /> : <Wrapper isCollapsed={isMenuCollapsed} title={title}>
        <MenuWrapper isCollapsed={isMenuCollapsed} onPointerOver={() => toggleMenu(false)} onPointerEnter={() => toggleMenu(false)} onPointerOut={() => toggleMenu(true)}>
            <Menu isCollapsed={isMenuCollapsed}>
                {(authStore.isSuperAdmin || authStore.isCustomerAdmin) && (
                    <AccountSelectorWrapper isCollapsed={isMenuCollapsed}>
                        <AccountSelectorInitials>{authStore.selectedAccountInitials}</AccountSelectorInitials>
                        <AccountSelectorContent isCollapsed={isMenuCollapsed}>
                            <AccountSelectorContentData>
                                <div>{authStore.selectedAccount.name}</div>
                                <div><span>ID: </span>{authStore.selectedAccount.id}</div>
                            </AccountSelectorContentData>
                            <Icon name="arrowRight" width="10px" height="16px" />
                        </AccountSelectorContent>
                        <SubMenu>
                            <ul>
                                {authStore.sortedAccountListByFavs.map((item) => {
                                    return (<li onClick={() => handleAccountSelector(item.id)}>{item.name}</li>);
                                })}
                            </ul>
                        </SubMenu>
                    </AccountSelectorWrapper>
                )}
                <ul>
                    <li>
                        <Link to="/customers/">
                            <Icon name={"account"}/>
                            <div className="link-menu">Accounts</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/v2/users/">
                            <Icon name={"user"} height={'28px'} width={'28px'}/>
                            <div className="link-menu">Users</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/notifications/">
                            <Icon name={"notification"} height={'28px'} width={'28px'}/>
                            <div className="link-menu">Notifications</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/keys/">
                            <Icon name={"integration"} height={'28px'} width={'28px'}/>
                            <div className="link-menu">Integrations</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/channels">
                            <Icon name={"channel"} height={'28px'} width={'28px'}/>
                            <div className="link-menu">Channels</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/conversations/">
                            <Icon name={"conversation"} height={'28px'} width={'28px'}/>
                            <div className="link-menu">Conversations</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/contacts">
                            <Icon name={"contacts"} height={'28px'} width={'28px'}/>
                            <div className="link-menu">Contacts</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/metrics/*">
                            <Icon name={"dashboard"} height={'28px'} width={'28px'}/>
                            <div className="link-menu">Dashboards</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/v2/assistants/">
                            <Icon name={"agents"} height={'28px'} width={'28px'}/>
                            <div className="link-menu">Agents</div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/home">
                            <Icon name={""} height={'32px'} width={'32px'}/>
                            <div className="link-menu">Home</div>
                        </Link>
                    </li>
                    <li onClick={handleSignOut}>
                        <a>
                            <Icon name={"logout"} height={'32px'} width={'32px'} />
                            <div className="link-menu">Log out</div>
                        </a>
                    </li>
                    {isSettingsOpen && !isMenuCollapsed && <SettingsItems />}
                    <li onClick={handleSettingsClick}>
                        <a>
                            <img src={SettingsIcons} alt="Settings" height={'32px'} width={'32px'} />
                                <div className="link-menu">Settings</div>
                            </a>
                        </li>
                </ul>
            </Menu>

            </MenuWrapper>
            {title && <Header showDivision={title}>
                <TitlePage>{title}</TitlePage>
            </Header>}

            <Content>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Outlet />
                    { (!location.pathname.includes('/home')) && isConciergeCollapsed &&
                        <OpenConciergeButton onClick={() => setIsConciergeCollapsed(!isConciergeCollapsed)}>
                            <Icon name={"concierge"} width={'inherit'} height={'inherit'} />
                        </OpenConciergeButton>
                    }
                </ThemeProvider>
            </Content>
            <PageFooter>Powered by Automotive Neural Networks</PageFooter>
            <FloatingChatWrapper isCollapsed={isConciergeCollapsed} >
                { !isConciergeCollapsed &&
                    <CloseConciergeButton onClick={() => setIsConciergeCollapsed(!isConciergeCollapsed)}>
                        <Icon name={"concierge"} width={'inherit'} height={'inherit'} />
                    </CloseConciergeButton>
                }
                <ConciergeChat
                    isCollapsed={isConciergeCollapsed}
                    onClose={() => setIsConciergeCollapsed(true)}
                />
            </FloatingChatWrapper>
        </Wrapper>;
});

export default App;
