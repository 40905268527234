import styled from 'styled-components';

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.veryLightGray};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.div`
  font-size: 12px;
  
  display: flex;
  align-items: center;

  span {
    font-weight: normal;
    margin-left: 5px;
  }
`;

export const UserStatus = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;

  & > div {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
`;

export const StatusDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.greenPrimary};
  margin-right: 5px;
`;

export const Divider = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.silverGray};
  margin: 0 10px;
`;

export const StatusText = styled.span`
  margin-left: 10px;
`;
