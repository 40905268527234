import { useState } from "react";
import {ImageSelectorButton, ImageSelectorWrapper, ImageSelectorSettings} from "./ImageSelector.styled";
import PlusSign from "../../icon/assets/plus.svg"

const ImageSelector = ({}) => {
    const [displaySettings, setDisplaySettings] = useState(false);
    const [selectedImage, setSelectedImage] = useState(PlusSign);
    const [userSelectedImage, setUserSelectedImage] = useState('22px');

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
    
        reader.onloadend = () => {
          setSelectedImage(reader.result);
          setUserSelectedImage('70px');
        };
    
        if (file) {
          reader.readAsDataURL(file);
        }
    };

    return (
        <ImageSelectorWrapper>
            <ImageSelectorButton onClick={() => setDisplaySettings(!displaySettings)} $imageSelected={userSelectedImage}>
                <img src={selectedImage}/>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    id="fileInput"
                    style={{display: 'none'}}
                />
            </ImageSelectorButton>
            {displaySettings && <ImageSelectorSettings>
                <li><span><label htmlFor="fileInput">Upload photos</label></span></li>
                <li><span>Use DALL•E</span></li>
            </ImageSelectorSettings>}
        </ImageSelectorWrapper>
    );
};

export default ImageSelector;