import {useCallback, useState} from 'react';
import { IconButton } from '../../../../../elements';
import AgentTeamAvatar from './AgentTeamAvatar';
import AgentTeamName from './AgentTeamName';
import AgentTeamIndicators from './AgentTeamIndicators';
import AgentTeamList from './AgentTeamList';

import {
  SelectorWrapper,
  SelectorContentWrapper,
  SelectorDropdownWrapper,
  SelectorElipsisWrapper,
  SelectorHamburgerWrapper,
  SelectorHamburgerItem
} from "./AgentTeamSelector.styled";
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks';

const AgentTeamSelector = observer(({ onChange }) => {
  const { editAgent } = useStore();

  const [openDropdown, setOpenDropdown] = useState(false);
  const [openHamburger, setOpenHamburger] = useState(false);

  const onHandleOpenDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const onHandleOpenHamburger = () => {
    setOpenHamburger(!openHamburger);
  };

  const onResetDropdown = useCallback((id) => {
    setOpenDropdown(false);
    setOpenHamburger(false);
    onChange && onChange(id);
  }, [onChange]);

  return (
    <SelectorWrapper>
      <AgentTeamAvatar />
      <SelectorContentWrapper>
        <AgentTeamName agentName={editAgent.name} />
        <AgentTeamIndicators status={'Active'} company={editAgent.account} />
      </SelectorContentWrapper>
      <SelectorDropdownWrapper openDropdown={openDropdown}>
        <IconButton
          icon="expandMoreOutlined"
          width="24px"
          height="24px"
          onClick={onHandleOpenDropdown}/>
      </SelectorDropdownWrapper>
      <SelectorElipsisWrapper>
        <IconButton
          icon="pencilDots"
          width="20px"
          height="20px"
          onClick={onHandleOpenHamburger}/>
      </SelectorElipsisWrapper>
      <AgentTeamList isVisible={openDropdown} items={editAgent.agents.map(a => ({name: a.name, value: a.id}))} onSelect={onResetDropdown}/>
      <SelectorHamburgerWrapper isVisible={openHamburger}>
        <SelectorHamburgerItem>Archive</SelectorHamburgerItem>
        <SelectorHamburgerItem>Show Versions</SelectorHamburgerItem>
      </SelectorHamburgerWrapper>
    </SelectorWrapper>
  );
});

export default AgentTeamSelector;
