import TriggerTab from "../components/TriggersTab/TriggerTab";

export const useTabs = () => {
  return [
    {
      label: 'Triggers',
      content: (
        <TriggerTab />
      ),
    },
    {
      label: 'Massive',
      content: (
        <></>
      ),
    },
    {
      label: 'Market of One',
      content: (
        <></>
      ),
    },
  ];
};
