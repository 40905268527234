import React, { useEffect, useState, useMemo, useRef } from "react";
import {
    MaterialReactTable,
    useMaterialReactTable,
} from "material-react-table";

import {
    Header,
    Title,
    AddButton,
    IconContainer
} from "./KnowledgeBaseTable.styled";
import { IconButton } from "../..";
import DetailModal from "./components/DetailModal";
import { format } from "date-fns";
import { observer } from "mobx-react";
import CircleAddIcon from '../../icon/assets/circle_add_icon.svg';
import ConfirmationDialog from "../../../components/common/CommonConfirmationDialog";

const KnowledgeBaseTable = observer(({ fileNames = [], vaultName = "", ownerName = "", onFilesChange, onError, customer = "", fileRoute = "", editAgentStore, extensions = {} }) => {
    const [listItems, setListItems] = useState([]);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const fileInputRef = useRef(null);
    const isInitialized = useRef(false);

    useEffect(() => {
        if (!isInitialized.current) {
            const initialFiles = fileNames.map((file, idx) => ({
                id: `uploaded-${idx}`,
                document: file,
                date: format(new Date(), 'MM/dd/yyyy'),
                vault: vaultName,
                owner: ownerName,
                isNew: false,
                status: 'uploaded'
            }));
            setListItems(initialFiles);
            if (editAgentStore) {
                editAgentStore.filesWithStatus = initialFiles;
            }
            isInitialized.current = true;
        }
    }, [fileNames, vaultName, ownerName, editAgentStore]);

    const columns = useMemo(
        () => {
            const baseColumns = [
                { 
                    accessorKey: "document", 
                    header: "Document",
                    size: 200
                },
                { 
                    accessorKey: "vault", 
                    header: "Vault",
                    grow: true,
                },
                { 
                    accessorKey: "date", 
                    header: "Date of creation",
                    grow: true,
                }
            
            ];
            return baseColumns;
        },
    );

    const table = useMaterialReactTable({
        columns,
        data: listItems,
        positionActionsColumn: 'last',
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: '',
            },
        },
        enableColumnFilters: false,
        enableHiding: false,
        enableDensityToggle: false,
        enableFilters: false,
        enableFullScreenToggle: false,  
        enableMultiSort: false,
        enableSorting: false,
        enableColumnActions: false,
        enableTopToolbar: false,
        enableRowActions: true,
        muiPaginationProps: {
            rowsPerPageOptions: [10],
        },
        muiTablePaperProps: {
            elevation: 0,
            sx: {
                borderRadius: '0',
            },
        },
        muiTableHeadCellProps: {
            sx: {
                backgroundColor: '#F2F2F2',
                fontSize: '18px',
                fontWeight: '500',
                padding: "7px"
            }
        },
        muiTableBodyCellProps: {
            sx: {
                fontSize: '14px',
                fontWeight: '400',
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "150px",
                maxWidth: "200px",
                overflow: "hidden",
                padding: "7px"
            }
        },
        getRowId: (row) => row.id,
        renderRowActions: ({ row }) => (
            <IconContainer>
                {row.original.isNew ? (
                    <>
                        <IconButton icon="paperUpload" width="20px" height="20px" onClick={() => handleUpload(row.original)} />
                        <IconButton icon="deleteGray" width="18px" height="18px" top="2px" onClick={() => handleDeleteNew(row.original)} />
                    </>
                ) : (
                    <>
                        <IconButton icon="visibility" width="20px" height="20px" onClick={() => setSelectedDoc(row.original)} />
                        <IconButton icon="deleteGray" width="18px" height="18px" top="2px" onClick={() => handleDeleteUploaded(row.original)} />
                    </>
                )}
            </IconContainer>
        ),
    });

    const handleAddButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const allowedExtensions = extensions ? Object.values(extensions).flat() : [];

        const newFiles = files
            .filter(file => allowedExtensions.includes(`.${file.name.split('.').pop()}`))
            .map(file => ({
                id: `new-${listItems.length + Math.random()}`,
                document: file.name,
                date: format(new Date(), 'MM/dd/yyyy'),
                vault: vaultName,
                owner: ownerName,
                isNew: true,
                file: file,
                status: 'pending'
            }));

        if (newFiles.length !== files.length) {
            editAgentStore.isError = true;
        }

        const updatedListItems = [...listItems, ...newFiles];
        setListItems(updatedListItems);
        if (editAgentStore) {
            editAgentStore.filesWithStatus = updatedListItems;
        }
    };

    const getAcceptedExtensions = (extensions) => {
        return extensions ? Object.values(extensions).flat().join(',') : '';
    };

    const handleUpload = (file) => {
        const filesToUpload = [file.file];
        const currentUploadedFilesLength = listItems.filter(item => item.status === 'uploaded').length;
        const totalFilesThreshold = 20;

        editAgentStore.uploadFile(
            filesToUpload, 
            currentUploadedFilesLength, 
            totalFilesThreshold, 
            customer, 
            fileRoute, 
            (uploadInfo) => {
                setListItems(prevListItems => {
                    const updatedListItems = prevListItems.map(item =>
                        item.id === file.id ? { ...item, document: uploadInfo.fileName, isNew: false, status: 'uploaded' } : item
                    );
                    if (editAgentStore) {
                        editAgentStore.filesWithStatus = updatedListItems;
                    }
                    return updatedListItems;
                });

                if (onFilesChange) onFilesChange(uploadInfo.uploadUrl);
            },
            onError
        );
    };

    const handleDeleteNew = (file) => {
        const updatedListItems = listItems.filter(item => item.id !== file.id);
        setListItems(updatedListItems);
        if (editAgentStore) {
            editAgentStore.filesWithStatus = updatedListItems;
        }
    };

    const handleDeleteUploaded = (file) => {
        if (editAgentStore) {
            editAgentStore.displayDeleteFileDialog(file.document);
        }
    };

    const handleConfirmDelete = () => {
        editAgentStore.confirmRemoveFile().then(() => {
            const updatedListItems = editAgentStore.filesWithStatus;
            setListItems(updatedListItems);
        }).catch(onError);
    };

    return (
        <>
            <Header>
                <Title>Knowledge base</Title>
                <AddButton onClick={handleAddButtonClick}>
                    <img src={CircleAddIcon} width={24} height={24} alt="Add" />
                </AddButton>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept={getAcceptedExtensions(extensions)}
                />
            </Header>
            <MaterialReactTable table={table} />
            <DetailModal selectedDocument={selectedDoc} isOpen={!!selectedDoc} onClose={() => setSelectedDoc(null)} />
            <ConfirmationDialog
                open={Boolean(editAgentStore?.deleteFile)}
                onClose={() => editAgentStore?.displayDeleteFileDialog(false)}
                message="Are you sure you want to delete this file from this assistant?"
                onConfirm={handleConfirmDelete}
                maxWidth="xl"
                fullWidth={false}
            />
        </>
    );
});

export default KnowledgeBaseTable;
