import styled from 'styled-components';

const FilterByWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;

    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.clearGray};
    background: ${(props) => props.theme.colors.primaryWhite};
    cursor: pointer;
`;

const FilterByOptionsWrapper = styled.div`
    position: absolute;
    width: 350px;
    display: ${(props) => props.open ? 'flex' : 'none'};
    flex-direction: column;
    gap: 24px;
    top: 55px;
    right: 0;
    z-index: 2;
    
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.primaryWhite};
    box-shadow: -4px 0px 6.3px 0px rgba(0, 0, 0, 0.10);
    padding: 24px;
`;

const ButtonCloseWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    top: 24px;
    right: 24px;
`;

const HeaderOptions = styled.div`
    ${(props) => props.theme.fonts.variant.semiBold()};
    font-size: 1.5em;
    line-height: normal;
    letter-spacing: 0.25px;
`;

const FilterOptions = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    & h3 {
        color: ${(props) => props.theme.colors.black};
        font-size: 0.875em;
        ${(props) => props.theme.fonts.variant.regular()};
        padding-bottom: 8px;
        margin: 0;
    }
    
    & div {
        width: 100%;
    }
`;

const FilterOption = styled.div``;

export {
  FilterByWrapper,
  FilterByOptionsWrapper,
  ButtonCloseWrapper,
  HeaderOptions,
  FilterOptions,
  FilterOption,
};
