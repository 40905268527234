import {observer} from "mobx-react";
import {useStore} from "../../../hooks";

import {
  ViewWrapper,
  HeaderViewWrapper,
  ContentViewWrapper
} from "./ConversationView.styled";
import Tabs from "../../../elements/v2/Tabs/Tabs";
import {useTabs} from "./hook/useTabs";
import HeaderView from "./components/HeaderView/HeaderView";

const ConversationView = observer(() => {
  const { changeTitle } = useStore();
  const tabsConversation = useTabs();

  changeTitle('Conversations Starters');

  return (
    <ViewWrapper>
      <HeaderViewWrapper>
        <HeaderView />
      </HeaderViewWrapper>
      <ContentViewWrapper>
        <Tabs tabs={tabsConversation} />
      </ContentViewWrapper>
    </ViewWrapper>
  );
});

export default ConversationView;
