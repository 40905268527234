import React, { useCallback, useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import ImageSelector from "../../../../../elements/v2/ImageSelector/ImageSelector.jsx";
import InputText from "../../../../../elements/v2/TextInput/TextInput.jsx";
import TextArea from "../../../../../elements/v2/TextArea/TextArea.jsx";
import GenericList from "../../../../../elements/v2/GenericList/GenericList.jsx";
import InstructionsBox from "../../../../../elements/v2/InstructionsBox/InstructionsBox.jsx";
import KnowledgeBaseTable from "../../../../../elements/v2/KnowledgeBaseTable/KnowledgeBaseTable.jsx";
import ActionsList from "../../../../../elements/v2/ActionsCatalog/ActionsList.jsx";
import { useStore } from "../../../../../hooks/index.js";
import { ExpandableComponent } from "../../../../../elements/index.js";
import {
    Title,
    ContainerInnerTab,
} from "../../PlaygroundView.styled.js";
import { assistantListFileRoute, extensions } from "../../../../NewAgentPage/components/consts.js";

const BasicConfigurationTab = observer(() => {
    const { editAgent } = useStore();
    const [showModal, setShowModal] = useState(false);

    const onHandleChangeName = useCallback((e) => {
        editAgent.onChangeName(e.target.value);
    }, [editAgent]);

    const onChangeConversationStarter = useCallback((idx, value) => {
        editAgent.updateConversationStarter(Object.keys(editAgent.conversationStarters)[idx], value);
    });

    const onHandleChangeInstructions = useCallback((value) => {
        editAgent.onChangeInstructions(value);
    }, [editAgent]);

    const onHandleChangeTitle = useCallback((e) => {
        editAgent.onChangeTitle(e.target.value);
    }, [editAgent]);

    return (
        <ContainerInnerTab>
            <ImageSelector/>

            <span>
                <Title>Name</Title>
                <InputText 
                    placeholder="Enter name"  
                    value={editAgent.name} 
                    onChange={onHandleChangeName}
                    displayError={!editAgent.name}
                    validationError="Your agent needs a name before saving the changes."
                    disabled={editAgent.isConcierge}
                />
            </span>

            <span>
                <Title>Description</Title>
                <TextArea 
                    height="120px" 
                    resize="none" 
                    placeholder="Enter description"
                    value={editAgent.title} 
                    onChange={onHandleChangeTitle}
                    disabled={editAgent.isConcierge}
                />
            </span>

            <GenericList 
                title="Starters" 
                listItems={Object.values(editAgent.conversationStarters)} 
                setListItems={onChangeConversationStarter} 
                newItem={(value) => editAgent.newConversationStarter(value)} 
                removeItem={(idx) => editAgent.removeConversationStarter(idx)}
                placeholder={'Type your question starter here'}
            />

            <InstructionsBox
                value={editAgent.instructions} 
                onChange={onHandleChangeInstructions} 
                toggleModal={() => setShowModal(true)}
                instructionsHelper={!editAgent.isConcierge}
            />


            <KnowledgeBaseTable 
                fileNames={editAgent.files} 
                vaultName={editAgent.account}
                ownerName={editAgent.name}
                onFilesChange={editAgent.onChangeFiles}
                onError={editAgent.onSetError}
                customer={editAgent.account}
                fileRoute={assistantListFileRoute}
                editAgentStore={editAgent}
                extensions={extensions}
            />

            <ActionsList agentActions={editAgent.actions} onSave={editAgent.onChangeActions}/>

            {showModal && <ExpandableComponent
              isOpen={showModal}
              title={editAgent.instructions}
              onClose={() => setShowModal(false)}
            />}
        </ContainerInnerTab>
    );
});

export default BasicConfigurationTab;
