import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    height: 45%;
    position: relative;
    right: 0;
    margin-left: 10%;
    margin-bottom: 7.4rem;
`;

export const Item = styled.div`
    position: absolute;
    color: #B8B8B8;
    font-size: 14px;
    font-family: Inter;
    font-weight: 300;
    line-height: 33.33px;
    word-wrap: break-word;
`;

export const Circle = styled.div`
    width: 7px;
    height: 7px;
    position: absolute;
    background: #B8B8B8;
    border-radius: 9999px;
    left: ${props => props.left}px;
    top: ${props => props.top}px;
`;

export const Line = styled.div`
    width: 1px;
    height: 106px;
    position: absolute;
    background: #B8B8B8;
`;

export const Background = styled.div`
    opacity: 0;
    background: #D9D9D9;
`;

export const SettingsItem = styled(Item)`
    left: ${props => props.left}px;
    top: ${props => props.top}px;
`;

export const StyledCircle = styled(Circle)`
    left: 1px;
    top: 41px;
`;

export const StyledLine = styled(Line)`
    left: 4px;
    top: 16px;
`;

export const StyledCircle41 = styled(Circle)`
    top: 41px;
`;

export const StyledCircle70 = styled(Circle)`
    top: 70px;
`;

export const StyledCircle96 = styled(Circle)`
    top: 96px;
`;

export const StyledLink = styled(Link)`
    left: 1px;
`;
