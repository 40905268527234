import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const CommonConfirmationDialog = ({ open, onClose, title = "", message, onConfirm, maxWidth = 'xl', fullWidth = false }) => {

  const contentStyle = {
    textAlign: 'center',
    color: 'black',
    fontSize: '21.15px',
    fontWeight: 300,
    wordWrap: 'break-word',
    marginTop: '100px',
    marginBottom: '50px',
  };

  const buttonStyle = {
    transformOrigin: '0 0',
    background: '#EDEDED',
    borderRadius: '10px',
    marginBottom: '20px'
  };

  const buttonStyleYes = {
    transformOrigin: '0 0',
    background: 'black',
    borderRadius: '10px',
    color: 'white',
    marginBottom: '20px'
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={fullWidth} maxWidth={maxWidth}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
      {message && <div style={contentStyle}>
        {message.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </div>}
    </DialogContent>
      <DialogActions  style={{ justifyContent: 'center' }}>
      <Button onClick={onClose} color="primary" style={buttonStyle}>
      Cancel
    </Button>
        <Button onClick={onConfirm} color="primary" style={buttonStyleYes}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommonConfirmationDialog;
