import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../../../hooks';
import {
  RulesRowWrapper,
  RulesLabel,
  RulesCount,
  ArrowIcon
} from "./GeneralInformation.styled";
import UserProfileCard from './components/UserProfileCard/UserProfileCard';

const GeneralInformation = observer(() => {
  const { newConversationStore } = useStore();

  return (
    <>
      <RulesRowWrapper>
        <RulesLabel>Rules</RulesLabel>
        <RulesCount><span>Number of rules:</span><span className="value"> {newConversationStore.rules}</span></RulesCount>
        <ArrowIcon></ArrowIcon>
      </RulesRowWrapper>

      <RulesRowWrapper>
        <RulesLabel>Segments</RulesLabel>
        <RulesCount><span>Contacts of rules:</span><span className="value"> {newConversationStore.segments}</span></RulesCount>
        <ArrowIcon></ArrowIcon>
      </RulesRowWrapper>

      <RulesRowWrapper>
        <RulesLabel>Actions</RulesLabel>
        <RulesCount><span>Number of actions:</span><span className="value">{newConversationStore.actions} </span></RulesCount>
        <ArrowIcon></ArrowIcon>
      </RulesRowWrapper>

      <RulesRowWrapper>
        <RulesLabel>Agent</RulesLabel>
        <RulesCount>
          <UserProfileCard
            avatarUrl={newConversationStore.avatarUrl}
            userName="Leonard"
            userRole="Finance Assistant"
            isActive={true}
            isPublic={true}
          />
        </RulesCount>
        <ArrowIcon></ArrowIcon>
      </RulesRowWrapper>
    </>
  )
});

export default GeneralInformation;
