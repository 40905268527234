import React, { useState} from "react";
import { 
    ChatHistoryWrapper, 
    ChatHistoryHeader,
    ChatHistoryTabContainer,
    ChatHistoryListContainer,
    ChatHistoryList,
    ActiveTabIndicator,
    ChatHistoryDisplayButton
} from "./ChatHistory.styled.js";
import { Icon } from "../../../elements/index.js";
import ChatHistoryItem from "./ChatHistoryItemSettings.jsx";

const ChatHistory = () => {

    const todayConversations = [
        'Show me conversations per customer',
        'Show me conversations per customer',
        'Show me conversations per customer',
        'Show me conversations per customer',
    ];

    const favoriteConversations = [
        'Could you tell me what is ANN?',
        'How can you help me to optimize processes?',
    ];

    const [selectedTab, setSelectedTab] = useState('today');

    const [displayHistory, setDisplayHistory] = useState(false);

    return (
        <>
        {displayHistory && <ChatHistoryWrapper>
            <ChatHistoryHeader>
                <Icon name="newChat" width="12px" height="12px"/>
                <span> New Conversation </span>
            </ChatHistoryHeader>

            <ChatHistoryTabContainer>
                <div onClick={() => setSelectedTab('today')}>
                    <p>Today</p> <ActiveTabIndicator active={selectedTab === 'today'}/>
                </div>
                <div onClick={() => setSelectedTab('favorite')}>
                    <p>Your Favorites</p> <ActiveTabIndicator active={selectedTab === 'favorite'}/>
                </div>
            </ChatHistoryTabContainer>
                
            <ChatHistoryListContainer>
                <ChatHistoryList>
                    {(selectedTab === 'today' ? todayConversations : favoriteConversations)
                        .map((conversation, idx) => 
                            <ChatHistoryItem key={idx} text={conversation}/>
                    )}
                </ChatHistoryList>
            </ChatHistoryListContainer>
        </ChatHistoryWrapper>}
        <ChatHistoryDisplayButton $display={displayHistory} onClick={() => setDisplayHistory(!displayHistory)}>
            <Icon name="arrowRight" width="10px" height="10px"/>
        </ChatHistoryDisplayButton>
        </>
    );
};

export default ChatHistory;
