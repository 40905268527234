import { useEffect, useRef } from 'react';
import EmailEditor from 'react-email-editor';
import IconTabs from '../Tabs/IconTabs/IconTabs';
import { 
  TemplateEditorWrapper, 
  EmailEditorWrapper, 
  TabsWrapper, 
  Backdrop, 
  TemplateEditorContainer, 
  TemplateEditorFooter, 
  ButtonWrapper 
} from './TemplateEditor.styled';
import GeneralTab from './GeneralTab';
import { RoundedButton } from '../../button';
import VariablesTab from './VariablesTab';
import { observer } from 'mobx-react';
import useStore from '../../../hooks/useStore';
import { toJS } from 'mobx';
import Spinner from '../../../components/common/Spinner';


const TemplateEditor = observer(({isOpen=true, onClose}) => {
    const emailEditorRef = useRef(null);
    const { templateEditorStore } = useStore();

    const updateVariables = () => {
      if(templateEditorStore.variables.length && emailEditorRef.current?.editor){
        const variablesToSet = {}
        for(const variable of templateEditorStore.variables){
          variablesToSet[variable.variable] = {
            name: variable.name,
            value: `{{${variable.variable}}}`,
            sample: `{{${variable.variable}}}`
          }
        }

        emailEditorRef.current.editor.setMergeTags(variablesToSet);
      }
    };

    useEffect(() => {
      templateEditorStore.newVariable();
      templateEditorStore.loadTemplate();
    }, [])

    useEffect(() => {
      if(templateEditorStore.templateJson && emailEditorRef.current?.editor){
        emailEditorRef.current.editor.loadDesign(toJS(templateEditorStore.templateJson))
      }
    }, [templateEditorStore.templateJson])

    const exportHtml = () => {
      const unlayer = emailEditorRef.current?.editor;
  
      unlayer?.exportHtml((data) => {
        const { design, html } = data;
        templateEditorStore.saveTemplate(html, design);
        templateEditorStore.savePayload();
      });
    };

    const closeEditor = () => {
      templateEditorStore.clearTemplate();
      onClose && onClose();
    }

    const tabs = [
        {
          label: 'General',
          icon: 'gear',
          content: (<GeneralTab/>),
        },
        {
          label: 'Variables',
          icon: 'brackets',
          content: (<VariablesTab updateVariables={updateVariables}/>),
        },
      ];
  
    return (
      <Backdrop isOpen={isOpen}>
        <TemplateEditorWrapper>
          <TemplateEditorContainer>
            <TabsWrapper>
                <IconTabs tabs={tabs} />
            </TabsWrapper>
            <EmailEditorWrapper>
                {templateEditorStore.isLoading ? <Spinner/> : <EmailEditor loadDesign ref={emailEditorRef} options={{
                    version: "latest",
                    appearance: {
                      theme: "modern_light"
                    },
                }} />}
            </EmailEditorWrapper>
          </TemplateEditorContainer>
          <TemplateEditorFooter>
            <RoundedButton width="79px" kind='secondary' onClick={closeEditor}>Cancel</RoundedButton>
            <ButtonWrapper>
              <RoundedButton width="89px" onClick={exportHtml} disabled={templateEditorStore.updatingTemplate}>{templateEditorStore.updatingTemplate ? 'Updating...' : 'Update'}</RoundedButton>
            </ButtonWrapper>

          </TemplateEditorFooter>
        </TemplateEditorWrapper>
      </Backdrop>
    );
});

export default TemplateEditor