import styled from "styled-components";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px - 80px);
  margin: 40px 4%;
  gap: 16px;
`;

const LoadngContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px - 80px);
  margin: 40px 4%;
  align-items: center;
  justify-content: center;
`;

const ContainerColumns = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  gap: 23px;
`;

const Column = styled.div`
  flex: 1;
  padding: 8px 16px 24px 16px;
  border: 1px solid ${(props) => props.theme.colors.silverGrayTwo};
  background-color: ${props => props.theme.colors.primaryWhite};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: auto;
`;

const VerticalLine = styled.div`
  width: 1px;
  background-color: ${(props) => props.theme.colors.silverGrayTwo};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
`;

const ActionsButtonWrapper = styled.div`
  display: flex;
  width: 350px;
  height: 50px;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
 `;

const Title = styled.p`
    ${(props) => props.theme.fonts.variant.regular()};
    font-size: ${props => props.theme.fonts.size.b2};
`;

const ContainerInnerTab = styled.section`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 0px;
`;

const CheckboxContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    margin-top: 10px
`;

const RadioButtonContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 10px
`;

export {
  LoadngContainer,
  PageContainer,
  ContainerColumns,
  Column,
  VerticalLine,
  Actions,
  ActionsButtonWrapper,
  Title,
  ContainerInnerTab,
  CheckboxContainer,
  RadioButtonContainer
};
