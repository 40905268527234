import React, { useState } from 'react';
import { TabsContainer, TabsHeader, Tab, TabContent } from './PlaygroundTabs.styled';

const Tabs = ({ tabs, onToggleTab }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
    onToggleTab && onToggleTab(index);
  };

  return (
    <TabsContainer>
      <TabsHeader>
        {tabs.map((tab, index) => (
          <Tab key={index} isActive={activeTab === index} onClick={() => handleTabClick(index)}>
            {tab.label}
          </Tab>
        ))}
      </TabsHeader>
      <TabContent>
        {tabs.map((tab, index) => (activeTab === index && tab.content))}
      </TabContent>
    </TabsContainer>
  );
};

export default Tabs;
