import React, {useState} from "react";
import { 
    ChatHistoryListItem,
    ChatHistoryListItemSettingsBox,
    ChatHistoryListItemWrapper
} from "./ChatHistory.styled.js";
import { Icon } from "../../../elements/index.js";

const ChatHistoryItem = ({key, text}) => {

    const [displaySettings, setDisplaySettings] = useState(false);

    return (
        <ChatHistoryListItemWrapper>
            <ChatHistoryListItem key={key}>
                <span>{text}</span>
                <div onClick={() => setDisplaySettings(!displaySettings)}>...</div>
            </ChatHistoryListItem>
            {displaySettings && <ChatHistoryListItemSettingsBox>
                <li><span>Delete</span> <Icon name="deleteRed" width="12px" height="12px"/></li>
                <li><span>Archive</span> <Icon name="archive" width="12px" height="12px"/></li>
                <li><span>Rename</span> <Icon name="rename" width="12px" height="12px"/></li>
                <li><span>Favorite</span> <Icon name="favorite" width="12px" height="12px"/></li>
            </ChatHistoryListItemSettingsBox>}
        </ChatHistoryListItemWrapper>  
    );
};

export default ChatHistoryItem;
