import { useCallback } from 'react';
import { Icon } from '../../../../../elements';
import { 
  SelectorListItem,
  SelectorListItemAvatarWrapper,
  SelectorListItemName,
  SelectorListItemTeam
}  from './AgentTeamSelector.styled';

const AgentTeamListItem = ({ avatar, name, team, onClick, id }) => {

  const onHandleClick = useCallback(() => {
    onClick && onClick(id);
  }, [id, onClick]);

  return (
    <SelectorListItem onClick={onHandleClick}>
      <SelectorListItemAvatarWrapper>
        { avatar === undefined && <Icon name="carbonUserAvatarFilled" width="24px" height="24px" />}
        { avatar && avatar}
      </SelectorListItemAvatarWrapper>
      <SelectorListItemName>{name}</SelectorListItemName>
      <SelectorListItemTeam>{team}</SelectorListItemTeam>
    </SelectorListItem>
  );
};

export default AgentTeamListItem;
