import { observer } from "mobx-react";
import { useStore } from "../../../hooks";
import { RoundedButton, SuccessfulModal } from '../../../elements';
import { LoadingBox } from '../../../components/loading/Loading.styled';
import { 
  PlaygroundTabs,
  ChatComponent,
  AgentTeamSelector
} from './components';
import {
  LoadngContainer,
  PageContainer,
  Column,
  VerticalLine,
  ContainerColumns,
  Actions,
  ActionsButtonWrapper 
} from './PlaygroundView.styled';
import AdmirationSign from '../../../elements/icon/assets/admiration_sign.svg';
import AgentCompletedModal from "../../NewAgentPage/components/AgentCompletedModal";
import {useCallback, useEffect, useState} from "react";
import {useSearchParams, useNavigate, createSearchParams} from "react-router-dom";
import {ConfirmationModal} from "../../../elements/v2/modals";

const PlaygroundView = observer(() => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { changeTitle, editAgent, agentStore, authStore } = useStore();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ agentID, setAgentID ] = useState(null);
  const [ onConfirmChangeAgent, setOnConfirmChangeAgent ] = useState(false);

  changeTitle('Playground');

  useEffect(() => {
    setIsLoading(true);
    const customerParam = searchParams.get('customer');
    const agentId = searchParams.get('agent');
    const selectedCustomerId = authStore.selectedAccount.id;

    const handleAgentLogic = (customer) => {
      editAgent.clear();

      if (!agentId || agentId === 'undefined') {
        navigate('/assistants');
      } else {
        agentStore.onChangeAccount(customer);
        agentStore.onSelectedAgentById(agentId);
        editAgent.getAgents(customer, agentId);
        editAgent.getAgentPrompter(customer);
        editAgent.onChangeAccount(customer);
        setIsLoading(false);
      }
    };

    if (!customerParam) {
      handleAgentLogic(selectedCustomerId);
    } else if (selectedCustomerId === customerParam) {
      handleAgentLogic(customerParam);
    } else {
      navigate('/v2/assistants');
    }
  }, [agentStore, editAgent, authStore, searchParams, navigate]);

  const onHandleDraft = () => {
    console.log('Draft');
  };

  const onHandlePublish = () => {
    editAgent.updateAgent();
  }

  const onChangeAgent = useCallback((agent) => {
    setAgentID(agent);
    setOnConfirmChangeAgent(true);
  }, [setAgentID, setOnConfirmChangeAgent]);

  const onCancelChangeAgentModal = useCallback(() => {
    setAgentID(null);
    setOnConfirmChangeAgent(false);
  }, [setAgentID, setOnConfirmChangeAgent]);

  const onConfirmChangeAgentModal = () => {
    setOnConfirmChangeAgent(false);
    navigate({
      pathname: "/v2/assistants/playground",
      search: createSearchParams({
        agent: agentID
      }).toString(),
    });
  }

  return (
    <>
      {isLoading && <LoadngContainer><LoadingBox /></LoadngContainer>}
      {!isLoading && <PageContainer>
        <Actions>
          <AgentTeamSelector onChange={onChangeAgent} />
          <ActionsButtonWrapper>
            {!editAgent.isConcierge && (
              <>
                <RoundedButton outline={true} width={"71px"} onClick={onHandleDraft}>Draft</RoundedButton>
                <RoundedButton width={"162px"} onClick={onHandlePublish} disabled={editAgent.isLoading}>{editAgent.isLoading ? 'Loading...' : 'Publish'}</RoundedButton>
              </>
            )}
          </ActionsButtonWrapper>
        </Actions>
        <ContainerColumns>
          <Column>
            <PlaygroundTabs/>
          </Column>
          <VerticalLine />
          <Column>
            <ChatComponent/>
          </Column>
        </ContainerColumns>

        {editAgent.updatedAgentSuccessfully && <AgentCompletedModal description="Agent has been successfully edited." redirectToAssistants={false} agentid={editAgent.id} customerid={editAgent.account} displayFooter={false}/>}

        {editAgent.isError && <SuccessfulModal
          isOpen={editAgent.isError}
          onClose={() => editAgent.onClearError()}
          imageSrc={AdmirationSign}
          title="Oops!"
          subtitle="An error occurred."
          subtitle2="Please try again later."
        />}

        <ConfirmationModal
          isOpen={onConfirmChangeAgent}
          title="Warning"
          message="Are you sure you want to discard the changes in this agent? Any changes made to your agent will be lost. Are you sure you want to proceed?"
          icon="deleteFilled"
          onClose={onCancelChangeAgentModal}
          onConfirm={onConfirmChangeAgentModal}
        />
      </PageContainer>}
    </>
  );
});

export default PlaygroundView;
