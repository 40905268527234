import styled from 'styled-components';

const MainContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;
    background: ${props => props.theme.colors.greyTen};
    padding: 20px 80px;
    border: 1px solid ${props => props.theme.colors.borderGray};
    overflow: hidden;
`

const TabsContainer = styled.div`
  margin: 20px 80px;
`

const BreadCrumbContainer = styled.div `
    order: 1;
    flex: 0 1 auto;
    align-self: auto;
`

const MenuContainer = styled.div`
    order: 1;
    flex: 0 1 auto;
    align-self: auto;
`

const Title = styled.h3`
    margin:0;
`

export {
  MainContainer,
  TabsContainer,
  BreadCrumbContainer,
  MenuContainer,
  Title,
}