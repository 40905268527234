import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: -webkit-fill-available;
`;

export const TabsHeader = styled.div`
  display: flex;
  gap: 10px;
`;

export const Tab = styled.div`
  padding: 9px 16px;
  cursor: pointer;
  border-bottom: 2px solid ${(props) => (props.isActive ? props.theme.colors.activeBlue : 'transparent')};
  color: ${(props) => (props.isActive ? props.theme.colors.activeBlue : props.theme.colors.greyEleven)};
  ${(props) => props.theme.fonts.variant.regular()};
  font-size: 1em;
  line-height: normal;
  
  transition: color 0.3s, border-bottom 0.3s;

  &:hover {
    color: ${(props) => props.theme.colors.activeBlue};
  }
`;

export const TabContent = styled.div`
  padding: 0px;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.primaryWhite};
  }
   
  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.primaryWhite};
  }
`;

export const TabContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
