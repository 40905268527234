import React, { useEffect } from 'react';
import GenericActionCard from './GenericActionCard';
import { observer } from 'mobx-react';

const ActionLibraryCards = observer(({ options, openModalAddAction }) => {

	const customActions = [
		{ iconName: "circleAdd", onClick: () => openModalAddAction(options.type, options.icon, options.title, options.settings) }
	];

	return (
		<GenericActionCard
			options={options}
			openModalAddAction={openModalAddAction}
			customActions={customActions}
		/>
	);
});

export default ActionLibraryCards;