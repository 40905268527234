import {useCallback, useMemo, useState} from "react";
import AgentTeamListItem from "./AgentTeamListItem";

import {
  SelectorListWrapper,
  SelectorListItems,
  AgentTeamListItemGroup
} from "./AgentTeamSelector.styled";
import SearchInput from "./SearchInput";
import { Icon } from "../../../../../elements";
import { useNavigate } from "react-router-dom";

const AgentTeamList = ({items, isVisible, onSelect, clickAddNew}) => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');

  const onHandleSearch = useCallback((value) => {
    setSearchValue(value);
  }, []);

  const onHandleSelectItem = useCallback((item) => {
    setSearchValue('');
    onSelect && onSelect(item);
  }, [onSelect]);

  const onHandleSelectAddNew = () => {
    if(clickAddNew){
      clickAddNew()
    }else {
      navigate({
        pathname: "/v2/assistants/wizard/new-agent",
      });
    }
  };

  const listItems = useMemo(() => {
    return items.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()));
  }, [items, searchValue]);

  return (
    <SelectorListWrapper isVisible={isVisible}>
      <SearchInput defaultValue={searchValue} onChange={onHandleSearch} />
      <SelectorListItems>
        <h3>Agents</h3>
        <AgentTeamListItemGroup>
            <AgentTeamListItem
              name={'Create new'}
              onClick={onHandleSelectAddNew}
              id='addNewAgentButton'
              avatar={<Icon name="circleAdd" width="24px" height="24px" />}
            />
          {listItems.length === 0 && <p>No agents found</p>}
          {listItems.map(item => (
            <AgentTeamListItem
              name={item.name}
              onClick={onHandleSelectItem}
              id={item.value}
            />
          ))}
        </AgentTeamListItemGroup>
      </SelectorListItems>
    </SelectorListWrapper>
  );
}

export default AgentTeamList;
