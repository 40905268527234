import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateAgentsTeamStepper from "./CreateAgentsTeamStepper";
import AgentsTreePage from "./AgentsTree/AgentsTreePage";

const AgentsTeamPage = () => {
  return (
    <Routes>
        <Route index element={<CreateAgentsTeamStepper />} />
        <Route path="tree" element={<AgentsTreePage />} />
        <Route path="new" element={<CreateAgentsTeamStepper />} />  
    </Routes>
  );
};

export default AgentsTeamPage;
