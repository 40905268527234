import { action, makeObservable, observable } from "mobx";
import { API } from "aws-amplify";
import { getUsers, putUser } from "../../graphql/queries";

class ProfileStore {
    first_name = '';
    last_name = '';
    email = '';
    designation = '';
    phone = '';
    userData = null;
    isUpdatingProfile = false;

    isSuccessfullModalOpen = false;

    constructor() {
        makeObservable(this, {
            first_name: observable,
            last_name: observable,
            email: observable,
            designation: observable,
            phone: observable,
            isSuccessfullModalOpen: observable,
            isUpdatingProfile: observable,
            fetchUserData: action,
            updateUser: action,
            onChangeFirstName: action,
            onChangeLastName: action,
            onChangeDesignation: action,
            onChangePhone: action,
            onChangeEmail: action,
            setIsSuccessfulModalOpen: action,
            setIsUpdatingProfile: action,
        });
    }

    fetchUserData = async (userId, customerId) => {
        try {
            const response = await API.graphql({
                query: getUsers,
                variables: { input: { customer: customerId, id: userId } },
            });

            const userData = JSON.parse(response.data?.getUsers?.body);
            this.userData = userData;
            this.first_name = userData.first_name;
            this.last_name = userData.last_name;
            this.email = userData.email;
            this.designation = userData.designation;
            this.phone = userData.phone;
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    }

    updateUser = async () => {
        try {
            this.isUpdatingProfile = true;

            const updatedUserData = {
                id: this.userData.id,
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                phone: this.phone,
                customer: this.userData.customer,
                metadata: "{}",
                role_group: this.userData.role_group
            };

            await API.graphql({
                query: putUser,
                variables: { input: updatedUserData },
            });

            this.isUpdatingProfile = false;
            this.isSuccessfullModalOpen = true;
        } catch (error) {
            console.error("Error updating user data:", error);
        }
    }

    onChangeEmail = action((value) => {
        this.email = value.trim();
    });

    onChangeFirstName = action((value) => {
        this.first_name = value.trim();
    });

    onChangeLastName = action((value) => {
        this.last_name = value.trim();
    });

    onChangeDesignation = action((value) => {
        this.designation = value.trim();
    });

    onChangePhone = action((value) => {
        this.phone = value.trim();
    });

    setIsSuccessfulModalOpen = action((value) => {
        this.isSuccessfullModalOpen = value;
    });

    setIsUpdatingProfile = action((value) => {
        this.isUpdatingProfile = value;
    });
}

export default ProfileStore;
