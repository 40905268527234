import { styled } from "styled-components";
import {useCallback} from "react";

const InputWrapper = styled.input`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '46px'};
    padding: 0 20px;
    border-radius: 7px;
    border: 1px solid ${(props) => props.$error ? props.theme.colors.red : props.theme.colors.grey};
    font-size: ${(props) => props.theme.fonts.size.normal};
    ${(props) => props.theme.fonts.variant.light};
    color: ${(props) => props.theme.colors.primary};
    background-color: ${(props) => props.backgroundColor || 'transparent'};
    box-sizing: border-box;
    
    ::placeholder {
        ${(props) => props.theme.fonts.variant.light};
    }

    &:disabled{
        background-color: ${(props) => props.theme.colors.white};
        border: 1px solid ${(props) => props.theme.colors.lightGrey};
        color: ${(props) => props.theme.colors.grey};
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${(props) => props.margin || '10px 0'};

    span {
        text-align: left;
        font-size: 12px;
        margin-top: 2px;
        color: ${(props) => props.theme.colors.red};
    }
`;

const InputText = ({type = 'text', placeholder, value, onChange, onBlur, width, height, margin,
    backgroundColor, disabled, validationError, displayError=false, className }) => {
    return (
        <InputContainer margin={margin}>
            <InputWrapper
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                width={width}
                backgroundColor={backgroundColor}
                disabled={disabled}
                $error={displayError}
                height={height}
                className={className}
            />
            {displayError &&  <span>{validationError}</span>}
        </InputContainer>
    );
}

export default InputText;
