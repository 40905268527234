import { Route, Routes } from "react-router-dom";
import ConversationView from "./ConversationView";
import EventName from "./EventName/EventName";
import React from "react";

const ConversationPage = () => {
    return (
        <Routes>
            <Route index element={<ConversationView />} />
            <Route path="/event_name" element={<EventName/>} />
        </Routes>
    );
};

export default ConversationPage;
