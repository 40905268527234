import React from 'react';
import { observer } from "mobx-react-lite";
import useStore from "../../../../../hooks/useStore";
import ChatBox from "../../../../../elements/ChatBox/ChatBox";

const ChatComponent = observer(() => {
    const { editAgent } = useStore();
    
    return (
        <ChatBox
            customerSelected={editAgent.account}
            agentId={editAgent.id}
            agentDomain={editAgent.isConcierge}
            inputWidth="80vh"
        />
    );
});

export default ChatComponent;
