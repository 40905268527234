import { action, makeObservable, observable } from "mobx";
import { getActionTitles, getDataActionTitles, getCardLists } from "../../pages/v2/Conversations/EventName/Actions/consts";

class ConversationsActionsStore {
	listOfActionTitles = [];
	listOfActionLibraryCards = [];
	listOfCards = [];
	modalAddActionType = null;
	modalAddActionIcon = null;
	modalAddActionTitle = '';
	modalAddActionDescription = '';
	showSettingsIcon = true;

	constructor() {
		makeObservable(this, {
			listOfActionTitles: observable,
			listOfActionLibraryCards: observable,
			listOfCards: observable,
			modalAddActionType: observable,
			modalAddActionIcon: observable,
			modalAddActionTitle: observable,
			modalAddActionDescription: observable,
			showSettingsIcon: observable,
			onChangeTitles: action,
			onChangeLibraryCards: action,
			onChangeCards: action,
			setModalAddAction: action,
		});
	}

	onChangeTitles() {
		this.listOfActionTitles = getActionTitles;
	}

	onChangeLibraryCards(title) {
		this.listOfActionLibraryCards = getDataActionTitles(title);
	}

	onChangeCards() {
		this.listOfCards = getCardLists();
	}

	setModalAddAction(type, icon, title, description, settings) {
		this.modalAddActionType = type;
		this.modalAddActionIcon = icon;
		this.modalAddActionTitle = title;
		this.modalAddActionDescription = description;
		this.showSettingsIcon = settings;
	}

}

export default ConversationsActionsStore;