import React, { useState, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { Input as BaseInput } from '@mui/base/Input';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import AttachFile from '@mui/icons-material/AttachFile';
import { IconButton as CustomIconButton, SuccessfulModal } from "..";
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { Tooltip } from '@mui/material';
import AdmirationSign from '../../elements/icon/assets/admiration_sign.svg';
import {
  ChatInputWrapper,
  FileAttachmentWrapper,
  DragAndDropIndicator,
  InputButton,
  InputRoot,
  InputElement,
  IconButton,
  InputAdornment
} from './ChatInput.styled';

const Input = forwardRef(function CustomInput(props, ref) {
  const { slots, ...other } = props;
  return (
    <BaseInput
      slots={{
        root: InputRoot,
        textarea: InputElement,
        ...slots,
      }}
      {...other}
      ref={ref}
      multiline={true}
    />
  );
});

Input.propTypes = {
  slots: PropTypes.shape({
    input: PropTypes.elementType,
    root: PropTypes.elementType,
    textarea: PropTypes.elementType,
  }),
};

const defaultPlaceholder = 'Message ANN Agent...';
export default function InputAdornments({
  onChange,
  onKeyPress,
  showSpinner,
  newMessage = '',
  handleSendMessage,
  wrapperStyles = {},
  className = {},
  onFileUploaded,
  textAreaRef,
  placeholder = defaultPlaceholder,
  showFileIcon = true,
  customSendMessageIcon,
  handleCustomIconClick,
  width,
  disableSend,
  onCleanThread = () => {},
  showNewChatButton = true
}) {
  const fileInputId = Math.random();
  const extensions = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    "text/plain",
    "application/pdf",
    "text/csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ];

  const [fileUploaded, setFileUploaded] = useState();
  const [errorPopup, setErrorPopup] = useState('');
  const [droppingFile, setDroppingFile] = useState(false);

  const fileInputRef = useRef();

  const handleFile = (files) => {
    if (files.length) {
      const fileToUpload = files[0];

      if (!extensions.includes(fileToUpload.type)) {
        return setErrorPopup('Please upload a valid format file:*.doc, *.docx, *.pdf, *.txt or *.xlsx.');
      }

      if (fileToUpload.size / 1e+6 > 100) {
        return setErrorPopup(`File exceeds the 100 mb file size limit.`);
      }

      setFileUploaded(files[0].name);
      const reader = new FileReader();

      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        if (reader.error) {
          return setErrorPopup('File processing failed, please try again later.');
        }

        const data = reader.result.split('base64,')[1];
        onFileUploaded({ fileName: files[0].name, data });
      };
    }
  };

  const onRemoveFile = () => {
    setFileUploaded('');
    onFileUploaded({});
    fileInputRef.current.value = "";
  };

  const handleCleanThread = () => {
    onCleanThread();
  }

  return (
    <ChatInputWrapper className={className} wrapperStyles={wrapperStyles} width={width}>
      {fileUploaded &&
        <FileAttachmentWrapper>
          <span>{fileUploaded}</span>
          <CustomIconButton icon="close" width="15px" height="15px" onClick={onRemoveFile} />
        </FileAttachmentWrapper>
      }

      {droppingFile &&
        <DragAndDropIndicator>
          Drop file to attach to message...
        </DragAndDropIndicator>
      }

      <Input
        placeholder={placeholder}
        value={newMessage}
        ref={textAreaRef}
        onChange={onChange}
        onKeyPress={(e) => {onKeyPress(e); if(e.key === 'Enter' && !e.shiftKey)setFileUploaded("")}}
        disabled={showSpinner}
        onDragOver={(e) => {
          e.preventDefault();
          setDroppingFile(true);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          setDroppingFile(false);
        }}
        onDrop={(e) => {
          e.preventDefault();
          setDroppingFile(false);
          handleFile(e.dataTransfer.files);
        }}
        startAdornment={
          showFileIcon && (
            <InputAdornment>
              <input id={`contained-button-file-${fileInputId}`} ref={fileInputRef}
              type="file" style={{ display: 'none' }} onChange={(e) => handleFile(e.target.files)}/>
              {showNewChatButton && (
                <Tooltip title="New chat">
                  <InputButton>
                    <QuestionAnswerOutlinedIcon onClick={() => handleCleanThread(true)}/>
                  </InputButton>
                </Tooltip>
              )}
              <InputButton htmlFor={`contained-button-file-${fileInputId}`}>
                <AttachFile />
              </InputButton>
            </InputAdornment>
          )
        }
        endAdornment={
          <InputAdornment>
            <IconButton 
              onClick={() => {
                if (customSendMessageIcon) {
                  handleCustomIconClick();
                } else {
                  handleSendMessage(newMessage.trim() === "" ? null : newMessage);
                }
                setFileUploaded("");
              }} 
              color="primary" 
              disabled={showSpinner || disableSend } 
              sx={{backgroundColor: 'black', color: 'white', borderRadius: '5px', width: '30px', ":disabled": {"backgroundColor": "grey"}}}>
              {customSendMessageIcon ? customSendMessageIcon : <ArrowUpward />}
            </IconButton>
          </InputAdornment>
        }
      />
      <SuccessfulModal
        isOpen={!!errorPopup}
        onClose={() => setErrorPopup('')}
        imageSrc={AdmirationSign}
        title="Oops!"
        subtitle="An error occurred."
        subtitle2={errorPopup}
        height='300px'
        width='500px'
        zIndex={true}
      />
    </ChatInputWrapper>
  );
}

InputAdornments.propTypes = {
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool.isRequired,
  newMessage: PropTypes.string,
  handleSendMessage: PropTypes.func.isRequired,
  wrapperStyles: PropTypes.object,
  className: PropTypes.object,
  onFileUploaded: PropTypes.func.isRequired,
  textAreaRef: PropTypes.object,
  placeholder: PropTypes.string,
  showFileIcon: PropTypes.bool,
  customSendMessageIcon: PropTypes.node,
  handleCustomIconClick: PropTypes.func,
  width: PropTypes.string,
  disableSend: PropTypes.bool,
  onCleanThread: PropTypes.func,
  showNewChatButton: PropTypes.bool
};
