import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../../../hooks';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '../../../../../../../elements';
import {
  CardWrapper,
  CardHeader,
  CardContent,
  CardRow,
  CardLabel,
  CardValue,
  DescriptionValue,
  DateRow,
  DateColumn,
} from "./CardInformation.styled";
import NewConversationModal from '../../../../../Conversations/components/NewConversationModal/NewConversationModal';

const CardInformation = observer(() => {
  const { newConversationStore } = useStore();
  const [openNewConversationModal, setOpenNewConversationModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    newConversationStore.fetchEventDetails();
  }, [newConversationStore]);

  const handleConfirm = useCallback(() => {
    newConversationStore.onCreate(navigate);
  }, [newConversationStore, navigate]);

  const formatDate = (date) => date ? date.format('MM/DD/YYYY - HH:mm') : '';

  return (
    <>
      <CardWrapper>
        <CardHeader>
          <h3>Event Information</h3>
          <IconButton icon="editBlue" width="15px" height="15px" onClick={() => setOpenNewConversationModal(true)} />
        </CardHeader>
        <CardContent>
          <CardRow>
            <CardLabel>Name:</CardLabel>
            <CardValue>{newConversationStore.eventName}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Event:</CardLabel>
            <CardValue>{newConversationStore.source}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Description:</CardLabel>
            <DescriptionValue>{newConversationStore.description}</DescriptionValue>
          </CardRow>
          <CardRow>
            <CardLabel>Location:</CardLabel>
            <CardValue>{newConversationStore.location}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Organizer:</CardLabel>
            <CardValue>{newConversationStore.organizer}</CardValue>
          </CardRow>
          <h4>Activation Schedule</h4>
          <DateRow>
            <DateColumn>
              <CardLabel>Start date:</CardLabel>
              <CardValue>{formatDate(newConversationStore.startDate)}</CardValue>
            </DateColumn>
            <DateColumn>
              <CardLabel>End date:</CardLabel>
              <CardValue>{formatDate(newConversationStore.endDate)}</CardValue>
            </DateColumn>
          </DateRow>
        </CardContent>
      </CardWrapper>
      <NewConversationModal
        isOpen={openNewConversationModal}
        setIsOpen={setOpenNewConversationModal}
        showSourceSelect={false}
        buttonText='Save'
        onConfirm={handleConfirm}
      />
    </>
  );
});

export default CardInformation;
