import React, { useState } from "react";
import { observer } from 'mobx-react';
import { useStore } from "../../../../../hooks";
import { AgentViewContainer } from "./AgentTabs.styled";
import { Loading } from "../../../../../components/loading";
import ModalAgentsDetail from "../../components/ModalAgentsDetail/ModalAgentsDetail";
import Tabs from "../../../../../elements/v2/Tabs/Tabs"
import PublishedTab from "./PublishedTab";
import DraftTab from "./DrafTab";

const AgentsTabs = observer(() => {
    const { agentStore } = useStore();
    const { viewAgentStore } = agentStore;

    const [isModalOpen, setModalOpen] = useState(false);

    const handleOpenModal = (content) => {
        agentStore.onSelectedAgent(content);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const tabs = [
        {
          label: 'Published',
          content: (
            <PublishedTab handleOpenModal={handleOpenModal} />
          ),
        },
        {
          label: 'Draft',
          content: (
            <DraftTab handleOpenModal={handleOpenModal}/>
          ),
        },
    ];

    return (
        <>
            {viewAgentStore.isLoading && <Loading />}
            {!viewAgentStore.isLoading && (  
                <AgentViewContainer>
                    <Tabs tabs={tabs} />
                </AgentViewContainer>
            )}
            {agentStore.selectedAgent && <ModalAgentsDetail open={isModalOpen} onClose={handleCloseModal} />}
        </>
    );
});

export default AgentsTabs;