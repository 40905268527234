import { action, makeObservable, observable } from "mobx";
import AuthStore from "./auth/AuthStore";
import NewAgentStore from "./agents/pages/NewAgentStore";
import EditAgentStore from "./agents/pages/EditAgentStore";
import CustomerStore from "./customer/CustomerStore";
import NewCampaignStore from "./campaigns/NewCampaignStore";
import ContactStore from "./campaigns/ContactStore";
import UserStore from "./users/UserStore";
import NotificationStore from "./notifications/NotificationStore";
import ProfileStore from "./profile/ProfileStore";
import ViewAgentStore from "./agents/pages/ViewAgentStore";
import NewUserStore from "./users/pages/NewUserStore";
import UnifiedCustomerProfileStore from "./unifiedCustomerProfile/UnifiedCustomerProfile";
import AnnUnifiedCustomerProfileStore from "./annUnifiedCustomerProfile/AnnUnifiedCustomerProfile";
import AgentsTeamsStore from "./agentsTeams/AgentsTeamsStore";
import AgentStore from "./agents/AgentStore";
import TemplateEditorStore from "./TemplateEditorStore";
import NewConversationStore from "./conversations/NewConversationStore"
import AgentTreeStore from "./agentsTeams/AgentTreeStore";
import ConversationsActionsStore  from "./conversations/ConversationsActionsStore";

class Store {
  title = '';

  authStore = new AuthStore();
  customer = new CustomerStore();
  newAgent = new NewAgentStore();
  newUser = new NewUserStore();
  newCampaign = new NewCampaignStore();
  userStore = new UserStore();
  editAgent = new EditAgentStore();
  notificationStore = new NotificationStore();
  profileStore = new ProfileStore();
  contactStore = new ContactStore();
  viewAgentStore = new ViewAgentStore();
  unifiedCustomerProfileStore = new UnifiedCustomerProfileStore();
  annUnifiedCustomerProfileStore = new AnnUnifiedCustomerProfileStore();
  agentsTeamsStore = new AgentsTeamsStore();
  agentStore = new AgentStore();
  templateEditorStore = new TemplateEditorStore();
  newConversationStore = new NewConversationStore();
  agentTreeStore = new AgentTreeStore();
  conversationsActionsStore = new ConversationsActionsStore();

  constructor() {
    makeObservable(this, {
      title: observable,
      authStore: observable,
      newAgent: observable,
      editAgent: observable,
      customer: observable,
      userStore: observable,
      notificationStore: observable,
      profileStore: observable,
      contactStore: observable,
      viewAgentStore: observable,
      unifiedCustomerProfileStore: observable,
      changeTitle: action,
      agentsTeamsStore: observable,
      agentStore: observable,
      templateEditorStore: observable,
      newConversationStore: observable,
      agentTreeStore: observable,
      agentStore: observable,
      conversationsActionsStore: observable
    });
  }

  changeTitle = (title) => {
    this.title = title;
  }

  globalConstants = () => {
    return {
      userRoleGroups: ["superadmin", "customeradmin", "user"],
      onboardingTemplate: {
        isKeyReady: false,
        isCampaingReady: false,
        isChannelReady: false,
        isAssistantReady: false,
      },
    };
  }
}

const DefaultStore = new Store();
export default DefaultStore;
