import {styled} from "styled-components";

const getButtonVariant = (props) => {
    const { kind, $outline, theme } = props;
    switch (kind) {
        case 'primary':
            return !$outline ? `
                background-color: ${theme.colors.primary};
                color: ${theme.colors.white};
                border: 0;
            `: `
                background-color: inherit;
                color: ${theme.colors.primary};  
                border: 1px solid ${theme.colors.primary};
            `;
        case 'secondary':
            return !$outline ? `
                background-color: ${theme.colors.secondary};
                color: ${theme.colors.greyThree};
                border: 0;
            `: `
                background-color: inherit;
                color: ${theme.colors.secondary};  
                border: 1px solid ${theme.colors.primary};
            `;
        case 'tertiary':
            return !$outline ? `
                background-color: ${theme.colors.lightBlue};
                color: ${theme.colors.black};
                border: 0;
            `: `
                background-color: inherit;
                color: ${theme.colors.secondary};  
                border: 1px solid ${theme.colors.primary};
            `;
    }
};

const ButtonWrapper = styled.button`
    height: ${(props) => props.height || "46px"};
    width: ${(props) => props.width || "100%"};
    border-radius: ${props => props.$borderRadius || '10px'};
    border: 0;
    font-size: ${(props) => props.fontSize || props.theme.fonts.size.normal};
    font-family: ${(props) => props.theme.fonts.variant.regular};
    margin: ${(props) => props.margin || "3px 0"};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
    ${(props) => getButtonVariant(props)}
    font-weight: 700;
    
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    &:disabled {
        opacity: 0.5;
    }
`;
export {
    ButtonWrapper
}
