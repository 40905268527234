import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmationDialog from "../../components/common/CommonConfirmationDialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { API } from "aws-amplify";
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  deleteCampaign,
  getCampaigns,
  putCampaigns,
} from "../../graphql/queries";
import { CustomBox } from "../../theme/DefaultTheme";
import {useStore} from "../../hooks";
import {observer} from "mobx-react";

const campaignSelectionList = ["Draft", "Active", "Stopped", "Finished"];

const CampaignList = observer(() => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [dialogOpenDelete, setDialogOpenDelete] = useState(false);
  // Loading context
  const { changeTitle, authStore } = useStore();
  changeTitle('Conversations');
  const { isLoading, isSuperAdmin, customerId, userId, userRoleGroups, selectedAccount } = authStore;

  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [data, setData] = useState([]);
  const [isLoadingCampaignsError, setIsLoadingCampaignsError] = useState(false);
  const [isFetchingCampaigns, setIsFetchingCampaigns] = useState(false);
  const [isEditingCampaign, setIsEditingCampaign] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [campaignNameList, setCampaignNameList] = useState([]);

  useEffect(() => {
    fetchCampaigns(selectedAccount.id);
  }, [selectedAccount]);

  // Fetch campaigns
  const fetchCampaigns = async (customer) => {
    try {
      setIsFetchingCampaigns(true);
      const response = await API.graphql({
        query: getCampaigns,
        variables: { input: { customer: customer } },
      });
      const rawCampaigns = JSON.parse(response.data?.getCampaigns?.body);

      for (let i = 0; i < rawCampaigns.length; i++) {
        rawCampaigns[i].response_shape = JSON.stringify(
          rawCampaigns[i].response_shape
        );
        rawCampaigns[i].first_message = JSON.stringify(
          rawCampaigns[i].first_message
        );
        rawCampaigns[i].protected_intents = JSON.stringify(
          rawCampaigns[i].protected_intents
        );
      }
      setCampaigns(rawCampaigns);
    } catch (error) {
      console.error("Error getting Campaigns", error);
      setIsLoadingCampaignsError(true);
    } finally {
      setIsFetchingCampaigns(false);
    }
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        accessorKey: "id",
        header: "ID",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "name",
        header: "Conversation Name",
        muiEditTextFieldProps: {
          type: "text",
          required: false,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              name: undefined,
            }),
        },
      },
      {
        accessorKey: "start_datetime",
        header: "Start Date",
        muiEditTextFieldProps: {
          type: "text",
          required: false,
          error: !!validationErrors?.start_datetime,
          helperText: validationErrors?.start_datetime,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              start_datetime: undefined,
            }),
        },
      },
      {
        accessorKey: "end_datetime",
        header: "End Date",
        muiEditTextFieldProps: {
          type: "text",
          required: false,
          error: !!validationErrors?.end_datetime,
          helperText: validationErrors?.end_datetime,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              end_datetime: undefined,
            }),
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        editSelectOptions: campaignSelectionList,
        muiEditTextFieldProps: {
          select: true,
          required: true,
          error: !!validationErrors?.channel,
          helperText: validationErrors?.channel,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              channel: undefined,
            }),
        },
      },
      {
        accessorKey: "external_id",
        header: "External Id",
        muiEditTextFieldProps: {
          type: "text",
          required: false,
          error: !!validationErrors?.external_id,
          helperText: validationErrors?.external_id,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              external_id: undefined,
            }),
        },
      },
      {
        accessorKey: "insert_date",
        header: "Creation Date",
        muiEditTextFieldProps: {
          type: "text",
          required: false,
          error: !!validationErrors?.insert_date,
          helperText: validationErrors?.insert_date,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              insert_date: undefined,
            }),
        },
      }
    ];

    return baseColumns;
  }, [validationErrors, isSuperAdmin]);

  // Call to put campaign
  async function UsePutCampaign(values) {
    try {
      setIsLoadingCampaignsError(false);
      setIsEditingCampaign(true);
      const contextFormat = { v1: values.context };
      const instructionsFormat = { v1: values.instructions };
      values.context = JSON.stringify(contextFormat);
      values.instructions = JSON.stringify(instructionsFormat);
      if (!values.channels) {
        values.channels = JSON.stringify({});
      }

      if (values.protected_intents == "") {
        values.protected_intents = JSON.stringify({});
      }
      if (values.first_message == "") {
        values.first_message = JSON.stringify({});
      }
      if (values.response_shape == "") {
        values.response_shape = JSON.stringify({});
      }
      values.market = "";

      values.metadata = JSON.stringify({
        pinpoint: { app_id: values.pinpoint_id ?? "" },
      });

      const response = await API.graphql({
        query: putCampaigns,
        variables: {
          input: {
            customer: selectedAccount.id,
            name: values.name,
            context: values.context,
            first_message: values.first_message,
            response_shape: values.response_shape,
            instructions: values.instructions,
            id: values.id,
            protected_intents: values.protected_intents,
            market: values.market,
            external_id: values.external_id,
            status: values.status,
            channels: values.channels,
            metadata: values.metadata,
          },
        },
      });

      fetchCampaigns(selectedAccount.id);
    } catch (error) {
      console.error("Error creating Campaign", error);
      setIsLoadingCampaignsError(true);
    } finally {
      setIsEditingCampaign(false);
    }
  }

  // Call to delete campaign
  async function UseDeleteCampaign(value) {
    try {
      setIsLoadingCampaignsError(false);
      setIsEditingCampaign(true);
      const response = await API.graphql({
        query: deleteCampaign,
        variables: { input: { id: value.id } },
      });
      fetchCampaigns(selectedAccount.id);
    } catch (error) {
      console.error("Error deleting Campaign", error);
      setIsLoadingCampaignsError(true);
    } finally {
      setIsEditingCampaign(false);
    }
  }

  // Handle to create campaign without validations
  const handleCreateCampaign = async ({ values, table }) => {
    const newValidationErrors = validateCampaign(values, campaignNameList);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await UsePutCampaign(values);
    table.setCreatingRow(null); //exit creating mode
  };

  // Handle to update campaigns without validations
  const handleSaveCampaign = async ({ values, table }) => {
    const newValidationErrors = validateCampaign(values, []);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await UsePutCampaign(values);
    table.setEditingRow(null);
  };

  const openDeleteConfirmModal = (row) => {
    setSelectedRow(row);
    setDialogOpenDelete(true);
  };

  const handleConfirmDelete = (row) => {
    if (row) {
      UseDeleteCampaign(row.original);
    }
    setDialogOpenDelete(false);
  };

  const table = useMaterialReactTable({
    columns,
    data: campaigns,
    createDisplayMode: "modal", //default ('row', and 'custom' are also available)
    editDisplayMode: "modal", //default ('row', 'cell', 'table', and 'custom' are also available)
    enableExpanding: true,
    enableEditing: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingCampaignsError
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          display: "grid",
          margin: "auto",
          gridTemplateColumns: "1fr 1fr",
          width: "100%",
        }}
      >
        <Typography>id: {row.original.id}</Typography>
        <Typography>context: {JSON.stringify(row.original.context)}</Typography>
        <Typography>
          first_message: {JSON.stringify(row.original.first_message)}
        </Typography>
        <Typography>
          response_shape: {JSON.stringify(row.original.response_shape)}
        </Typography>
        <Typography>
          instructions: {JSON.stringify(row.original.instructions)}
        </Typography>
        <Typography>
          protected_intents: {JSON.stringify(row.original.protected_intents)}
        </Typography>
        <Typography>market: {row.original.market}</Typography>
        <Typography>status: {row.original.status}</Typography>
        <Typography>external_id: {row.original.external_id}</Typography>
        <Typography>
          channels: {JSON.stringify(row.original.channels)}
        </Typography>
      </Box>
    ),
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateCampaign,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveCampaign,

    //optionally customize modal content
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Create New Conversation</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Edit Conversartion</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <CustomBox display="flex" justifyContent="space-between">
        <div>
          <Button
            variant="contained"
            onClick={() => {
              navigate('/new-conversation');
            }}
          >
            + Create New Conversation
          </Button>
        </div>
      </CustomBox>
    ),
    state: {
      isLoading: isFetchingCampaigns,
      isSaving: isEditingCampaign,
      showAlertBanner: isLoadingCampaignsError,
      showProgressBars: isFetchingCampaigns,
    },
  });

  return (
    <div>
      <MaterialReactTable table={table} />
      <ConfirmationDialog
        open={dialogOpenDelete}
        onClose={() => setDialogOpenDelete(false)}
        message="Are you sure you want to delete this conversation?"
        onConfirm={() => handleConfirmDelete(selectedRow)}
      />
    </div>
  );
});

export default CampaignList;

const validateRequired = (value) => !!value.length;
const validateName = (value, campaignNameList) =>
  campaignNameList.includes(value);

function validateCampaign(campaign, list) {
  return {
    name: !validateRequired(campaign.name)
      ? "Campaign Name is Required"
      : "" || validateName(campaign.name, list)
        ? "Campaign Name is Already Used"
        : "",
    context: !validateRequired(campaign.context)
      ? "Campaign Context is Required"
      : "",
    instructions: !validateRequired(campaign.instructions)
      ? "Campaign Instructions is Required"
      : "",
    external_id: !validateRequired(campaign.external_id)
      ? "Campaign Externa Id is Required"
      : "",
  };
}
