import { API } from "aws-amplify";
import {action, makeObservable, observable, flow} from "mobx";
import { getAgent } from "../../graphql/queries";

class AgentTreeStore {

    isLoading = false;
    isError = false;
    nodes = [
        {
            id: 'd1',
            data: { label: 'Director one', title: 'Sales director'},
            position: { x: 0, y: 0 },
            type: 'director'
        },
        {
            id: 'add1',
            position: { x: 0, y: 0 },
            type: 'add',
        },
    ];
    edges = [
        { id: 'd1-add1', source: 'd1', target: 'add1', type: 'smoothstep', },
    ];
    initialLoad = false;
    agents = [];
    teamID = 1234;

    constructor() {
        makeObservable(this, {
            isLoading: observable,
            isError: observable,
            nodes: observable,
            edges: observable,
            initialLoad: observable,
            agents: observable,
            teamID: observable,
            setNodes: action,
            setEdges: action,
            clear: action,
            setInitialLoad: action,
            getAgents: flow
        });
    }

    setInitialLoad(value){
        this.initialLoad = value;
    }

    setNodes(nodes){
        this.nodes = [...nodes];
    }

    setEdges(edges){
        this.edges = [...edges];
    }

    clear(){
        this.nodes = [];
        this.edges = [];
        this.isLoading = false;
        this.isError = false;
        this.agents = []; 
    }

    *getAgents(customer_id) {
        try {
            this.isLoading = true;
            this.isError = false;

            const response = yield API.graphql({
                query: getAgent,
                variables: { input: { customer_id } },
            });
            const agents = JSON.parse(response.data?.getAgent?.body);
            this.agents = agents
        } catch (error) {
            this.isError = true;
        } finally {
            this.isLoading = false;
        }
    }
} 

export default AgentTreeStore;
