import { useState } from "react";
import Icon from "../../../../../../elements/icon/Icon";
import SimpleSelect from "../../../../../../elements/v2/Selects/SimpleSelect"
import { 
    PermissionCardWrapper,
    PermissionTitle,
    PermissionSubtitle,
    PermissionText,
    PermissionTextHeader,
    PermissionCardContainer,
    PermissionLevelsContainer,
    PermissionDescription,
    PermissionLevel,
    PermissionTag
} from "./PermissionCard.styled"
import { PermissionsScopes } from "../../consts";
import { observer } from "mobx-react";
import useStore from '../../../../../../hooks/useStore';
import { ChildPermissionWrapper } from "../PermissionNavbar/PermissionNavbar.styled";

const PermissionCard = observer(({data, reviewMode=true}) => {

    const { newUser } = useStore();

    const [showNested, setShowNested] = useState({});
    const toggleNested = (name) => {
      setShowNested({ ...showNested, [name]: !showNested[name] });
    };

    const accessLabel = (permissions) => {

        if(!permissions) return
        const permissionsValues = Object.values(permissions)
        if(permissionsValues.every(permission => permission === 'none')){
            return (<PermissionTag $access="N">No Access</PermissionTag>)
        } else if(permissionsValues.includes('none')) {
            return (<PermissionTag $access="P">Partial Access</PermissionTag>)
        }

        return (<PermissionTag $access="F">Full Access</PermissionTag>)
    }

    return (
        <>
        {newUser.permissionsLoaded && data?.map((permission) => (
            <>
                <PermissionCardWrapper $reviewMode={reviewMode}>
                    <PermissionCardContainer>
                        <PermissionDescription onClick={() => toggleNested(permission.name)}>
                            {permission.child_permissions.length > 0 && 
                                <Icon name={!showNested[permission.name] ? 'arrowDown' : 'arrowUp'}/>
                            }
                            <PermissionText>
                                    <PermissionTextHeader><PermissionTitle>{permission.name}</PermissionTitle> 
                                        {Object.values(newUser.intermediatePermissionsStructure).length && 
                                            accessLabel(newUser.intermediatePermissionsStructure[permission.code]["set_permissions"]) 
                                        }
                                    </PermissionTextHeader>
                                {!reviewMode && <PermissionSubtitle>{permission.description}</PermissionSubtitle>}
                            </PermissionText>
                        </PermissionDescription>
                        <PermissionLevelsContainer $reviewMode={reviewMode}>
                            {permission.permission_levels.map(level => (
                                <PermissionLevel>
                                    <span>{level.toLowerCase()} {reviewMode && `(${newUser.intermediatePermissionsStructure[permission.code]["set_permissions"][level]})`}</span>
                                    {!reviewMode && <SimpleSelect 
                                        placeholder="None" 
                                        options={newUser.intermediatePermissionsStructure[permission.code]["available_scopes"]?.[level] || PermissionsScopes} 
                                        handleSelect={(name, value) => newUser.setPermissionLevels(permission, level, value)} 
                                        selectedDefault={newUser.intermediatePermissionsStructure[permission.code]["set_permissions"][level]}
                                    />}
                                </PermissionLevel>
                            ))}
                        </PermissionLevelsContainer>
                    </PermissionCardContainer>
                </PermissionCardWrapper>
                {permission.child_permissions &&
                    <ChildPermissionWrapper $display={!showNested[permission.name]}>
                        <PermissionCard data={permission.child_permissions} reviewMode={reviewMode}/>
                    </ChildPermissionWrapper>
                }
            </>
        ))}
        </>
    );
})

export default PermissionCard