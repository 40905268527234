import { RoundedButton, IconButton } from '../../../../../elements/button';
import { useCallback } from 'react';
import { 
  ModalWrapper,
  CloseButtonWrapper,
  ModalContentWrapper
} from '../../../../../elements/v2/modals/Modals.styled';
import { InputWrapper, Subtitle, Label, PickerWrapper, ActivationScheduleWrapper, ModalActionsWrapper, ModalBoxGray } from './NewConversationModal.styled';
import TextArea from '../../../../../elements/v2/TextArea/TextArea';
import InputText from '../../../../../elements/v2/TextInput/TextInput';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks';
import dayjs from 'dayjs';
import { TextField } from '@mui/material';
import SimpleSelect from '../../../../../elements/v2/Selects/SimpleSelect';
import { useNavigate } from 'react-router-dom';

const DateTimePicker = ({ label, type='date', ...props }) => {
    const slots = { textField: TextField };
    const slotProps = { inputAdornment: { position: 'start' } };
    return (
        <PickerWrapper>
            <Label>{label}</Label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                { type === 'date' ? 
                    (<DatePicker slots={slots} slotProps={slotProps} {...props} />)
                    :
                    (<TimePicker slots={slots} slotProps={slotProps} {...props} />)
                }
            </LocalizationProvider>
        </PickerWrapper>
    );
};

const NewConversationModal = observer(({ isOpen, setIsOpen, showSourceSelect = true, buttonText = "Create" }) => {
    const { newConversationStore } = useStore();
    const navigate = useNavigate();

    const onHandleClose = useCallback(() => {
      setIsOpen(false);
    }, [setIsOpen]);

    const onHandleConfirm = useCallback(() => {
        setIsOpen(false);
        if (buttonText === "Create") {
            newConversationStore.onCreate(navigate);
        } else {
            newConversationStore.onCreate();
        }
    }, [newConversationStore, setIsOpen, buttonText, navigate]);

    const handleEndDateChange = (value) => {
        if (newConversationStore.startDate && newConversationStore.startDate.isSame(value, 'day')) {
            const minEndTime = newConversationStore.startTime ? newConversationStore.startTime.add(4, 'hour') : null;
            newConversationStore.updateFormField('endDate', value);
            newConversationStore.updateFormField('minEndTime', minEndTime);
        } else {
            newConversationStore.updateFormField('endDate', value);
            newConversationStore.updateFormField('minEndTime', null);
        }
    };

    const handleEndTimeChange = (value) => {
        newConversationStore.updateFormField('endTime', value);
    };

    const minEndTime = newConversationStore.startDate && newConversationStore.endDate && newConversationStore.startDate.isSame(newConversationStore.endDate, 'day')
        ? (newConversationStore.startTime ? newConversationStore.startTime.add(4, 'hour') : null)
        : null;

    return (
      <ModalWrapper isOpen={isOpen} $zIndex={5}>
        <ModalBoxGray width="50%" height="auto">
          <CloseButtonWrapper>
            <IconButton icon="close" width="16px" height="16px" onClick={onHandleClose} />
          </CloseButtonWrapper>
          <ModalContentWrapper>
            {showSourceSelect && (
              <InputWrapper>
                  <Subtitle>Source</Subtitle>
                  <SimpleSelect 
                      placeholder="Select Source" 
                      searchBar={true}
                      hideOnBlur={false}
                      handleSelect={(_, value) => newConversationStore.updateFormField("source", value)}
                      selectedDefault={newConversationStore.source}
                      options={newConversationStore.sourceOptions}
                  />
              </InputWrapper>
            )}
            <InputWrapper>
                <Subtitle>Event Name</Subtitle>
                <InputText 
                    placeholder="Name" 
                    value={newConversationStore.eventName} 
                    onChange={e => newConversationStore.updateFormField("eventName", e.target.value)}
                />
            </InputWrapper>

            <InputWrapper>
                <Subtitle>Description - optional</Subtitle>
                <TextArea 
                    placeholder="Enter a description"
                    height="120px"
                    resize="none"
                    value={newConversationStore.description} 
                    onChange={e => newConversationStore.updateFormField("description", e.target.value)}
                />
            </InputWrapper>

            <InputWrapper>
                <Subtitle>Activation Schedule</Subtitle>

                <ActivationScheduleWrapper>

                    <DateTimePicker 
                        label="Start Date" 
                        value={newConversationStore.startDate} 
                        onChange={value => newConversationStore.updateFormField("startDate", value)}
                        minDate={dayjs()}
                    />
                    <DateTimePicker 
                        label="Start Time" 
                        value={newConversationStore.startTime} 
                        type="time"
                        onChange={value => newConversationStore.updateFormField("startTime", value)}
                    />
                    <DateTimePicker 
                        label="End Date - optional" 
                        value={newConversationStore.endDate} 
                        onChange={handleEndDateChange}
                        minDate={newConversationStore.startDate ? newConversationStore.startDate.add(4, 'hour') : dayjs()}
                    />
                    <DateTimePicker 
                        label="End Time - optional" 
                        value={newConversationStore.endTime} 
                        type="time"
                        onChange={handleEndTimeChange}
                        minTime={minEndTime}
                    />
                </ActivationScheduleWrapper>
            </InputWrapper>
          </ModalContentWrapper>
          <ModalActionsWrapper>
            <RoundedButton onClick={onHandleConfirm} width="115px" disabled={!newConversationStore.canCreate()}>{buttonText}</RoundedButton>
          </ModalActionsWrapper>
        </ModalBoxGray>
      </ModalWrapper>
    );
});

export default NewConversationModal;
