import React, {useEffect, useState} from "react";
import { I18n } from 'aws-amplify';
import {Authenticator, useAuthenticator, translations, Button} from '@aws-amplify/ui-react';
import {
  MainWrapper,
  TitleWrapper,
  FormWrapper,
  DynamicTitleAnimatedWordWrapper,
  WrapperLogoAimsByAnn,
  BoxHeaderImage,
  WrapperForgotPassword,
  FormTitle,
  FormSubtitle,
  FormOrDivider,
  FormSocialNetworks,
  SocialButton,
  FormFooter,
  Footer
} from "./Signin.styled";
import { Icon } from "../../elements";
import { Loading } from "../loading";
import BoxHeaderImageSrc from "./assets/box-header-bg.png";

import '@aws-amplify/ui-react/styles.css';
import './Signin.css';

I18n.putVocabularies(translations);
I18n.setLanguage('en');

I18n.putVocabulariesForLanguage('en', {
    'Sign in': 'Log in',
    'Username': 'Email',
    'Password': 'Password',
    'Forgot your password?': 'Forgot Password?',
    'Create a new account': 'Sign up',
    'Reset Password': 'Forgot password',
    'Enter your Email': 'Email',
    'Send code': 'Enter your email',
    'Change Password': 'Log In',
})

const Signin = ({ children }) => {
    const { authStatus  } = useAuthenticator(context => [context.user]);
    const [currentWordIndex, setCurrentWordIndex] = useState(0);

    const components = {
        SignIn: {
            Header() {
                return (
                  <FormTitle>Log in</FormTitle>
                );
            },
            Footer() {
                const { toResetPassword } = useAuthenticator();

                return (
                  <>
                      <WrapperForgotPassword><Button onClick={toResetPassword} variation="link">Forgot password?</Button></WrapperForgotPassword>
                      <FormOrDivider>
                          <div></div>
                          <span>Or</span>
                          <div></div>
                      </FormOrDivider>
                      <FormSocialNetworks>
                        <SocialButton><Icon name="apple" width="23px" height="24px" /></SocialButton>
                        <SocialButton><Icon name="microsoft" width="23px" height="24px" /></SocialButton>
                        <SocialButton><Icon name="google" width="23px" height="24px" /></SocialButton>
                      </FormSocialNetworks>
                  </>
                );
            }
        },
        ResetPassword: {
            Header() {
                return (
                  <>
                      <FormTitle>Forgot password</FormTitle>
                      <FormSubtitle>Don't worry, it happens sometimes. Please enter the email associated with your account.</FormSubtitle>
                  </>
                );
            }
        },
        ForceNewPassword: {
            Header() {
                return (
                  <>
                      <FormTitle>Create password</FormTitle>
                      <FormSubtitle>Create your new password and jump right back into the joyful ANN experience!</FormSubtitle>
                  </>
                );
            }
        },
        ConfirmResetPassword: {
            Header() {
                return (
                  <>
                      <FormTitle>Verification code</FormTitle>
                      <FormSubtitle>We sent a code to your email, please enter the code to verify your account and password, finally jump right back into the joyful ANN experience!</FormSubtitle>
                  </>
                );
            }
        }
    };
    const formFields = {
        signIn: {
            username: {
                labelHidden: true,
            },
            password: {
                labelHidden: true,
            },
        },
        forceNewPassword: {
            password: {
                labelHidden: true,
            },
            confirm_password: {
                labelHidden: true,
            }
        },
        resetPassword: {
            username: {
              labelHidden: true,
          }
        },
        confirmResetPassword: {
            confirmation_code: {
                labelHidden: true,
            },
            password: {
                labelHidden: true,
            },
            confirm_password: {
                labelHidden: true,
            }
        }
    };

    const titleWords = [
      'Ecosystem',
      'Market of ONE',
      'Real time Service Updates',
      'Data Centralization',
      'Next Best Actions',
      'Tracking customers',
      'Personalize your Conversations',
      'Dynamic Segmentations'
    ];

    useEffect(() => {
        const wordInterval = setInterval(() => {
            setCurrentWordIndex(prevIndex => (prevIndex + 1) % titleWords.length);
        }, titleWords[currentWordIndex].length * 200 + 1000);

        return () => clearInterval(wordInterval);
    }, [currentWordIndex, titleWords]);

    return (
      <>
          {authStatus === 'configuring' && <Loading />}
          {authStatus  === 'authenticated' ? [children] : (
            <MainWrapper>
                <WrapperLogoAimsByAnn>
                    <Icon name="aimsByAnn" width="89px" height="78px" />
                </WrapperLogoAimsByAnn>
                <BoxHeaderImage>
                    <img src={BoxHeaderImageSrc}  alt="" />
                </BoxHeaderImage>
                <TitleWrapper>
                    <DynamicTitleAnimatedWordWrapper>
                      {titleWords.map((word, wordIndex) => (
                        <div
                          key={wordIndex}
                          className={`word-container ${wordIndex === currentWordIndex ? 'visible' : ''}`}
                        >
                            {word.split('').map((letter, letterIndex) => (
                              <span
                                key={`${wordIndex}-${letterIndex}`}
                                className={wordIndex === currentWordIndex ? 'animate' : ''}
                                style={{animationDelay: `${letterIndex * 0.2}s`}}
                              >
                                {letter === ' ' ? '\u00A0' : letter}
                              </span>
                            ))}
                        </div>
                      ))}
                    </DynamicTitleAnimatedWordWrapper>
                  <div>with AIMS</div>
                </TitleWrapper>
              <FormWrapper>
                <Authenticator
                  hideSignUp={true}
                  components={components}
                      formFields={formFields}
                      signUpAttributes={[ 'email', 'name', 'phone_number' ]} />
                </FormWrapper>
                <FormFooter>
                    <a>Privacy policy</a>
                    <div className="divider"></div>
                    <a>Terms of use</a>
                </FormFooter>
                <Footer>Powered by Automotive Neural Networks</Footer>
            </MainWrapper>
          )}
      </>
    );
};

export default Signin;
