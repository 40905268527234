import React, { useEffect } from "react";
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useStore } from "../../../../hooks";
import DefaultStepper from "../../../../elements/stepper/DefaultStepper";
import SuccessfulModal from "../../../../elements/modals/SuccesfulModal";
import UserEmailStep from "./components/UserEmailStep";
import UserInfoStep from "./components/UserInfoStep";
import UserCardStep from "./components/UserCardStep";
import PermissionsStep from "./components/PermissionsStep/PermissionsStep";
import ReviewStep from "./components/ReviewStep";
import { dummyPermissions } from "./consts";

const V2NewUserPage = observer(() => {
    const navigate = useNavigate();
    const { userStore, authStore, newUser } = useStore();
    const { selectedAccount } = authStore;

    useEffect(() => {
        newUser.onChangeAccount(selectedAccount.id);
    }, [selectedAccount]);

    useEffect(() => {
        newUser.updatePermissionsCatalog(dummyPermissions)
    }, [])

    useEffect(() => {
        userStore.setPage('new');
    }, [selectedAccount, userStore]);

    const onHandleCancel = () => {
        navigate('/');
    };

    const onFinishStepper = () => {
        if (newUser.onValidateForm()) {
            newUser.onCompleteCreation();
        }
    };

    useEffect(() => {
        if(newUser.isSuccessfullModalOpen){
            newUser.setIsSuccessfulModalOpen(false);
            navigate('/v2/users');
        }
    }, [newUser.isSuccessfullModalOpen])

    return (
        <>
            <DefaultStepper onCancel={onHandleCancel} status={newUser.isLoading ? 'loading' : newUser.isError ? 'error' : 'idle'} stepperActionsWidth='400px' stepperActionsMt='60px' onFinish={onFinishStepper}>
                <DefaultStepper.Step isComplete={() => newUser.onValidateTextField('email')}>
                    <UserEmailStep email={newUser.email} onEmailChange={(e) => newUser.onChangeEmail(e.target.value)} />
                </DefaultStepper.Step>
                
                <DefaultStepper.Step isComplete={newUser.onValidateForm}>
                    <UserInfoStep/>
                </DefaultStepper.Step>
                
                <DefaultStepper.Step isComplete={() => newUser.permissionsOption}>
                    <UserCardStep/>
                </DefaultStepper.Step>

                <DefaultStepper.Step>
                    <PermissionsStep/>
                </DefaultStepper.Step>

                <DefaultStepper.Step labelNext="Create">
                    <ReviewStep/>
                </DefaultStepper.Step>
            </DefaultStepper>
        </>
    );
});

export default V2NewUserPage;
