const getCardLists = () => [
	{
		type: "Email",
		icon: "roundedSquareMail",
		title: "Name of the subject",
		description: "Send emails to communicate information or interact with other people",
		settings: true,
	},
	{
		type: "Email",
		icon: "roundedSquareMail",
		title: "Name of the subject",
		description: "Send emails to communicate information or interact with other people",
		settings: true,
	},
	{
		type: "Email",
		icon: "roundedSquareMail",
		title: "Name of the subject",
		description: "Send emails to communicate information or interact with other people",
		settings: true,
	},
	{
		type: "Email",
		icon: "roundedSquareMail",
		title: "Name of the subject",
		description: "Send emails to communicate information or interact with other people",
		settings: true,
	},
	{
		type: "Email",
		icon: "roundedSquareMail",
		title: "Name of the subject",
		description: "Send emails to communicate information or interact with other people",
		settings: true,
	},
	{
		icon: "roundedSquareMail",
		title: "Name of the subject",
		description: "Send emails to communicate information or interact with other people",
		settings: true,
	},
	{
		type: "Email",
		icon: "roundedSquareMail",
		title: "Name of the subject",
		description: "Send emails to communicate information or interact with other people",
		settings: true,
	}
]

const getActionTitles = [
	"Vehicle Management",
	"Sales Management",
	"Financing and Credit",
	"Service and Maintenance",
	"Parts and Accessories",
	"Billing and Accounting",
	"Human Resources",
	"Marketing and Advertising",
	"Customer Relationship",
	"Warranty Management",
	"Security and Compliance",
	"Test Drives",
	"Logistics and Transportation",
	"Analysis and Reports"
];

const getDataActionTitles = (title) => {
	const dataActionTitles = {
		"Vehicle Management": [
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "notificationsOutlined" ,
				title: "Notifications",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon:  "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
		],
		"Sales Management": [
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "notificationsOutlined" ,
				title: "Notifications",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon:  "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
		],
		"Financing and Credit": [
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "notificationsOutlined" ,
				title: "Notifications",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon:  "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
		],
		"Service and Maintenance": [
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "notificationsOutlined" ,
				title: "Notifications",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon:  "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
		],
		"Parts and Accessories": [
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "notificationsOutlined" ,
				title: "Notifications",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon:  "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
		],
		"Billing and Accounting": [
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "notificationsOutlined" ,
				title: "Notifications",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon:  "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
		],
		"Human Resources": [
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "notificationsOutlined" ,
				title: "Notifications",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon:  "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
		],
		"Marketing and Advertising": [
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "notificationsOutlined" ,
				title: "Notifications",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon:  "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
		],
		"Customer Relationship":[
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "notificationsOutlined" ,
				title: "Notifications",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon:  "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
		],
		"Warranty Management": [
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "notificationsOutlined" ,
				title: "Notifications",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon:  "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
		],
		"Security and Compliance": [
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "notificationsOutlined" ,
				title: "Notifications",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon:  "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
		],
		"Test Drives": [
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "notificationsOutlined" ,
				title: "Notifications",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon:  "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
		],
		"Logistics and Transportation": [
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "notificationsOutlined" ,
				title: "Notifications",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon:  "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
		],
		"Analysis and Reports": [
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "notificationsOutlined" ,
				title: "Notifications",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon:  "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "roundedSquareMail" ,
				title: "Name of the subject",
				description: "Send emails to communicate information or interact with other people",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
			{
				type: "Email",
				icon: "dotsIcon" ,
				title: "Example of action",
				description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				settings: false,
			},
		],
	};

	return dataActionTitles[title]
}

export {
	getCardLists,
	getActionTitles,
	getDataActionTitles,
}