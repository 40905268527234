import {action, flow, makeObservable, observable} from "mobx";
import {API} from "aws-amplify";
import {deleteUser, getUsers, putUser} from "../../../graphql/queries";

class UserValidationStore {
  firstName = undefined;
  lastName = undefined;
  phone = undefined;
  roleGroup = undefined;

  constructor() {
    makeObservable(this, {
      firstName: observable,
      lastName: observable,
      phone: observable,
      roleGroup: observable,
      validateUser: action,
      clearFirstName: action,
      clearLastName: action,
      clearPhone: action,
      clearRoleGroup: action,
      clearValidation: action,
    });
  }

  clearFirstName = () => this.firstName = undefined;
  clearLastName = () => this.lastName = undefined;
  clearPhone = () => this.phone = undefined;
  clearRoleGroup = () => this.roleGroup = undefined;

  clearValidation = () => {
    this.clearFirstName();
    this.clearLastName();
    this.clearPhone();
    this.clearRoleGroup();
  }

  validateRequired = (value) => value !== undefined && !!value.length;

  validateEmail = (value) =>
    value !== undefined &&
    !!value.length &&
    value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  validatePhoneNumber = (value) => /^\+?[0-9]{1,24}$/.test(value);

  validateUser = (user, isAdmin) => {
    this.firstName = !this.validateRequired(user.first_name) ? "First Name is Required" : undefined;
    this.phone = !this.validatePhoneNumber(user.phone) ? "Incorrect Phone Format" : undefined;
    this.email = !this.validateEmail(user.email) ? "Incorrect Email Format" : undefined;
    this.roleGroup = isAdmin && !this.validateRequired(user.role_group) ? "Incorrect User Role Group" : undefined;
  }
}

class ListUserStore {
  users = [];
  status = 'idle';
  row = null;
  showDialogOpenDelete = false;
  validation = new UserValidationStore();

  constructor() {
    makeObservable(this, {
      users: observable,
      status: observable,
      row: observable,
      showDialogOpenDelete: observable,
      validation: observable,
      setRow: action,
      onFetchUsers: flow,
      onDeleteUser: flow,
      onPutUser: flow
    });
  }

  setRow(row) {
    this.row = row;
  }

  setShowDialogOpenDelete(value) {
    this.showDialogOpenDelete = value;
  }

  *onFetchUsers(customer) {
    try {
      this.status = "loading";
      const response = yield API.graphql({
        query: getUsers,
        variables: { input: { customer, id: "" } },
      });
      this.users = JSON.parse(response.data?.getUsers?.body);

      this.status = "idle";
    } catch (e) {
      this.status = "error";
      console.error("Error fetch", e);
    }
  }

  *onDeleteUser() {
    try {
      this.status = "loading";
      yield API.graphql({
        query: deleteUser,
        variables: { input: { customer: this.row.original.customer, id: this.row.original.id } },
      });
      this.onFetchUsers(this.row.original.customer);
      this.status = "idle";
    } catch (e) {
      this.status = "error";
      console.error("Error fetch", e);
    }finally{
      this.setRow(null);
      this.setShowDialogOpenDelete(false);
    }
  }

  *onPutUser(values) {
    try {
      this.status = "loading";

      if (this.validation.firstName || this.validation.lastName || this.validation.phone || this.validation.roleGroup) {
        this.status = "error";
        return;
      }

      values.metadata = "{}";

      yield API.graphql({
        query: putUser,
        variables: { input: {
            id: values.id,
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            phone: values.phone,
            customer: values.customer,
            role_group: values.role_group,
            metadata: values.metadata,
          } },
      });

      this.status = "idle";
    } catch (error) {
      this.status = "error";
      console.log("error: ", error);
    }
  }
}

export default ListUserStore;