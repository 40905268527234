import React from 'react'
import Tabs from "../../../../elements/v2/Tabs/Tabs";
import Actions from "./Actions/Actions"
import {useStore} from "../../../../hooks";
import {MainContainer, TabsContainer, BreadCrumbContainer, MenuContainer, Title} from "./EventName.styled"
import General from '../../CampaignPage/EventName/General/General';

const EventName = () => {
  const { changeTitle, userStore } = useStore();
  changeTitle(null);

  const tabs = [
    {
      label: 'General',
      content: (
        <General/>
      ),
    },
    {
      label: 'Actions',
      content: (
        <Actions/>
      ),
    },
  ];

  return (
    <>
    <MainContainer>
      <BreadCrumbContainer>
        <Title>Conversations Starters</Title>
      </BreadCrumbContainer>
      <MenuContainer>

      </MenuContainer>
    </MainContainer>
    <TabsContainer>
        <Tabs
          tabs={tabs}
        />
    </TabsContainer>
    </>
  )
}

export default EventName
