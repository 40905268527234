import ContactForm from "./contactInformation";

import React from "react";
import { Route, Routes } from "react-router-dom";

const ContactPage = () => {
  return (
    <Routes>
      <Route index element={<ContactForm />} />
    </Routes>
  );
};

export default ContactPage;
