import { useState, useEffect } from "react";

import {
    ModalOverlay,
    ModalContent,
    ModalWrapper,
    ModalImage,
    ModalTitle,
    ModalCreatedBy,
    ModalDescription,
    ModalDescriptionContainer,
    ModalConversationsAndMembersContainer,
    ModalConversationsAndMembersTitle,
    ModalConversationsAndMembersDescription,
    ModalConversations,
    ModalMembersContainer,
    ModalStarters,
    ModalActions,
    CustomGenericListStyle,
    StartButtonText,
    KnowledgeBaseTableContainer,
    StartChatButtonContainer,
    ModalAccessHeaderTitle,
    VerticalButtonsContainer,
    ModalFooter
} from "./ModalAgentsDetail.styled";
import { useNavigate, createSearchParams } from 'react-router-dom';
import { observer } from "mobx-react";

import { useStore } from "../../../../../hooks";
import { RoundedButton, Icon } from "../../../../../elements";
import GenericList from '../../../../../elements/v2/GenericList/GenericList';
import ActionsList from "../../../../../elements/v2/ActionsCatalog/ActionsList";
import KnowledgeBaseTable from "../../../../../elements/v2/KnowledgeBaseTable/KnowledgeBaseTable"
import AccessTabs from "../AccessTabs/AccessTabs";

import { getDummyContentTabs } from "../../consts"
import VerticalButton from "../../../../../elements/verticalButton/VerticalButton";

const ModalAgentDetail = observer(({ open, onClose }) => {
    const navigate = useNavigate();
    const { authStore, agentStore } = useStore()

    const [agentActions, setAgentActions] = useState(agentStore.selectedAgent.actions);

    useEffect(() => {
      if (authStore.selectedAccount?.id){
        agentStore.onChangeAccount(authStore.selectedAccount.id);
      }
  }, [authStore.selectedAccount, agentStore]);

    const handleSaveAction = (newActions) => {
        setAgentActions(newActions);
    };

    if (!open) return <></>;

    const handleStartChat = () => {
      navigate({
          pathname: "/home",
          search: createSearchParams({
              agent: agentStore.selectedAgent.id,
              customer: authStore.selectedAccount.id,
          }).toString(),
      });
    };

    return (
        <ModalOverlay onClick={onClose}>
          <ModalWrapper>
            <ModalContent onClick={(e) => e.stopPropagation()}>
              <VerticalButtonsContainer id="vertical-button-container">
                  <VerticalButton options={[
                  {
                    title: 'Duplicate',
                  },
                  {
                    title: "Archive",
                  },
                  {
                    title: "Share",
                  },
                  {
                    title: 'Playground',
                    onClick: () => { 
                      onClose && onClose(); 
                      navigate({
                        pathname: "/v2/assistants/playground",
                        search: createSearchParams({
                            agent: agentStore.selectedAgent.id
                        }).toString(),
                      })
    
                    }
                  },
                ]} />
              </VerticalButtonsContainer>
              <ModalImage src={agentStore.selectedAgent.image} alt="Modal Image" />
              <ModalTitle>{agentStore.selectedAgent.name}</ModalTitle>
              <ModalCreatedBy>Created by <i>{agentStore.selectedAgent.createdby}</i></ModalCreatedBy>
              <ModalDescriptionContainer><ModalDescription>{agentStore.selectedAgent.description2}</ModalDescription></ModalDescriptionContainer>
              <ModalConversationsAndMembersContainer>
                <div>
                  <ModalConversations>
                    <ModalConversationsAndMembersTitle>
                      Conversations
                      </ModalConversationsAndMembersTitle>
                    <ModalConversationsAndMembersDescription>
                    {agentStore.selectedAgent.conversations || 0} conversations
                    </ModalConversationsAndMembersDescription>
                  </ModalConversations>
                </div>
                <ModalMembersContainer>
                  <ModalConversations>
                    <ModalConversationsAndMembersTitle>
                      Members
                      </ModalConversationsAndMembersTitle>
                    <ModalConversationsAndMembersDescription>
                    {agentStore.selectedAgent.members || 0} members
                    </ModalConversationsAndMembersDescription>
                  </ModalConversations>
                </ModalMembersContainer>
              </ModalConversationsAndMembersContainer>

              <AccessTabs access={agentStore.selectedAgent.access ? agentStore.selectedAgent.access: []} tabsNames={getDummyContentTabs().names}/>

              <ModalStarters id="starters">
                  {
                      agentStore.selectedAgent.conversation_starters && agentStore.selectedAgent.conversation_starters.length > 0 && (
                          <>
                              <CustomGenericListStyle/>
                              <GenericList
                                  title="Starters"
                                  listItems={agentStore.selectedAgent.conversation_starters}
                              />
                          </>

                      )
                  }

              </ModalStarters>
              <ModalActions id="agent-actions">
                <CustomGenericListStyle/>
                  {
                    agentStore.selectedAgent.actions && agentStore.selectedAgent.actions.length > 0 && (
                    <ActionsList
                      agentActions= { agentStore.selectedAgent.actions }
                      onSave={handleSaveAction}
                    />
                    )
                  }
              </ModalActions>
              <ModalAccessHeaderTitle>Knowledge base</ModalAccessHeaderTitle>
              <KnowledgeBaseTableContainer id="knowledge-base">
                <KnowledgeBaseTable fileNames={agentStore.selectedAgent.knowledgeBase} />
              </KnowledgeBaseTableContainer>
            </ModalContent>
            <ModalFooter>
                <StartChatButtonContainer id="startchat-container">
                    <RoundedButton onClick={handleStartChat} icon={{ position: 'left', element: <Icon name="conversation" /> }} width={'500px'} height={'48px'}>
                        <StartButtonText>Start chat</StartButtonText>
                    </RoundedButton>
                </StartChatButtonContainer>
            </ModalFooter>
          </ModalWrapper>
        </ModalOverlay>
    );
  });
  
  export default ModalAgentDetail;