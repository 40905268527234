import { useEffect, useState } from "react";
import Icon from "../../../../../../elements/icon/Icon";
import { 
    PermissionCardWrapper,
    PermissionTitle,
    PermissionCardContainer,
    ChildPermissionWrapper
} from "./PermissionNavbar.styled"
import { observer } from "mobx-react";
import useStore from '../../../../../../hooks/useStore';

const PermissionNavbar = observer(({data}) => {

    const { newUser } = useStore();

    const [showNested, setShowNested] = useState({});
    const toggleNested = (name) => {
      setShowNested({ ...showNested, [name]: !showNested[name] });
    };

    const clickPermissionCard = (permission) => {
        newUser.filterPermissionDisplayed(permission);toggleNested(permission.name)
    }

    return (
        <>
        {newUser.permissionsLoaded && data?.map((permission) => (
            <>
                <PermissionCardWrapper tabIndex={0} onClick={() => clickPermissionCard(permission)}>
                    <PermissionCardContainer>
                        <PermissionTitle>{permission.name}</PermissionTitle> 
                        {permission.child_permissions.length > 0 && 
                            <Icon name={!showNested[permission.name] ? 'arrowDown' : 'arrowUp'} height="14px" width="14px"/>
                        }
                    </PermissionCardContainer>
                </PermissionCardWrapper>
                {permission.child_permissions &&
                    <ChildPermissionWrapper $display={!showNested[permission.name]}>
                        <PermissionNavbar data={permission.child_permissions}/>
                    </ChildPermissionWrapper>
                }
            </>
        ))}
        </>
    );
})

export default PermissionNavbar