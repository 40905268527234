import {useRef, useState} from "react";
import { observer } from "mobx-react";
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { Tooltip } from '@mui/material'

import {CustomSelect, Icon, RichText, RoundedButton} from "../../../../elements";
import {
  RichTextWrapper,
  RichTextWrapperSendButton,
  RichTextWrapperTopActions,
  RichTextWrapperTopActionsLeft,
  AttachmentButtonWrapper,
  AttachmentButton,
  FileAttachmentWrapper
} from "./Assistance.styled";
import AttachFile from '@mui/icons-material/AttachFile';
import {IconButton as CustomIconButton, SuccessfulModal} from "../../../../elements";
import AdmirationSign from '../../../../elements/icon/assets/admiration_sign.svg';

const RichInput = observer(({
  onChange,
  isLoading,
  onSend,
  onFileUploaded,
  disableSend,
  onCleanThread,
}) => {

  const [text, setText] = useState('');
  const [sentMessage, setSentMessage] = useState(false);

  const [fileUploaded, setFileUploaded] = useState();
  const [errorPopup, setErrorPopup] = useState('');
  const [droppingFile, setDroppingFile] = useState(false);
  const fileInputRef = useRef();

  const fileInputId = Math.random();
  const extensions =  [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    "text/plain",
    "application/pdf",
    "text/csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ]

  const handleFile = (files) => {
    if(files.length){
      
      const fileToUpload = files[0]

      if(!extensions.includes(fileToUpload.type)){
        return setErrorPopup('Please upload a valid format file:*.doc, *.docx, *.pdf, *.txt or *.xlsx.');
      }

      if(fileToUpload.size/1e+6 > 100){
        return setErrorPopup(`File exceeds the 100 mb file size limit.`);
      }

      setFileUploaded(files[0].name)
      const reader = new FileReader()

      reader.readAsDataURL(files[0])
      reader.onload = () => {
        if(reader.error){
          return setErrorPopup('File processing failed, plesae try again later.');
        }

        const data = reader.result.split('base64,')[1];
        onFileUploaded({fileName: files[0].name, data })
      }
    }
  }

  const onRemoveFile = () => {
    setFileUploaded('');
    onFileUploaded({});
    fileInputRef.current.value = "";
  }

  const handleContentChange = (value) => {
    const input = value.root.children[0]?.children[0]?.text;
    setText(input);
    onChange(input)
    setSentMessage(false);
  };

  const handleOnSend = () => {
    onSend();
    setText('');
    setSentMessage(true);
    setFileUploaded("")
  }

  const handleCleanThread = () => {
    onCleanThread();
  }

  return (
    <RichTextWrapper>
      <RichTextWrapperTopActions>
        <RichTextWrapperTopActionsLeft>
          <RoundedButton
            width={"90px"}
            icon={{
              position:"left",
              element:<Icon name="note" width={"15px"} height={"20px"} margin={"0 5px 0 0"} />
            }}
            height={"40px"}
          >
            Notes
          </RoundedButton>
          <Tooltip title="New chat 2">
            <RoundedButton height="40px" width="40px">
              <Tooltip title="New chat">
                <QuestionAnswerOutlinedIcon onClick={handleCleanThread}/>
              </Tooltip>
            </RoundedButton>
          </Tooltip>
        </RichTextWrapperTopActionsLeft>
        <div>
          <RoundedButton
            width={"70px"}
            icon={{
              position:"left",
              element:<Icon name="ai" width={"15px"} height={"20px"} margin={"0 5px 0 0"} />
            }}
            height={"40px"}
          >
            AI
          </RoundedButton>
        </div>
      </RichTextWrapperTopActions>
      <RichText initialText={text} onChange={handleContentChange} sent={sentMessage} includeClearTextPlugin={true}/>
      <RichTextWrapperSendButton>
        <RoundedButton
          width={"90px"}
          height={"40px"}
          onClick={handleOnSend}
          disabled={isLoading || disableSend}
          sent={sentMessage}
        >
          Send
        </RoundedButton>
      </RichTextWrapperSendButton>

      <AttachmentButtonWrapper>
          {fileUploaded &&
            (<FileAttachmentWrapper>
              <span>{fileUploaded}</span>
              <CustomIconButton icon="close" width="15px" height="15px" onClick={onRemoveFile} />   
            </FileAttachmentWrapper>)
          }
          <div>
            <input id={`contained-button-file-${fileInputId}`} ref={fileInputRef}
              type="file" style={{ display: 'none' }} onChange={(e) => handleFile(e.target.files)}/>
            <AttachmentButton htmlFor={`contained-button-file-${fileInputId}`}>
              <AttachFile sx={{ color: 'white'}}/>
            </AttachmentButton>
          </div>
      </AttachmentButtonWrapper>

      <SuccessfulModal
        isOpen={errorPopup}
        onClose={() => setErrorPopup('')}
        imageSrc={AdmirationSign}
        title="Oops!"
        subtitle="An error occurred."
        subtitle2={errorPopup}
        height='300px'
        width='500px'
        zIndex={true}
      />

    </RichTextWrapper>
  );
});

export default RichInput;