import React, {useCallback, useState, useMemo, useEffect} from "react";
import {useStore} from "../../hooks";
import {RoundedButton, SuccessfulModal} from "../../elements";
import AdmirationSign from '../../elements/icon/assets/admiration_sign.svg';
import {observer} from "mobx-react";
import {useNavigate, createSearchParams} from "react-router-dom";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { CustomBox } from "../../theme/DefaultTheme";
import {HeaderButtonWrapper, SearchWrapper} from "./ContactForm.styled";
import { IconButton, Tooltip, Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ContactForm = observer(() => {
  const { authStore, contactStore, changeTitle } = useStore();
  const navigate = useNavigate();

  changeTitle('Contacts');
  const { selectedAccount } = authStore;

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    contactStore.getContacts(selectedAccount.id);
  }, [selectedAccount]);
  
  const extensions = {
    "application/vnd.ms-excel": [".xls"],
    "text/csv": [".csv"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
  };
  const [rowSelection, setRowSelection] = useState({});

  const onHandleNewContactList = useCallback(() => {
    navigate('/contacts/upload');
  }, []);

  const onHandleUnifiedCustomerProfile = useCallback((segment) => {
    const urlForUnified = authStore.selectedAccount.id.startsWith('ann') ? "/ann-unified-profile" : "/unified-profile" 
    navigate({
      pathname: urlForUnified,
      search: createSearchParams({
          segment,
      }).toString(),
    });
  })

  const columns = useMemo(
    () => {
      const baseColumns = [
    { 
      accessorKey: "id", 
      header: "ID",
      enableEditing: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    { 
      accessorKey: "first_name", 
      header: "Name",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    { 
      accessorKey: "email", 
      header: "Email",
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    { 
      accessorKey: "phone", 
      header: "Phone" ,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    { 
      accessorFn: (row) => new Date(row.creation_date),
      accessorKey: "creation_date", 
      header: "Creation Date",
      enableEditing: false,
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(),
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    
  ];
    return baseColumns;
  },
    [validationErrors]
  );

  const table = useMaterialReactTable({
    columns,
    data: contactStore.contacts !== null ? contactStore.contacts : [],
    initialState: {
      sorting: [
        {
          id: 'creation_date', 
          desc: true,
        },
      ],
    },
    positionActionsColumn: 'last',
    enableRowActions: true,
    enableColumnFilters: false,
    enableHiding: false,
    
    getRowId: (row) => row.id,
    renderTopToolbarCustomActions: ({ table }) => (
      <CustomBox display="flex" justifyContent="flex-end">
        <HeaderButtonWrapper>
          <RoundedButton width="197px" onClick={onHandleNewContactList} margin="0px 5px">
            Upload your file
          </RoundedButton>
        </HeaderButtonWrapper>
      </CustomBox>
    ),
    renderRowActions: ({ row, table }) => (
      <Box>
        <Tooltip title="View">
          <IconButton onClick={() => onHandleUnifiedCustomerProfile(row.id)}>
            <VisibilityIcon/>
          </IconButton>
        </Tooltip>
      </Box>
    ),
    state: {
      isLoading: contactStore.isLoading,
    }
  });

  return (
    <>
      <MaterialReactTable table={table}/>
      {contactStore.isError && <SuccessfulModal
              isOpen={contactStore.isError}
              onClose={() => contactStore.onClearError()}
              imageSrc={AdmirationSign}
              title="Oops!"
              subtitle="An error occurred."
              subtitle2="Please try again later."
          />}
    </>
  );
});

export default ContactForm;
