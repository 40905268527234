import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import React, {useMemo} from "react";
import { useMockDataTriggerTab } from "../../mock";
import {StatusWrapper} from "./TriggerTab.styled";
import {DefaultTheme as theme} from "../../../../../theme";

const TriggerTab = () => {
  const data = useMockDataTriggerTab();

  const columns = useMemo(
  () => [
    {
      accessorKey: "status",
      header: "Status",
      Cell: ({ renderedCellValue, row }) => {
        const className = (value) => {
          return (value === 'Active' && 'active')
            || (value === 'Finished' && 'finished')
            || (value === 'Draft' && 'draft')
            || (value === 'Paused' && 'paused')
            || (value === 'Stand By' && 'standby');
        };

        return (<StatusWrapper className={className(renderedCellValue)}>{renderedCellValue}</StatusWrapper>);
      }
    },
    {
      accessorKey: "name",
      header: "Name"
    },
    {
      accessorKey: "segment",
      header: "Segment"
    },
    {
      accessorKey: "contact",
      header: "Contacts"
    },
    {
      accessorKey: "sent",
      header: "Sent"
    },
    {
      accessorKey: "reply",
      header: "Reply"
    },
    {
      accessorKey: "start_date",
      header: "Start date",
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(),
    },
    {
      accessorKey: "end_date",
      header: "End date",
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(),
    },
  ], []);

  const table = useMaterialReactTable({
    columns,
    data,
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: theme.colors.highClearGray,
        color: 'black',
        paddingTop: '16px',
        paddingBottom: '16px',
      },
    },
    positionActionsColumn: 'last',
    paginationDisplayMode: 'pages',
  });

  return (<MaterialReactTable table={table} />);
};

export default TriggerTab;
