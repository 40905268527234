import ListUserStore from "./pages/ListUserStore";
import NewUserStore from "./pages/NewUserStore";
import {action, makeObservable, observable} from "mobx";

const UserPageStrategy = {
  'list': new ListUserStore(),
  'new': new NewUserStore()
};

class UserStore {
  page = null;

  constructor() {
    makeObservable(this, {
      page: observable,
      setPage: action
    });
  }

  setPage(page) {
    this.page = UserPageStrategy[page];
  }
}

export default UserStore;