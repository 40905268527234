import React, { useEffect } from 'react';
import { observer } from "mobx-react-lite";
import useStore from "../../../../../hooks/useStore";
import ChatBox from "../../../../../elements/ChatBox/ChatBox";
import BasicConfigurationTab from './BasicConfigurationTab';
import AdvancedConfigurationTab from './AdvancedConfigurationTab';
import Spinner from '../../../../../components/common/Spinner';
import Tabs from '../../../../../elements/v2/Tabs/Tabs';

const PlaygroundTabs = observer(() => {
  const { authStore, editAgent } = useStore();

  const tabs = [
    {
      label: 'Create',
      content: (
        <ChatBox
          customerSelected={authStore.selectedAccount?.id}
          agentId={editAgent.agentPrompter}
          agentDomain={''}
          inputWidth="80vh"
        /> 
      ),
    },
    {
      label: 'Basic',
      content: (
          <BasicConfigurationTab/>
      ),
    },
    {
      label: 'Advanced',
      content: (
          <AdvancedConfigurationTab/>
      ),
    },
  ];

  return (editAgent.finishedInitialLoad ? <Tabs tabs={tabs} /> : <Spinner/>);
});

export default PlaygroundTabs;
