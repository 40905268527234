import AgentTeamIndicatorItem from './AgentTeamIndicatorItem';

import {
  SelectorContentIndicators,
  SelectorContentIndicatorSeparator
} from "./AgentTeamSelector.styled";

const AgentTeamIndicators = ({status, company}) => {
  return (
    <SelectorContentIndicators>
      <AgentTeamIndicatorItem
        icon={{
          name: status === "Active" ? "ellipseIndicatorActive" : "ellipseIndicatorInactive",
          width: "7px",
          height: "7px"
        }}
        value={status}
      />
      <SelectorContentIndicatorSeparator />
      <AgentTeamIndicatorItem
       icon={{
        name: "mdiCompany",
        width: "15px",
        height: "15px"
      }}
      value={company}
      />
    </SelectorContentIndicators>
  );
}

export default AgentTeamIndicators;