import styled from "styled-components"

const ChatQuestionsWrapper = styled.section`
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ChatQuestionsBox = styled.div`
    width: ${props => props.$width || '818px'};
    border-radius: 20px;
    border: 2px ${props => props.theme.colors.greyTwo} solid;
    padding: 18px 30px;
    margin-top: ${props => props.$mt || '0px'}
`;

const ChatQuestionsBoxDescription = styled.span`
    color: ${props => props.theme.colors.greyTwo};
    font-size: 15px;
    text-align: justify;
    font-style: italic;  
`;

const ChatQuestionsBoxTitle = styled.span`
    color: ${props => props.theme.colors.greyTwo};
    font-size: 15px;
    text-align: justify; 
    font-weight: bold;
`;

const ChatQuestionsSuggestions = styled.div`
    margin-top: 20px;
    display: grid;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const Suggestion = styled.div`
    flex: 0 0 calc(44% - 12px);
    height: 70px;
    background: ${props => props.theme.colors.white};
    border-radius: 12px;
    border: 1px ${props => props.theme.colors.black} solid;
    margin: 5px 20px;
    text-align: center;
    font-size: ${props => props.$fontSize || '16px'};
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.5s;

    &:hover {
        background-color: ${props => props.theme.colors.lightGrey};
    }
`;

const Suggestion2 = styled(Suggestion)`
    flex: 0 0 calc(51% - 16px);
    margin: 5px 5px;
    height: 50px;
`;

const EditAnswerInput = styled.textarea`
    resize: none;
    padding: 5px 10px;
    border-radius: 10px;

    &:focus {
        outline: none;
`;  

const ChatQuestionsInputContainer = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px; 
`;

const ChatQuestionsInputContainerLeftText = styled.div`
    width: 100%;
    text-align: left;
`;


export {
    ChatQuestionsWrapper,
    ChatQuestionsBox,
    ChatQuestionsBoxDescription,
    ChatQuestionsSuggestions,
    Suggestion,
    ChatQuestionsBoxTitle,
    EditAnswerInput,
    Suggestion2,
    ChatQuestionsInputContainer,
    ChatQuestionsInputContainerLeftText
}