import styled from "styled-components";

const SelectorWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  gap: 16px;
  padding: 0 16px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.highClearGray};
`;

const SelectorAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const SelectorContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 127px;
`;

const SelectorContentName = styled.div`
  ${(props) => props.theme.fonts.variant.regular()};
  font-size: ${(props) => props.theme.fonts.size.normal};
  line-height: normal;
  font-style: normal;
  color: ${(props) => props.theme.colors.greyEleven};
`;

const SelectorContentIndicators = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 11px;
  height: 15px;
  margin-top: 6px;
`;

const SelectorContentIndicatorItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  height: 15px;
`;

const SelectorContentIndicatorItemText = styled.div`
  margin: 0;
  padding: 0;
  ${(props) => props.theme.fonts.variant.light()};
  font-size: 0.75rem;
`;

const SelectorContentIndicatorSeparator = styled.div`
  width: 5px;
  height: 5px;
  background-color: ${(props) => props.theme.colors.silverGrayFour};
  border-radius: 50%;
`;

const SelectorDropdownWrapper = styled.div`
  display: flex;
  width: 24px;
  background-color: ${(props) => props.theme.colors.silverGrayThree};
  rotate: ${(props) => props.openDropdown ? '180deg' : '0deg'};
`;

const SelectorElipsisWrapper = styled.div`
  display: flex;
  width: 20px;
`;

const SelectorListWrapper = styled.div`
  position: absolute;
  top: 63px;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.colors.highClearGray};

  border-radius: 8px;
    
  padding: 16px;

  display: ${(props) => (props.isVisible ? "flex" : "none")};
  flex-direction: column;
  gap: 16px;
  z-index: 3;
`;

const SelectorListItems = styled.div`
    display: flex;
    flex-direction: column;
    height: 296px;

    h3 {
        ${(props) => props.theme.fonts.variant.regular()};
        font-size: 1rem;
        color: ${(props) => props.theme.colors.darkGray};
        padding: 0;
        margin: 0 0 4px;
    }
`;

const AgentTeamListItemGroup = styled.div`
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.clearGray};
    background-color: ${(props) => props.theme.colors.primaryWhite};
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    
    p {
        width: 100%;
        ${(props) => props.theme.fonts.variant.extraLight()};
        font-size: 0.75rem;
        padding: 10px;
        text-align: center;
        margin: 0;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.colors.clearGray};
        border-radius: 8px;
        background-clip: content-box;
        pointer-events: none;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: ${(props) => props.theme.colors.darkGray};
    }
`;

const SelectorListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 42px;
  gap: 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.silverGrayTwo};
  padding: 0 16px;

  &:last-child {
    border-bottom: none;
  }  
    
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const SelectorListItemAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const SelectorListItemName = styled.span`
  ${(props) => props.theme.fonts.variant.regular()};
  font-size: 1rem;
  color: ${(props) => props.theme.colors.greyEleven};
`;

const SelectorListItemTeam = styled.span`
  ${(props) => props.theme.fonts.variant.light()};
  font-size: 0.75rem;
  color: ${(props) => props.theme.colors.silverGrayTwo};
`;

const SelectorHamburgerWrapper = styled.div`
  position: absolute;
  top: 63px;
  right: 0;
  background-color: ${(props) => props.theme.colors.silverGrayThree};

  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.silverGrayTwo};

  display: ${(props) => (props.isVisible ? "block" : "none")};
`;

const SelectorHamburgerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 27px;
  border-bottom: 1px solid ${(props) => props.theme.colors.silverGrayTwo};
  padding: 0 16px;

  ${(props) => props.theme.fonts.variant.regular()};
  font-size: 0.75rem;
  color: ${(props) => props.theme.colors.greyThirteen};

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export {
  SelectorWrapper,
  SelectorAvatarWrapper,
  SelectorContentWrapper,
  SelectorContentName,
  SelectorContentIndicators,
  SelectorContentIndicatorItem,
  SelectorContentIndicatorItemText,
  SelectorContentIndicatorSeparator,
  SelectorDropdownWrapper,
  SelectorElipsisWrapper,
  SelectorListWrapper,
  SelectorListItems,
  AgentTeamListItemGroup,
  SelectorListItem,
  SelectorListItemAvatarWrapper,
  SelectorListItemName,
  SelectorListItemTeam,
  SelectorHamburgerWrapper,
  SelectorHamburgerItem
}