import styled from 'styled-components';

const NewAgentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1vw 12vw 0 12vw;
`;

const FormTitle = styled.p`
    font-weight: 400;
    font-size: ${(props) => props.fontSize || '28px'};
    font-style: italic;
    ${(props) => props.theme.fonts.variant.regular}
`;

const FormSubtitle = styled.p`
    ${(props) => props.theme.fonts.variant.regular}
    color: #666666;
    font-size: ${(props) => props.fontSize || '15px'};
    Font: Inter;
    Weight: 300;
    Opacity: 60%;
`;

const FormAgentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const FormButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    margin: ${(props) => props.margin || '80px 0 0'};
    justify-content: space-between;
    width: 400px;
`;

const CheckboxContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 10px;
    margin-top: 55px;
    margin-bottom: 90px
`;

const RadioButtonsContainer = styled.div`
    display: flex;
    gap: 50px;
    margin-top: ${(props) => props.mt || '50px'};
    margin-bottom: ${(props) => props.mb || '0'};
`;

const ModalWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: 440px;
    height: 300px;
    background-color: white;
    border-radius: 10px;
`;

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    padding: 55px;
    padding-right: 15px;
    margin-top: 20px;
`;

const ModalTitle = styled.div`
    font-weight: bold;
    font-size: 28px;
    ${(props) => props.theme.fonts.variant.regular}
`;

const ModalImage = styled.img`
    margin-bottom: '20px';
`;

const ModalSubtitle = ({children}) => <FormTitle fontSize="20px">{children}</FormTitle>;

const ModalFooterWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
`;

const ModalCloseButton = styled.img`
    margin-top: 12px;
    margin-right: 20px;
    cursor: pointer;
`;

const ModalTestAgentButton = styled.img`
    cursor: pointer;
`;

const DragAndDropAssistantWrapper = styled.div`
    margin-top: 40px;
`;

const StyledTextArea = styled.textarea`
    width: 868px;
    height: 133px;
    gap: 0px;
    border: 1.44px solid black;
    border-radius: 10px;
    opacity: 0.5;
    padding: 10px;
`;

const StyledButton = styled.button`
    width: 378px;
    height: 52px;
    top: 7px;
    left: 19px;
    opacity: 0.6;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 17px;
    line-height: 20.57px;
    text-align: center;
    color: #717171;
    margin-bottom: 20px;
    border-radius: 10px;
    cursor: pointer;
`;

const CustomDiv = styled.div`
    width: 40vw;
    height: 60px;
    top: 744px;
    left: 534px;
    gap: 0px;
    opacity: 0.8;
    font-family: Inter;
    font-size: 12px;
    font-weight: 300;
    line-height: 14.52px;
    text-align: justify;
    margin-top: 20px;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 0fr 0fr;
`;

const GridItem = styled.div`
    padding: 20px;
`;

const GridRow = styled.div`
    display: flex;
    align-items: center;
`;

const LabelActions = styled.label`
    width: 60px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const CreatedActionsContainer = styled.div`
    width: 645px;
    height: 100%;
    top: 170px;
    left: 86px;
    gap: 0px;
    border-radius: 5px 0px 0px 0px;
    opacity: 0px;
`;


const ActionItem = styled.div`
    width: 529px;
    min-height: 50px;
    top: 30px;
    left: 105px;
    gap: 0px;
    opacity: 0px;
    text-align: justify;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    background-color: ${props => props.theme.colors.lightGrey};
    border-radius: 10px;
`;

const ActionText = styled.div`
    flex: 1;
    margin-left: 10px;
`;

const DeleteIcon = styled.img`
    cursor: pointer;
    right: 0;
    top: 50%;
    margin-left: 20px;
    margin-right: 20px;
`;


const ActionsContainer = styled.div``;
const Label = styled.div`
    ${props => props.theme.fonts.variant.light};
    font-size: ${props => props.$fontSize || '18px'};
    color: ${props => props.theme.colors.grey};
    margin: ${props => props.$margin || '10px 0'};
    font-weight: ${props => props.$bold || '500'};
    text-align: ${props => props.$align || 'justify'};
`;

export {
    NewAgentWrapper,
    FormTitle,
    FormSubtitle,
    FormAgentWrapper,
    FormButtonsContainer,
    CheckboxContainer,
    RadioButtonsContainer,
    ModalContainer,
    ModalTitle,
    ModalSubtitle,
    ModalImage,
    ModalFooterWrapper,
    ModalWrapper,
    ModalCloseButton,
    ModalTestAgentButton,
    DragAndDropAssistantWrapper,
    StyledTextArea,
    StyledButton,
    GridContainer,
    GridItem,
    GridRow,
    CreatedActionsContainer,
    CustomDiv,
    LabelActions,
    ButtonContainer,
    ActionItem,
    ActionText,
    DeleteIcon,
    ActionsContainer,
    Label,
}
