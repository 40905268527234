import styled from "styled-components";

export const AgentsTreeContainer = styled.div`
    width: 95vw;
    height: 85vh;
`;

export const AgentSelectorContainer = styled.div`
    position: absolute;
    width: 300px;
    top: ${(props) => props.$top-100}px;
    left: ${(props) => props.$left}px;
`;