import styled, {css} from "styled-components";

const DropDownContainer = styled("div")`
    height: 27px;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.colors.clearGray};;
    text-align: left;
    font-weight: 400;
    background-color: ${(props) => props.theme.colors.silverGrayThree};
    font-size: 0.75rem;
    min-width: 100px;
    z-index: 20;
`;

const DropDownHeader = styled("div")`
    position: relative;
    cursor: pointer;
    padding: 3px 6px;
    text-transform: ${props => props.$textTransform || 'none'};

    &:after {
        --size: 0.4rem;
        content: "";
        position: absolute;
        right: 1rem;
        pointer-events: none;
    }
    
    &:after {
        width: 30px;
        height: 30px;
        background-image: url('${(props) => props.arrow}');
        background-repeat: no-repeat;
        top: -3px;
        right: 6px;
    }
`;

const DropDownList = styled("ul")`
    border-radius: 8px;
    border: 1px solid ${props => props.theme.colors.clearGray};
    position: relative;
    z-index: 10;
    padding: 0;
    margin-top: 5px;
    background: white;
    max-height: 200px;
    overflow-y: auto;
`;

const ListItem = styled("li")`
    background-color: ${(props) => props.theme.colors.silverGrayThree};
    list-style: none;
    height: 27px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid ${(props) => props.theme.colors.silverGrayTwo};
    ${(props) => props.theme.fonts.variant.regular()};
    font-size: 0.75rem;
    color: ${(props) => props.theme.colors.greyThirteen};
    padding: 3px 6px;

    &:last-child {
        border: none;
    }

`;

const ListInput = styled.input`
    position: relative;
    padding: 3px 6px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 5px;
    outline: none;
    border: 1px solid ${props => props.theme.colors.clearGray};
    color: ${(props) => props.theme.colors.greyThirteen};
    z-index: 20;
    border-radius: 8px;
`;

const DropDownBody = styled.div`
    ${(props) => props.$searchBar && css`
        background-color: ${props => props.theme.colors.highClearGray};
        border-radius: 8px;
        padding: 10px;
        margin-top: 10px;
        z-index: 20;
    `}
`;


export {
    DropDownContainer,
    DropDownHeader,
    DropDownList,
    ListItem,
    ListInput,
    DropDownBody
}