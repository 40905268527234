import React from 'react';
import { CardContainer, CardHeader, CardTitle, Title, CardDescription, Description, CardFooter, Action, SettingsContainer } from "./GenericActionCards.styled";
import Icon from "../../../../../../../elements/icon/Icon";

const GenericActionCard = ({ options, openModalAddAction, openConfirmationModal, customActions, onSettingsClick }) => {
	return (
		<>
			{options && (
				<CardContainer>
					<CardHeader>
						<Icon name={options.icon} width={"35px"} height={"35px"} />
						{options.settings &&
							<SettingsContainer onClick={onSettingsClick}>
								<Icon name={"settingsIcon"} width={"18px"} height={"18px"} />
							</SettingsContainer>
						}
					</CardHeader>
					<CardTitle>
						<Title>{options.title}</Title>
					</CardTitle>
					<CardDescription>
						<Description>
							{options.description}
						</Description>
					</CardDescription>
					<CardFooter>
						{customActions && customActions.map((action, index) => (
							<Action key={index} onClick={() => action.onClick(options)}>
								<Icon name={action.iconName} width={"20px"} height={"20px"} />
							</Action>
						))}
					</CardFooter>
				</CardContainer>
			)}
		</>
	);
};

export default GenericActionCard;