import styled from 'styled-components';
import { Button as BaseButton } from '@mui/base/Button';
import { styled as muiStyled } from '@mui/material';

const ChatInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2;
  width: ${(props) => props.width || '60%'};
  justify-content: center;
  position: relative;
`;

export const TypingIndicator = styled.div`
  font-size: 14px;
  color: #999;
  margin-left: 10px;
`;

const FileAttachmentWrapper = styled.div`
  padding: 10px 15px 10px 15px;
  max-width: 100%;
  position: absolute;
  bottom: 55px;
  border-radius: 10px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: white;

  span {
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  button {
    margin-top: 2px;
  }
`;

const DragAndDropIndicator = styled.div`
  width: 100%;
  position: absolute;
  top: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: white;
`;

const InputButton = styled.label`
  border: none;
  justify-content: center;
  border-radius: 0.5rem;
  cursor: pointer;

  &:hover {
    color: ${(props) => (props.theme.colors.grey)};
  }
`;

const InputRoot = styled.div`
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid ;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: fit-content;
`;

const InputElement = styled.textarea`
  resize: none;
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  min-height: 48px;
  height: 48px;
  max-height: 150px;
  flex-grow: 1;
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 12px;
  outline: 0;

`;

const IconButton = muiStyled(BaseButton)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: inherit;
  cursor: pointer;
  color: black;
`;

const InputAdornment = styled.div`
  margin: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export {
    ChatInputWrapper,
    FileAttachmentWrapper,
    DragAndDropIndicator,
    InputButton,
    InputRoot,
    InputElement,
    IconButton,
    InputAdornment
}
