import {
  TitleView,
  ActionsView, Separator
} from './HeaderView.styled';
import SearchInput from "./SearchInput";
import FilterBy from "./FilterBy";
import {RoundedButton} from "../../../../../elements";
import SortBy from "./SortBy";
import NewConversationModal from '../NewConversationModal/NewConversationModal';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../../../hooks';

const HeaderView = observer(() => {
  const [openNewConversationModal, setOpenNewConversationModal] = useState(false);

  return (
    <>
      <TitleView></TitleView>
      <ActionsView>
        <SearchInput />
        <FilterBy />
        <SortBy />
        <Separator />
        <RoundedButton width="103px" height="48px" onClick={() => setOpenNewConversationModal(true)}>New</RoundedButton>
        <NewConversationModal 
          isOpen={openNewConversationModal}
          setIsOpen={setOpenNewConversationModal}
        />

      </ActionsView>
    </>
  );
})

export default HeaderView;
