import React from 'react';
import { ChatWindowComponentPaper, MessageContainer, ChatWindowAvatar, SpinnerImage, MessageWrapper, ImageWrapper } from './ChatWindowComponent.styled';
import Message from './Message';
import AnnAvatar from '../../images/AnnAvatar.svg';
import Spinner from '../icon/assets/spinners_chat.svg';
import Markdown from 'react-markdown';

const ChatWindowComponent = ({ messages, bottomRef, className, displayButtons = true, width, height, agentIsTyping, boxShadow, showImage, backgroundColor }) => {
  // Filter out consecutive messages of the same type, keeping only the last received message
  let filteredMessages = [];
  let lastReceivedIndex = -1;

  messages.forEach((message, index) => {
    if (message.type !== 'received') {
      filteredMessages.push(message);
    } else {
      // Check if this received message is consecutive with the previous one
      if (index === 0 || messages[index - 1].type !== 'received') {
        lastReceivedIndex = filteredMessages.length;
        filteredMessages.push(message);
      } else {
        filteredMessages[lastReceivedIndex] = message;
      }
    }
  });

  return (
    <ChatWindowComponentPaper elevation={3} className={className} $height={height} boxShadow={boxShadow} sx={{backgroundColor: backgroundColor || 'white'}}>
      {showImage && !messages.length && (
        <ImageWrapper>
          <img alt="Concierge Agent" />
        </ImageWrapper>
      )}
      {filteredMessages.map((item, index) => (
        <Message key={index} messageItem={item} displayButtons={displayButtons} width={width} />
      ))}

      {agentIsTyping && messages.type !== 'sent' && (
        <MessageContainer width={width}>
          <ChatWindowAvatar src={AnnAvatar} alt="Agent Avatar" />
          <MessageWrapper flex>
            <Markdown>ANN agent is typing </Markdown>
            <SpinnerImage src={Spinner} alt="Spinner" />
          </MessageWrapper> 
        </MessageContainer>
      )}

      <div ref={bottomRef} />
    </ChatWindowComponentPaper>
  );
};

export default ChatWindowComponent;
