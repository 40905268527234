import { action, makeObservable, observable, flow } from "mobx";
import { API } from "aws-amplify";
import { 
  getAgent
} from "../../graphql/queries";

import ViewAgentStore from "./pages/ViewAgentStore";
import NewAgentStore from "./pages/NewAgentStore";

class AgentStore {
  isError = false;
  isLoading = false;

  agents = [];
  selectedAgent = undefined;
  account = '';

  viewAgentStore = new ViewAgentStore();
  newAgentStore = new NewAgentStore();

  constructor() {
    makeObservable(this, {
      isError: observable,
      isLoading: observable,
      agents: observable,
      selectedAgent: observable,
      account: observable,
      viewAgentStore: observable,
      newAgentStore: observable,
      onChangeAccount: action,
      onSelectedAgentById: action,
      onSelectedAgent: action,
      fetchGetAgent: flow
    });
  }

  onChangeAccount = (value) => {
    this.account = value;
    this.newAgentStore.onChangeAccount(value);
    this.fetchGetAgent();
  }

  onSelectedAgentById = (value) => {
    const agent = this.agents.find(agent => agent.id === value);
    this.selectedAgent = agent;
  }

  onSelectedAgent = (value) => {
    this.selectedAgent = value;
  }

  *fetchGetAgent() {
    try {
        this.isLoading = true;
        const response = yield API.graphql({
            query: getAgent,
            variables: {
                input: {
                    customer_id: this.account
                },
            },
        });
        const data = JSON.parse(response.data?.getAgent?.body);
        this.agents = data;
    } catch (error) {
        this.isError = true;
    } finally {
        this.isLoading = false;
    }
  }
}

export default AgentStore;