import { useEffect, useState } from 'react';
import { CustomTextArea, Wrapper, Label, InstructionsSuggestionsContainer, Suggestion, ExpandIcon, ExpandIconContent, 
    CustomContent, StyledButton } from './InstructionsBox.styled';
import ExpandImage from '../../../../elements/icon/assets/expand_content.svg';

const InstructionsBox = ({value, onChange, tooltipAvailable=true, placeholder, width, toggleModal, instructionsModal, hideSuggestions=false, displayHelper=false}) => {

    const [ displayTooltip, setDisplayTooltip ] = useState(false);
    const [ instructionsValue, setInstructionsValue ] = useState();

    const suggestionMessages = [
        'Check your Knowledge base before sending answer.',
        'Before answering check every document.',
        'Solve the customer problems in a polite way.',
        'Make sure your answer is aligned with our manuals.'
    ];

    const updateInstructions = (value) => {
        onChange(value)
        setInstructionsValue(value)
    }

    useEffect(() => {
        setInstructionsValue(value);
    }, [value])

    return (
        <Wrapper>
            <CustomContent>
                <CustomTextArea 
                    $width={width || '28vw'}
                    placeholder={placeholder || 'Feel free to enter the correct answer right here.' }
                    rows="4" 
                    cols="51" 
                    value={instructionsValue}
                    onChange={(e) => updateInstructions(e.target.value)} 
                    onMouseOver={() => tooltipAvailable && setDisplayTooltip(true)} 
                    onMouseLeave={() => tooltipAvailable && setDisplayTooltip(false)}
                    disabled={hideSuggestions}
                />
                { tooltipAvailable && (
                    <ExpandIconContent >
                      <ExpandIcon src={ExpandImage} onClick={toggleModal} />
                    </ExpandIconContent>
                )}
            </CustomContent>
            
            { displayTooltip && <Label>Remeber: <br/>
            Your instructions must adhere to applicable laws, avoid compromising others' privacy, and not engage in or promote illegal activities. Our guidelines specifically prohibit using ANN services to harm oneself or others, to develop or distribute harmful materials, or to bypass safety measures. ANN also commits to reporting any detected child sexual abuse material to the appropriate authorities.
            </Label>}
            {!hideSuggestions && <InstructionsSuggestionsContainer $width={width || '28vw'}>
                {suggestionMessages.map((msg, idx) => <Suggestion key={idx} onClick={() =>{updateInstructions(`${instructionsValue} ${msg}`)}} ><span>{msg}</span></Suggestion>)}
            </InstructionsSuggestionsContainer>}
            {displayHelper && <StyledButton onClick={instructionsModal}>Do you need help with the instructions?</StyledButton>}
        </Wrapper>
    );
};

export default InstructionsBox