import { format } from 'date-fns';
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { useBlocker, useNavigate, useSearchParams } from "react-router-dom";

import ConfirmationDialog from "../../../components/common/CommonConfirmationDialog";
import Spinner from "../../../components/common/Spinner";
import { Loading } from "../../../components/loading";
import { 
  CustomCheckbox,
  ConfirmationDialog as CustomConfirmationDialog,
  CustomRadioButton,
  CustomSelect,
  FilesList,
  InputText, 
  RoundedButton,
  SuccessfulModal
} from "../../../elements";
import DragAndDropAssistant from "../../../elements/dragAndDrop/DragAndDropAssistant";
import AdmirationSign from '../../../elements/icon/assets/admiration_sign.svg';
import EthanHumanoidImage from '../../../elements/icon/assets/ethan_humanoid.png';
import { useStore } from "../../../hooks";
import ChatTestingWindow from "../../Chatbox/ChatTestingWindow";
import AgentCompletedModal from "../../NewAgentPage/components/AgentCompletedModal";
import { assistantListFileRoute } from "../../NewAgentPage/components/consts";
import {
  agentType,
  answerType,
  initialActions,
  initialChannels,
  initialTones
} from "../EditAgent/consts";
import AgentActions from "../components/AgentActions/AgentActions";
import {
  AgentDescriptionContainer,
  AgentIcon,
  AgentImgContainer,
  AgentProfileWrapper,
  CheckboxContainer,
  EditAgentWrapper,
  EditMessageInput,
  FormButtonsContainer,
  Label,
  PageWrapper,
  RadioButtonsContainer,
  Separator,
  ConversationStartersContainer
} from './PlaygroundPage.styled';
import InstructionsBox from "./components/InstructionsBox";
import { ExpandableComponent } from '../../../elements';
import { InstructionsHelperComponent } from './components/instructionsHelper';

const PlaygroundPage = observer(() => {

  const navigate = useNavigate();
  const { editAgent, authStore, changeTitle } = useStore();
  const [showModal, setShowModal] = useState(false);
  const [showInstructionsModal, setShowInstructionsModal] = useState(false);

  const [includeConcierge, setIncludeConcierge] = useState(""); 
  const [usePrompter, setUsePrompter] = useState("no");

  const handleIncludeConciergeChange = ( 
      value 
  ) => { 
      setIncludeConcierge(value); 
      editAgent.onChangeIncludeConcierge(value);
  }; 

  const handleUsePrompterChange = (value) => { 
    setUsePrompter(value); 
    editAgent.onChangeUsePrompter(value);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleInstructionsClose = () => {
    setShowInstructionsModal(false);
  };

  useEffect(() => {
    const concierge = editAgent.agents.find(agent => agent.domain === 'concierge');
    const typeName = agentType.find(typeName => typeName.value === editAgent.type)
  
    if (concierge) {
      const match = concierge.human_instructions.match(/\[.*?\]/s);
      if (match) {
        try {
          const agentsInConcierge = JSON.parse(match[0]);
          const isIncluded = agentsInConcierge.some(agent => agent.id.split("#")[1] === editAgent.id);
          handleIncludeConciergeChange(isIncluded ? "yes" : "no");
        } catch (error) {
          handleIncludeConciergeChange("no");
        }
      } else {
        handleIncludeConciergeChange("no");
      }
    }

    if (typeName) {
      editAgent.typeName = typeName.name;
      setPreselectedAgentTypeName(editAgent.typeName);
    }
  }, [editAgent.name])

  const includeConciergeChecked = (value) => value == includeConcierge;
  const usePrompterChecked = (value) => value == usePrompter;


  const blocker = useBlocker(({ currentLocation, nextLocation }) => currentLocation.pathname !== nextLocation.pathname);
  const [searchParams, setSearchParams] = useSearchParams();

  const [toneOptions, setToneOptions] = useState(initialTones);
  const [channelOptions, setChannelOptions] = useState(initialChannels);
  const [actions, setActions] = useState(initialActions)

  const [displayAgentTypeConfirmationModal, setDisplayAgentTypeConfirmationModal] = useState(false);
  const [agentTypeConfirmationMessage, setAgentTypeConfirmationMessage] = useState('');
  const [preselectedAgentType, setPreselectedAgentType] = useState('');
  const [preselectedAgentTypeName, setPreselectedAgentTypeName] = useState('');
  const [showAlertChangeAccount, setShowAlertChangeAccount] = useState(false);

  changeTitle('Playground');

  const selectsWidth = '27.5vw';
  const inputsWidth = '17vw';

  useEffect(() => {
    const customerParam = searchParams.get('customer');
    const agentId = searchParams.get('agent');
    const selectedCustomerId = authStore.selectedAccount.id;

    const handleAgentLogic = (customer) => {
      editAgent.clear();

      if (!agentId || agentId === 'undefined') {
        navigate('/assistants');
      } else {
        editAgent.onChangeAccount(customer);
        editAgent.getAgents(customer, agentId);
      }
    };

    if (!customerParam) {
      handleAgentLogic(selectedCustomerId);
    } else if (selectedCustomerId === customerParam) {
      handleAgentLogic(customerParam);
    } else {
      setShowAlertChangeAccount(true);
      navigate('/assistants');
    }
  }, [searchParams, authStore.selectedAccount]);

  useEffect(() => {
    if(toJS(editAgent.channels)){
      const savedChannels = {...initialChannels};
      toJS(editAgent.channels).map(channel => {
          if(channel in savedChannels){
              savedChannels[channel] = true
          }
      })

      setChannelOptions({...savedChannels})
    }
  }, [editAgent.channels.length, editAgent.name])

  useEffect(() => {
    if(toJS(editAgent.tones)){
      const savedTones = {...initialTones};
      toJS(editAgent.tones)?.map(tone => {
          if(tone in savedTones){
              savedTones[tone] = true
          }
      })
      setToneOptions({...savedTones})
    }
    
  }, [editAgent.tones.length, editAgent.name])

  const onSelectTone = (tone) => {
    const updatedOptions = {...toneOptions, ...tone}
    setToneOptions(updatedOptions)

    const filteredTones = Object.keys(updatedOptions).filter(tone => updatedOptions[tone]);
    if(filteredTones){
        editAgent.onChangeTones(filteredTones);
    }
  }

  const onHandleChangeName = useCallback((e) => {
    editAgent.onChangeName(e.target.value);
  }, [editAgent]);

  const onHandleChangeTitle = useCallback((e) => {
    editAgent.onChangeTitle(e.target.value);
  }, [editAgent]);

  const onHandleChangeInstructions = useCallback((value) => {
    editAgent.onChangeInstructions(value);
  }, [editAgent]);

  const onHandleChangeAnswerExtension = useCallback((value) => {
    editAgent.onChangeAnswerExtension(value);
  }, [editAgent]);

  const onChangeConversationStarter = useCallback((idx, value) => {
    editAgent.updateConversationStarter(Object.keys(editAgent.conversationStarters)[idx], value)
  })

  const onChangeAgentType = (name, value) => {
    setDisplayAgentTypeConfirmationModal(true);
    setPreselectedAgentType(value);
    setPreselectedAgentTypeName(name);
    setAgentTypeConfirmationMessage(`Are you sure to change the user Agent type? Remember that this agent will now be ${name}, instead of ${editAgent.type}`);
  };

  const handleAgentSelect = (name, agent_id) => {
    editAgent.getAgentByID(agent_id);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const openInstructionsModal = () => {
    setShowInstructionsModal(!showInstructionsModal);
  };

  useEffect(() => {
    if(toJS(editAgent.actions)){
      const savedActions = {...initialActions};
      toJS(editAgent.actions)?.forEach(action => {
        if (action in savedActions) {
          savedActions[action] = true;
        }
      })
      setActions({...savedActions})
    }
  }, [editAgent.actions.length, editAgent.actions])

  const onSelectAction = (action) => {
    const newActions = { ...actions, [action]: !actions[action] }
    setActions(newActions)
    const activatedActions = Object.keys(newActions).filter(action => newActions[action]);
    editAgent.onChangeActions(activatedActions);
  }

  return (
    <>
      { editAgent.finishedInitialLoad ? (
        <PageWrapper>

            <FormButtonsContainer>
              <RoundedButton width={'180px'} disabled={editAgent.isLoading} kind={'secondary'} onClick={() => {navigate('/assistants')}}>Back to Your Agents</RoundedButton>
              <RoundedButton width={'180px'} disabled={editAgent.isLoading} kind={'primary'} onClick={() => editAgent.updateAgent()}>{editAgent.isLoading ? 'Loading...' : 'Update'}</RoundedButton>
            </FormButtonsContainer>

          <EditAgentWrapper $gridColumnsWidth={'34% 6% 60%'}>

            <AgentProfileWrapper>
              {editAgent.agents && 
                <CustomSelect 
                  selectedDefault={editAgent.name}
                  placeholder="Select Agent" 
                  width={selectsWidth} 
                  margin='15px 0 15px 0'
                  options={editAgent.agents.map(a => ({name: a.name, value: a.id}))}
                  handleSelect={handleAgentSelect}/>
              }

              <AgentImgContainer>
                <AgentIcon src={EthanHumanoidImage}/>
                <AgentDescriptionContainer>
                  <InputText 
                    placeholder="Agent name" 
                    width={inputsWidth} 
                    margin="3px 0" 
                    value={editAgent.name} 
                    onChange={onHandleChangeName}
                    displayError={!editAgent.name} 
                    validationError="Your agent needs a name before saving the changes."
                    disabled={editAgent.isConcierge}
                  />
                  {!editAgent.isConcierge &&
                    <InputText 
                      placeholder="Agent title" 
                      width={inputsWidth} 
                      margin="3px 0 6px 0" 
                      value={editAgent.title}
                      onChange={onHandleChangeTitle}
                    />
                  }
                  <Label $bold="200">Updated: {editAgent.updatedDate ? format(editAgent.updatedDate, 'MM/dd, hh:mm a') :''}</Label>
                </AgentDescriptionContainer>
              </AgentImgContainer>

                
              {!editAgent.isConcierge && <><CustomSelect 
                placeholder="Agent’s Type List" 
                width={selectsWidth} 
                margin='10px 0 15px 0' 
                options={agentType} 
                handleSelect={onChangeAgentType} 
                selectedDefault={editAgent.typeName}
                preventSelection={true}
                textTransform={'capitalize'}
              />
              
              <Label>Agent's tone</Label>
              <CheckboxContainer>
                {toneOptions && 
                  Object.keys(toneOptions).map(option => 
                    <CustomCheckbox 
                    key={option} 
                    label={option} 
                    onSelectTone={onSelectTone} 
                    options={toneOptions} 
                    width="13vw" 
                    />
                  )
                }
              </CheckboxContainer>
              
              <Label margin='0'>Files</Label>
              <FilesList files={toJS(editAgent.files)} onClickRemove={row => editAgent.displayDeleteFileDialog(row)}/>   
            
              <DragAndDropAssistant
                fileRoute={assistantListFileRoute}
                customer={editAgent.account}
                extensions={{
                  'application/msword': ['.docx', '.doc'],
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx', '.doc'],
                  "text/plain": [".txt"],
                  "application/pdf": [".pdf"],
                }}
                rejectMessage='Please upload a valid format file:*.doc, *.docx, *.pdf or *.txt'
                onFinishUpload={() => editAgent.updateAgent()}
                onFilesChange={editAgent.onChangeFiles}
                onError={editAgent.onSetError}
                width='28vw'
                currentUploadedFilesLength={toJS(editAgent.files).length}
              />
              </>}
              <Label margin="0 0 5px 0">Instructions</Label>
              <InstructionsBox value={editAgent.instructions} onChange={onHandleChangeInstructions} toggleModal={toggleModal} instructionsModal={openInstructionsModal} hideSuggestions={editAgent.isConcierge} displayHelper={true}/>

              <Label margin="0 0 5px 0">Conversation Starters</Label>
              <ConversationStartersContainer>
              {Object.values(editAgent.conversationStarters).map((cs, idx) => 
                <InputText key={idx} placeholder={`Conversation Starter ${idx+1}`} width='27.5vw' value={cs} onChange={(e) => onChangeConversationStarter(idx, e.target.value)}/>
              )}
              </ConversationStartersContainer>
              
              {!editAgent.isConcierge &&
                <>
                  <Label margin="0 0 5px 0">Include agent in concierge?</Label>
                  <RadioButtonsContainer>
                    <CustomRadioButton name="includeConcierge" label="Yes" value="yes" onChange={handleIncludeConciergeChange} selectedValue={includeConcierge} checked={includeConciergeChecked('yes')}/>
                    <CustomRadioButton name="includeConcierge" label="No" value="no" onChange={handleIncludeConciergeChange} selectedValue={includeConcierge} checked={includeConciergeChecked('no')}/>
                  </RadioButtonsContainer>
      
                  <Label>Use instruction helper?</Label>
                  <RadioButtonsContainer>
                    <CustomRadioButton name="useHelper" label="Yes" value="yes" onChange={handleUsePrompterChange} selectedValue={usePrompter} checked={usePrompterChecked('yes')}/>
                  <CustomRadioButton name="useHelper" label="No" value="no" onChange={handleUsePrompterChange} selectedValue={usePrompter} checked={usePrompterChecked('no')}/>
                  </RadioButtonsContainer>
                </>
              }
              {!editAgent.isConcierge &&
                <>
                  <Label margin="10px 0">Actions:</Label>
                  <AgentActions actionsValues={actions} onSelectAction={onSelectAction} />
                </>
              }
            </AgentProfileWrapper>
              

            <Separator>
              <div></div>
            </Separator>

            {!editAgent.showOverlay ? 
              (<section>
                <ChatTestingWindow showHeader={false} agentName={editAgent.name} agentId={editAgent.id} agentDomain={editAgent.isConcierge ? 'concierge' : ''} customer={editAgent.account} showOverlay={editAgent.showOverlay}/>
              </section>)
              :
              <Spinner className="spinner" />
            }
          </EditAgentWrapper>

          {editAgent.isError && <SuccessfulModal
              isOpen={editAgent.isError}
              onClose={() => editAgent.onClearError()}
              imageSrc={AdmirationSign}
              title="Oops!"
              subtitle="An error occurred."
              subtitle2="Please try again later."
          />}

          {showModal && <ExpandableComponent
              isOpen={showModal}
              title={editAgent.instructions}
              onClose={handleClose}
          />}

          {showInstructionsModal && <InstructionsHelperComponent
              isOpen={showInstructionsModal}
              initialTitle={editAgent.instructions}
              onClose={() => handleInstructionsClose()}
              agentName={editAgent.name} 
              agentId={editAgent.id}
              customer={editAgent.account}
          />}

          <ConfirmationDialog
            open={editAgent.deleteFile}
            onClose={() => editAgent.displayDeleteFileDialog(false)}
            message="Are you sure you want to delete this file from this assistant?"
            onConfirm={() => editAgent.confirmRemoveFile()}
            maxWidth="xl"
            fullWidth={false}
          />

          <ConfirmationDialog
            open={displayAgentTypeConfirmationModal}
            onClose={() => setDisplayAgentTypeConfirmationModal(false)}
            message={agentTypeConfirmationMessage}
            onConfirm={() => {editAgent.onChangeType(preselectedAgentType); editAgent.onChangeTypeName(preselectedAgentTypeName); setDisplayAgentTypeConfirmationModal(false)}}
            maxWidth="sm"
            fullWidth={false}
          />

          <CustomConfirmationDialog
            isOpen={blocker.state === 'blocked'}
            onClose={() => {
              if (showAlertChangeAccount) {
                authStore.rollbackAccount();
                setShowAlertChangeAccount(false);
              }

              blocker.reset();
            }}
            onConfirm={() => blocker.proceed()}
            confirmationLabel='Yes, Exit'
            cancelLabel='Back to playground'
            title="Are you sure to Exit now?"
            subtitle='If you did changes without clicking the “Update” Button, those changes will be lost.'
          />

          {editAgent.updatedAgentSuccessfully && <AgentCompletedModal description="Agent has been successfully edited." redirectToAssistants={false} agentid={editAgent.id} customerid={editAgent.account} displayFooter={false}/>}
          
        </PageWrapper>):
        <Loading/>
      }
    </>
  );
});

export default PlaygroundPage;
