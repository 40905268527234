import {css, styled} from "styled-components";

const DefaultTheme = {
  fonts: {
    family: {
      inter: "'Inter', sans-serif",
    },
    variant: {
      thin: () => {
        return css`
          font-family: ${(props) => props.theme.fonts.family.inter};
          font-weight: 100;
        `;
      },
      extraLight: () => {
        return css`
          font-family: ${(props) => props.theme.fonts.family.inter};
          font-weight: 200;
        `;
      },
      light: () => {
        return css`
          font-family: ${(props) => props.theme.fonts.family.inter};
          font-weight: 300;
        `;
      },
      regular: () => {
        return css`
          font-family: ${(props) => props.theme.fonts.family.inter};
          font-weight: 400;
        `;
      },
      medium: () => {
        return css`
          font-family: ${(props) => props.theme.fonts.family.inter};
          font-weight: 500;
        `;
      },
      semiBold: () => {
        return css`
          font-family: ${(props) => props.theme.fonts.family.inter};
          font-weight: 600;
        `;
      },
      bold: () => {
        return css`
          font-family: ${(props) => props.theme.fonts.family.inter};
          font-weight: 700;
        `;
      },
      extraBold: () => {
        return css`
          font-family: ${(props) => props.theme.fonts.family.inter};
          font-weight: 800;
        `;
      },
      black: () => {
        return css`
          font-family: ${(props) => props.theme.family.inter};
          font-weight: 900;
        `;
      }
    },
    size: {
      small: '0.7em',
      normal: '1em',
      medium: '1.3em',
      large: '1.8em',
      //V2
      lt1: '12px',
      b1: '16px',
      b2: '14px',
      s1: '18px'
    }
  },
  colors: {
    primary: "#232323",
    secondary: "#EDEDED",
    white: "#FFFFFF",
    black: "#000000",
    grey: "#666666",
    greyTwo: "#717171",
    greyThree: "#7C7C7C",
    greyFour: "#5A5A5A",
    greyFive: '#EFEFEF',
    greySix: '#F5F3F0',
    greySeven: '#F0F0F0',
    greyEight: "#E1E1E1",
    greyNine: "#2C313B",
    greyEleven: "#2A2A2A",
    greyTen: "#F1F1F1",
    greyTwelve: "#DEDEDE",
    greyThirteen: "#282A37",
    border: "#D9D9D9",
    darkCharcoal: "#333333",
    lightGrey: "#dddddd",
    lighterGray: "#D9D9D9",
    silverGray: "#DADADA",
    silverGrayTwo: "#BABABA",
    silverGrayThree: "#FAFAFA",
    silverGrayFour: "#E5E5E5",
    red: "#FF0000",
    lightBlue: "#9CB5F5",
    activeBlue: "#2173FF",
    blueOne: "#04245C",
    //V2
    highClearGray: "#F2F2F2",
    primaryWhite: "#FAFAFA",
    clearGray: "#BABABA",
    darkGray: '#2A2A2A',
    clearBlue: "#2173FF",
    greenPrimary: "#00D9AA",
    borderGray: '#CBCBCB',
    dhusarGray: '#ABABAB',
    cerebralGray: '#CCCCCC',
    brightNavyBlue: '#1976d2',
    golden: "#A1824A",
    blackTwo: "#1C170D",
    thumbGray: "#E6E6E6",
    digital: "#636363",
    veryLightGray: "#F5F5F5",
    argent: "#C0C0C0",
    taupeGray: "#898989",
    antiFlashWhite:"#EFF3FA",
    blueCrayola: "#2B79FF",
    culturedGray: "#F6F6F6",
    silverChalice: "#ADADAD",
    silverFoil: "#B3AFAB"
  }
}

export const CustomBox = styled.div`
  width: ${(props) => props.width || '100%'};
  display: ${(props) => props.display || 'flex'};
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  align-items: ${(props) => props.alignItems || 'center'};
  margin-right: ${(props) => props.marginRight || '5rem'};
`;

export default DefaultTheme;
