import  NotificationList from "./NotificationList";
import { Route, Routes } from "react-router-dom";
import { useStore } from "../../hooks";

const NotificationPage = () => {
  const { changeTitle, notificationStore } = useStore();

  changeTitle('Notifications');
  notificationStore.setCurrentPage('list');

  return (
    <Routes>
      <Route index element={<NotificationList />} />
    </Routes>
  );
};

export default NotificationPage;
