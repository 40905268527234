import React, { useEffect, useState } from "react";
import {
    Backdrop,
    ModalWrapper,
    ModalContainer,
    ModalTitle,
    ModalContainerCloseButton,
    ModalCloseButton,
    ModalChatContainer,
    ModalButtonContainer,
    ModalAddButton,
    SpinnerImage
} from "./instructionsHelper.styled";
import ChatInput from '../../../../../elements/input/ChatInput';
import { API, graphqlOperation } from "aws-amplify";
import { getConversationChatAssistant } from '../../../../../graphql/queries';
import { onChatAssistantResponse } from '../../../../../graphql/subscriptions';
import _ from "lodash";
import { useStore } from "../../../../../hooks";
import { ReactComponent as ReloadInstructions } from '../../../../../elements/icon/assets/reload_instructions.svg';
import Spinner from '../../../../../elements/icon/assets/spinners_chat.svg';
import Markdown from 'react-markdown';

const InstructionsHelperComponent = ({ isOpen, onClose, initialTitle, height, width, zIndex = false, agentName, agentId, customer }) => {
    const { editAgent, authStore } = useStore();
    const [newMessage, setNewMessage] = useState('');
    const [title, setTitle] = useState('');
    const [messages, setMessages] = useState([]);
    const [iconChanged, setIconChanged] = useState(false);
    const [originalMessage, setOriginalMessage] = useState('');
    const [agentIsTyping, setAgentIsTyping] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(initialTitle){
            setTitle(initialTitle)
        }
    }, [initialTitle])

    useEffect(() => {
        if (authStore.selectedAccount && authStore.selectedAccount.id) {
            editAgent.getAgentPrompter(authStore.selectedAccount.id);
        }
    }, [authStore.selectedAccountAgent]);

    const handleClose = () => {
        onClose();
    };

    const handleOnChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleOnKeyPress = (e) => {
        if(!editAgent.agentPrompter){
            return setMessages(prevMessages => [...prevMessages, { message: "Agent prompter not available", type: "received" }]);
        }

        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const handleSendMessage = async (messageToSend = newMessage) => {
        if (messageToSend.trim() === "") {
            messageToSend = originalMessage;
        }
        if (messageToSend.trim() === "") return;
    
        setNewMessage("");
        setOriginalMessage(messageToSend);
        const params = {
            message: messageToSend,
            campaign_id: '',
            campaign_version: '',
            agent: editAgent.agentPrompter,
            customer_id: customer,
            is_concierge: false,
            attachment: '{}'
        };
    
        try {
            let local_msgs = _.union(messages, []);
    
            const resultConversationOpenAi = await API.graphql({
                query: getConversationChatAssistant,
                variables: { input: params },
            });
    
            if (!resultConversationOpenAi.errors?.length) {
                const apiRespnse = resultConversationOpenAi.data.getConversationChatAssistant;
                setAgentIsTyping(true);
    
                local_msgs = _.union(local_msgs, [{ message: messageToSend, type: "sent" }]);
                setMessages(local_msgs);
    
                const assistantResponseSub = API.graphql(
                    graphqlOperation(onChatAssistantResponse, { id: apiRespnse.id })
                ).subscribe({
                    next: ({ provider, value }) => {
                        try {
                            const subApiRespnse = value.data.onChatAssistantResponse;
                            const body = JSON.parse(subApiRespnse.body);
    
                            if (body && typeof body === 'object' && body.answer) {
                                const openAiAnswer = body.answer;
    
                                if (subApiRespnse.status === 'done') {
                                    setTitle(openAiAnswer);
                                    assistantResponseSub.unsubscribe();
                                    
                                    setIconChanged(true);
                                    setAgentIsTyping(false);
                                }
                            } else {
                                setAgentIsTyping(false);
                                throw new Error("Server response is not a valid JSON");
                            }
                        } catch (error) {
                            console.error("Error parsing JSON response:", error);
                            setMessages([...local_msgs, { message: "Oops! Something went wrong. Please try again.", type: "received" }]);
                            setAgentIsTyping(false);
                        }
                    },
                    error: (error) => {
                        console.warn(error);
                        setMessages([...local_msgs, { message: "Oops! Something went wrong. Please try again.", type: "received" }]);
                    }
                });
            }
        } catch (error) {
            console.error(error);

            if (
                error.errors &&
                error.errors[0].errorType === "Lambda:ExecutionTimeoutException"
            ) {
                setMessages([
                    ...messages,
                    {
                        message: "Sorry, I'm taking longer than expected to get your answer. I'll notify my team to teach me more about this topic. Meanwhile, let's continue with other questions you have?",
                        type: "received",
                    },
                ]);
            }
        }
    };

    const handleCustomIconClick = () => {
        handleSendMessage(newMessage.trim() === "" ? originalMessage : newMessage);
    };

    const updateInstructions = () => {
        setIsLoading(true);
        editAgent.onChangeInstructions(title);
        editAgent.updateAgent().finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <Backdrop isOpen={isOpen} $zIndex={zIndex}>
            <ModalWrapper $height={height} $width={width}>
                <ModalContainer>
                    <ModalTitle>
                        {agentIsTyping ? (
                            <SpinnerImage src={Spinner} alt="Spinner" />
                        ) : (
                            title && <Markdown>{title}</Markdown>
                        )}
                    </ModalTitle>
                </ModalContainer>
                <ModalContainerCloseButton>
                    <ModalChatContainer>
                        <ChatInput
                            onChange={handleOnChange}
                            onKeyPress={handleOnKeyPress}
                            showSpinner={false}
                            newMessage={newMessage}
                            handleSendMessage={handleSendMessage}
                            placeholder="Type your instructions"
                            showFileIcon={false}
                            customSendMessageIcon={iconChanged ? <ReloadInstructions /> : null}
                            handleCustomIconClick={handleCustomIconClick}
                            width="95%"
                        />
                    </ModalChatContainer>
                    <ModalButtonContainer>
                        <ModalAddButton onClick={!isLoading ? updateInstructions : null}>
                            {isLoading ? 'Loading...' : 'Add Instructions'}
                        </ModalAddButton>
                        <ModalCloseButton onClick={handleClose}>Close</ModalCloseButton>
                    </ModalButtonContainer>
                </ModalContainerCloseButton>
            </ModalWrapper>
        </Backdrop>
    );
};

export default InstructionsHelperComponent;