import {action, computed, flow, makeObservable, observable} from "mobx";
import { API, Auth } from "aws-amplify";
import { getCustomers, getAgent } from "../../graphql/queries";

class AuthStore {
  isLoading =  true;
  isError =  false;
  isSuperAdmin = false;
  isCustomerAdmin = false;
  isUser = false;
  customerId =  "";
  userId = "";
  customer = null;
  selectedAccount = null;
  favoriteAccount = [];
  listAccount = [];
  agents = [];

  constructor() {
    makeObservable(this,{
      isLoading: observable,
      isError: observable,
      isSuperAdmin: observable,
      isCustomerAdmin: observable,
      isUser: observable,
      customerId: observable,
      customer: observable,
      userId: observable,
      listAccount: observable, // list all account
      selectedAccount: observable, // selected account to show in menu and to apply all functions all over the app
      favoriteAccount: observable, // list off last 5 accounts selected
      agents: observable,
      selectedAccountInitials: computed, // function to get initials of selected account
      sortedAccountListByFavs: computed, // function to sort list of accounts get priority to favorites on top list
      clear: action,
      pickAccount: action, // function to select account
      rollbackAccount: action, // function to rollback to previous account
      saveLocalStorageFavoriteAccounts: action, // function to save favorites account in local storage to keep it after refresh
      getGlobalInformation: flow,
      fetchAgents: flow
    });
  }

  get selectedAccountInitials() {
    const initials = [];
    if (this.selectedAccount) {
      const splitName = this.selectedAccount.name.split(" ").slice(0, 2);
      let initials = "";

      if (splitName.length === 1) {
        initials = splitName[0].slice(0, 2);

      } else {
        initials = splitName.map((word) => word.charAt(0)).join("");
      }

      return initials.toUpperCase()
    }
  }

  pickAccount(id) {
    const account = this.listAccount.find((account) => account.id === id);
    this.saveLocalStorageFavoriteAccounts(account.id);
    this.selectedAccount = account;
  }

  rollbackAccount(){
    if (this.favoriteAccount.length > 0) {
      this.pickAccount(this.favoriteAccount[1]);
    }
  }

  saveLocalStorageFavoriteAccounts(id){
    const currentFavoritesAccount = JSON.parse(localStorage.getItem("favoriteAccount")) || [];
    const existId = currentFavoritesAccount.findIndex((idSaved) => idSaved.trim() === id.trim());

    if (existId !== -1) {
      currentFavoritesAccount.splice(existId, 1);
    }

    currentFavoritesAccount.unshift(id);

    if (currentFavoritesAccount.length > 5) {
      currentFavoritesAccount.pop();
    }

    this.favoriteAccount = currentFavoritesAccount;
    localStorage.setItem("favoriteAccount", JSON.stringify(currentFavoritesAccount));
  }

  get sortedAccountListByFavs() {
    const indexMap = {};
    const currentFavoritesAccount = [...this.favoriteAccount];
    const currentAccounts = [...this.listAccount];

    currentFavoritesAccount.forEach((favorite, index) => {
      indexMap[favorite] = index;
    });

    return currentAccounts.sort((a, b) => {
      const indexA = indexMap[a.id];
      const indexB = indexMap[b.id];

      if (indexA !== undefined && indexB !== undefined) {
        return indexA - indexB;
      }

      if (indexA !== undefined) {
        return -1;
      }
      if (indexB !== undefined) {
        return 1;
      }

      return 0;
    });
  }

  clear(){
    this.saveLocalStorageFavoriteAccounts(this.customer.id);

    this.isLoading =  true;
    this.isError = false;
    this.isSuperAdmin = false;
    this.isCustomerAdmin = false;
    this.isUser = false;
    this.customerId =  "";
    this.userId = "";
    this.customer = null;
    this.selectedAccount = null;
    this.listAccount = [];
    this.agents = [];
  }

  *getGlobalInformation(){
    try {
      const userInfo = yield Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      const groups = userInfo.signInUserSession.accessToken.payload["cognito:groups"];

      const responseCustomer = yield API.graphql({
        query: getCustomers,
        variables: {
          input: { id: userInfo.attributes["custom:customer"] ?? "" },
        },
      });

      const customerData = JSON.parse(responseCustomer?.data?.getCustomers?.body);

      const response = yield API.graphql({
        query: getCustomers,
        variables: {
          input: { id: "" },
        },
      });

      const customerList = JSON.parse(response.data?.getCustomers?.body).map(
        ({ name, id }) => ({ name, id })
      );

      const favoritesAccount = JSON.parse(localStorage.getItem("favoriteAccount")) || [];

      if (favoritesAccount.length === 0) {
        // set main principal account as first favorite if favoritesAccount is empty
        this.saveLocalStorageFavoriteAccounts(customerData?.id);
        favoritesAccount.unshift(customerData?.id);
      }

      this.isSuperAdmin = groups.includes("superadmin");
      this.isCustomerAdmin = groups.includes("customeradmin");
      this.isUser = groups.includes("user");
      this.customerId = userInfo.attributes["custom:customer"] ?? "";
      this.userId = userInfo.attributes.sub ?? "";
      this.customer = customerData;
      this.selectedAccount = customerList.find((account) => account.id === favoritesAccount[0]);
      this.listAccount = [...customerList];
      this.favoriteAccount = favoritesAccount;
      this.isLoading = false;
    } catch (e) {
      this.clear();
    }
  }

  *fetchAgents() {
    try {
      this.isError = false;
      const response = yield API.graphql({
          query: getAgent,
          variables: {
              input: {
                  customer_id: this.selectedAccount.id
              },
          },
      });
      const data = JSON.parse(response.data?.getAgent?.body);
      this.agents = data.filter(agent => agent.domain === 'concierge' || agent.domain === '');
    } catch (error) {
        this.isError = true;
    }
  }
  
}

export default AuthStore;