import App from "../components/app/App";
import ErrorPage from "../error-page";
import { BrowserRouter, Navigate, Route, Routes, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import UserPage from "../pages/UsersPage";
import CustomerAccountPage from "../pages/CustomerAccountPage";
import KeyPage from "../pages/CustomerDataPlatformPage";
import ChannelPage from "../pages/ChannelManagmentModulePage";
import CampaignPage from "../pages/Campaigns";
import AssistantManagementPage from '../pages/AssistantManagement';
import ContactPage from "../pages/ContactPage";
import ChatWindow from "../pages/Chatbox/ChatWindow";
import ChatTestingWindow from "../pages/Chatbox/ChatTestingWindow";
import OnboardingPage from "../pages/OnboardingPage";
import ProfilePage from "../pages/ProfilePage/ProfilePage";
import NewAgentPage from "../pages/NewAgentPage";
import NewUserPage from "../pages/NewUserPage/NewUserPage";
import HomeChatPage from "../pages/HomeChatPage/HomeChatPage";
import NewCampaignPage from "../pages/NewCampaignPage/NewCampaignPage";
import EditAgentPage from "../pages/AgentPage/EditAgent/EditAgentPage";
import ContactFlowPage from "../pages/ContactFlow";
import PlaygroundPage from "../pages/AgentPage/PlaygroundPage/PlaygroundPage";
import NotificationPage from "../pages/NotificationPage";
import UnifiedProfilePage from "../pages/UnifiedProfile";
import AnnUnifiedProfilePage from "../pages/ann-unified-profile";
import DashboardsPage from "../pages/DashboardsPage/DashboardsPage";
import AgentsTreePage from "../pages/v2/AgentsTeam/AgentsTree/AgentsTreePage";
import CreateAgentsTeamStepper from "../pages/v2/AgentsTeam/CreateAgentsTeamStepper";
import UsersPage from "../pages/v2/UsersPage";

//v2
import AssistantManagementPageV2 from '../pages/v2/AsistantManagement';
import AgentsTeamPage from "../pages/v2/AgentsTeam";
import TemplateEditor from "../elements/v2/TemplateEditor/TemplateEditor";
import ConversationPage from "../pages/v2/Conversations";


const RoutesMenu = createBrowserRouter(
  createRoutesFromElements(
        <Route path={"/"} element={<App />} errorElement={<ErrorPage />}>
          <Route
            index
            element={<Navigate to="/home" />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/users/*"
            element={<UserPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/customers/*"
            element={<CustomerAccountPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/keys/*"
            element={<KeyPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/channels/*"
            element={<ChannelPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/conversations/*"
            element={<CampaignPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/assistants/*"
            element={<AssistantManagementPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/contacts/*"
            element={<ContactPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/chat/*"
            element={<ChatWindow />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/chat-testing/*"
            element={<ChatTestingWindow />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/metrics/*"
            element={<DashboardsPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/new-agent/*"
            element={<NewAgentPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/duplicate-agent/*"
            element={<EditAgentPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/playground/*"
            element={<PlaygroundPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/new-user/*"
            element={<NewUserPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/new-conversation/*"
            element={<NewCampaignPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/profile/*"
            element={<ProfilePage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/home"
            element={<HomeChatPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/notifications"
            element={<NotificationPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/contacts/upload/*"
            element={<ContactFlowPage />}
            errorElement={<ErrorPage />}
          />
          <Route
            path="/unified-profile"
            element={<UnifiedProfilePage />}
            errorElement={<ErrorPage />}
          />

          <Route
            path="/ann-unified-profile"
            element={<AnnUnifiedProfilePage />}
            errorElement={<ErrorPage />}
          />

          <Route path="v2">
            <Route
              path="agents-team/*"
              element={<AgentsTeamPage />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="assistants/*"
              element={<AssistantManagementPageV2 />}
              errorElement={<ErrorPage />}
            />
            {/* THIS ROUTE IS TEMPORARY AND WHEN CONVERSATIONS IS IMPLEMENTED WILL BE REMOVED */}
            <Route
              path="template/*"
              element={<TemplateEditor />}
            />
            <Route
              path="users/*"
              element={<UsersPage />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="conversations/*"
              element={<ConversationPage />}
              errorElement={<ErrorPage />}
            />
          </Route>
        </Route>
        
  )
);

export default RoutesMenu;
