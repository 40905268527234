import styled from "styled-components";
import { ModalBox } from "../../../../../elements/v2/modals/Modals.styled";


export const Subtitle = styled.p`
    ${(props) => props.theme.fonts.variant.semiBold()};
    font-size: 1em;
`;

export const Label = styled.p`
    ${(props) => props.theme.fonts.variant.regular()};
    font-size: 0.8750em;
`;


export const InputWrapper = styled.span`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 20px;

    p {
        margin: 0;
    }

    > div {
        margin: 0;
    }
`;

export const DateButtonWrapper = styled.div`
    button {
        background-color: ${props => props.theme.colors.highClearGray};
        border: 1px solid ${props => props.theme.colors.clearGray};
        color: ${props => props.theme.colors.darkGray};
        font-weight: 400;
        font-size: 0.875em;
        justify-content: flex-start;
        padding-left: 15px;
    }
`;

export const ActivationScheduleWrapper = styled.div`
    display: flex;
    gap: 10px;
`;

export const ModalActionsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const ModalBoxGray = styled(ModalBox)`
    background-color: ${props => props.theme.colors.primaryWhite};
`;

export const PickerWrapper = styled.div``;