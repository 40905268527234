import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  width: 100%;
  height: -webkit-fill-available;
`;

export const TabsHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 95px;
`;

export const Tab = styled.div`
  height: 95px;
  padding: 9px 16px;
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? props.theme.colors.activeBlue : 'transparent')};
  color: ${(props) => (props.isActive ? props.theme.colors.white : props.theme.colors.clearGray)};
  ${(props) => props.theme.fonts.variant.regular()};
  font-size: 1em;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;

`;

export const TabContent = styled.div`
  padding: 0px;
  overflow-y: auto;
  border: 1px solid ${(props) => props.theme.colors.clearGray};
  border-bottom: 0;
  min-width: 200px;

  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.primaryWhite};
  }
   
  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.primaryWhite};
  }
`;

export const TabContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
