import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { API } from "aws-amplify";
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCampaignJourneys,
  putCampaignJourneys,
} from "../../graphql/queries";
import {observer} from "mobx-react";


const CampaignJourneyList = observer(() => {
  // Loading context
  const navigate = useNavigate();
  const location = useLocation();

  const [journeys, setJourneys] = useState([]);
  const [isFetchingJourneys, setIsFetchingJourneys] = useState(false);
  const [isLoadingJourneysError, setIsLoadingJourneysError] = useState(false);
  const [isEditingJourney, setIsEditingJourney] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const application_id = location.state && location.state.data;

  const fetchJourneys = async () => {
    try {
      setIsFetchingJourneys(true);
      const response = await API.graphql({
        query: getCampaignJourneys,
        variables: { input: { application_id: application_id } },
      });
      const journeys_data = JSON.parse(
        response.data?.getCampaignJourneys?.body
      );
      setJourneys(journeys_data);
    } catch (error) {
      console.error("Error getting Journeys", error);
      setIsLoadingJourneysError(true);
    } finally {
      setIsFetchingJourneys(false);
    }
  };

  useEffect(() => {
    fetchJourneys();
  }, [isEditingJourney]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "name",
        header: "Name",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "schedule.start_time",
        header: "Start Date",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "schedule.end_time",
        header: "End Date",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "state",
        header: "State",
        enableEditing: false,
        size: 80,
      },
    ],
    [validationErrors]
  );

  const handleGoBack = () => {
    navigate("/conversations");
  };

  async function UsePutCampaign(values) {
    try {
      setIsEditingJourney(true);
      const response = await API.graphql({
        query: putCampaignJourneys,
        variables: {
          input: { application_id: application_id, journey_id: values.id },
        },
      });
      setIsEditingJourney(false);
    } catch (error) {
      console.log("error: ", error);
      setIsLoadingJourneysError(true);
    }
  }

  const handleUpdateJourney = async ({ values, table }) => {
    setValidationErrors({});
    await UsePutCampaign(values);
    table.setEditingRow(null);
  };

  const table = useMaterialReactTable({
    columns,
    data: journeys,
    editDisplayMode: "modal",
    enableEditing: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isLoadingJourneysError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleUpdateJourney,

    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Edit Journey</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),

    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton
            color="success"
            disabled={row?.original?.state != "DRAFT"}
            onClick={() => table.setEditingRow(row)}
          >
            <PlayCircleOutlineIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),

    renderTopToolbarCustomActions: () => (
      <div>
        <Button variant="contained" onClick={handleGoBack}>
          Back
        </Button>
      </div>
    ),
    state: {
      isLoading: isFetchingJourneys,
      isSaving: isEditingJourney,
      showAlertBanner: isLoadingJourneysError,
      showProgressBars: isFetchingJourneys,
    },
  });

  return (
    <div>
      <MaterialReactTable table={table} />
    </div>
  );
});

export default CampaignJourneyList;
