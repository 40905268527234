import React from 'react';
import ReactDOM from 'react-dom';
import { ModalOverlay, ModalContent, CloseButton, Row, Label, TextField, TextArea, Header,
	Actions, Button, HeaderIcon,HeaderType } from './ModalAddAction.styled';
import Icon from "../../../../../../../elements/icon/Icon";
import { observer } from 'mobx-react';
import { useStore } from "../../../../../../../hooks";

const ModalAddAction = observer(({ isOpen, onClose}) => {
	const { conversationsActionsStore } = useStore();
	const { modalAddActionType, modalAddActionIcon,
		modalAddActionTitle, modalAddActionDescription } = conversationsActionsStore;

	if (!isOpen) return null;

	return ReactDOM.createPortal(
		<ModalOverlay>
			<ModalContent>
				<CloseButton onClick={onClose}>
					<Icon name={"closeIconGray"} width={"15px"} height={"15px"} />
				</CloseButton>
				<Row>
					<Header>
						<HeaderIcon>

							{modalAddActionIcon && (
								<Icon name={modalAddActionIcon} width={"35px"} height={"35px"} />
							)}
						</HeaderIcon>
						<HeaderType>
							{modalAddActionType && modalAddActionType}
						</HeaderType>
					</Header>
				</Row>
				<Row>
					<Label>Name</Label>
					<TextField type="text" placeholder={"Enter Name"} />
				</Row>
				<Row>
					<Label>Description</Label>
					<TextArea type="text" placeholder={"Enter Description"} />
				</Row>
				<Row>
					<Actions>
						<Button onClick={onClose}>Cancel</Button>
						<Button>Save</Button>
					</Actions>
				</Row>
			</ModalContent>
		</ModalOverlay>,
		document.body
	);
});

export default ModalAddAction;