import React, { useState, useEffect } from 'react';
import { MainContainer, Content, MainCard, MainCardTitle, MainIconContainer } from "./Actions.styled";
import Icon from "../../../../../elements/icon/Icon";
import ActionCards from "./components/ActionCards/ActionCards";
import ModalLibrary from "./components/ModalLibrary/ModalLibrary";
import ModalAddAction from "./components/ModalAddAction/ModalAddAction";
import { ConfirmationModal } from "../../../../../elements/v2/modals";
import { observer } from 'mobx-react';
import { useStore } from "../../../../../hooks";


const Actions = observer(() => {
  const { conversationsActionsStore } = useStore();
  const { listOfCards } = conversationsActionsStore;


  const [isModalLibraryOpen, setIsModalLibraryOpen] = useState(false);
  const [isModalAddActionOpen, setIsModalAddActionOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationModalTitle, setConfirmationModalTitle] = useState('');
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');

  const openModalLibrary = () => {
    setIsModalLibraryOpen(true);
  };

  const closeModalLibrary = () => {
    setIsModalLibraryOpen(false);
  };

  const openModalActionLibraryOpen = () => {
    setIsModalAddActionOpen(true);
    closeModalLibrary();
  };

  const closeModalAddAction = () => {
    setIsModalAddActionOpen(false);
  };

  const openConfirmationModal = (title, message) => {
    setConfirmationModalTitle(title);
    setConfirmationModalMessage(message);
    setIsConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  useEffect(() => {
    conversationsActionsStore.onChangeCards();
  }, [conversationsActionsStore]);

  const openModalAddActionCards = (type, icon, title, description, settings) => {
    conversationsActionsStore.setModalAddAction(type, icon, title, description, settings);
    setIsModalAddActionOpen(true);
    closeModalLibrary();
  };


  return (
    <MainContainer>
      <Content>
        {isModalLibraryOpen && <ModalLibrary closeModalLibrary={closeModalLibrary} openModalAddAction={openModalActionLibraryOpen} />}
        {isModalAddActionOpen && <ModalAddAction isOpen={isModalAddActionOpen} onClose={closeModalAddAction}
        />}
        {isConfirmationModalOpen && (
          <ConfirmationModal
            isOpen={isConfirmationModalOpen}
            onClose={closeConfirmationModal}
            onConfirm={closeConfirmationModal}
            title={confirmationModalTitle}
            message={confirmationModalMessage}
            icon="deleteFilled"
          />
        )}

        <MainCard onClick={openModalLibrary}>
          <MainCardTitle>New</MainCardTitle>
          <MainIconContainer><Icon name={"circleAdd"} width={"30px"} height={"30px"} /></MainIconContainer>
        </MainCard>

        {listOfCards.map((card, index) => {
          return (<ActionCards key={index} options={card} openModalAddAction={openModalAddActionCards} openConfirmationModal={openConfirmationModal} />)
        })}
      </Content>
    </MainContainer>
  );
});

export default Actions;
