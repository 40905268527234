import {API, graphqlOperation, Hub} from "aws-amplify";
import {CONNECTION_STATE_CHANGE} from '@aws-amplify/pubsub';
import React, {useEffect, useRef, useState, useLayoutEffect} from "react";
import {observer} from "mobx-react";
import { toJS } from "mobx";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";

import ChatWindowComponent from '../Chatbox/components/ChatWindowComponent';
import ChatQuestionsSection from './components/ChatQuestionsSection';
import ChatInput from '../../elements/input/ChatInput';
import { CustomSelect } from "../../elements";
import Spinner from "../../components/common/Spinner";
import {getAgent, getConversationChatAssistant} from "../../graphql/queries";
import {onChatAssistantResponse} from "../../graphql/subscriptions";
import {useStore} from "../../hooks";
import { CustomizeQButton, HomeChatWrapper, ChatWindowWrapper, ChatInputWrapper, Separator } from "./HomeChatPage.styled";
import ChatHistory from "./components/ChatHistory";
import ChatEditDefaultQuestions from "./components/ChatEditDefaultQuestions";

Hub.listen('api', (data) => {
    const {payload} = data;
    if (payload.event === CONNECTION_STATE_CHANGE) {
        const connectionState = payload.data.connectionState
    }
});

const HomeChatPage = observer(() => {
    const { authStore, changeTitle, viewAgentStore } = useStore();
    const [searchParams] = useSearchParams();
    changeTitle(`Home - ${toJS(authStore?.selectedAccount)?.name}`);
    const [agentIsTyping, setAgentIsTyping] = useState(false);
    const [isChatQuestionsVisible, setIsChatQuestionsVisible] = useState(true);
    const [isChatQuestionEditVisible, setIsChatQuestionEditVisible] = useState(false);
    const [isLoadingConcierge, setIsLoadingConcierge] = useState(false);
    const [shouldCleanThread, setShouldCleanThread] = useState(false);

    const [showSpinner, setShowSpinner] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [agent_params, setAgent_params] = useState({
        message: "",
        campaign_id: "",
        campaign_version: "",
        agent: "",
    });
    const [attachment, setAttachment] = useState();
    const [chatWindowHeight, setChatWindowHeight] = useState(70);

    const [conciergeAgent, setConciergeAgent] = useState("");
    const [isSendMessageClicked, setIsSendMessageClicked] = useState(false);
    const [selectedAgentName, setSelectedAgentName] = useState('');

    const defaultConversationStarters = [
        'What is your main purpose?',
        'How can you assist me?',
        'Tell me about your capabilities.',
        'What specific knowledge do you have available?',
    ]
    const [agentQuestions, setAgentQuestions] = useState(defaultConversationStarters);


    const setConversationStarters = (selectedAgent) => {
        if(selectedAgent?.conversation_starters.length && !selectedAgent?.conversation_starters.every(el => !el)){
            setAgentQuestions(selectedAgent.conversation_starters)
        }else{
            setAgentQuestions(defaultConversationStarters)
        }
    }

    const fetchConciergeAgent = async (customer) => {
        try {
            setIsLoadingConcierge(true);
            const response = await API.graphql({
                query: getAgent,
                variables: {input: {customer_id: customer, domain: "concierge" }},
            });
            const agents = JSON.parse(response.data.getAgent.body).filter(agent => agent.provider === 'openai');
            const conciergeAgent = agents[0];
            setConciergeAgent(conciergeAgent)
            setAgent_params((prevParams) => ({
                ...prevParams,
                agent: conciergeAgent?.id,
                is_concierge: conciergeAgent?.domain === 'concierge'
            }));
            setConversationStarters(conciergeAgent)
        } catch (error) {
            console.error("Error getting Agents", error);
            console.log('Error fetching agents');
        } finally {
            setIsLoadingConcierge(false);
        }
    };

    const handleSelectQuestion = (selectedQuestion) => {
        setNewMessage(selectedQuestion);
        handleSendMessage();
    };

    const handleAgentSelect = (name, agent_id) => {
        const selectedAgent = authStore.agents.find(agent => agent.id === agent_id);
        setSelectedAgentName(name);
        setAgent_params((prevParams) => ({
            ...prevParams,
            agent: selectedAgent?.id,
            is_concierge: selectedAgent?.domain === 'concierge'
        }));
        setConversationStarters(selectedAgent)
    };

    const bottomRef = useRef(null);
    const textAreaRef = useRef();

    useEffect(() => {
        if(authStore.selectedAccount && authStore.selectedAccount.id){
            setSelectedCustomer(authStore.selectedAccount.id)
            authStore.fetchAgents();
        }
    }, [authStore.selectedAccount])

    useEffect(() => {
        if (selectedCustomer) {
            fetchConciergeAgent(selectedCustomer);
        }
    }, [selectedCustomer])

    useLayoutEffect(() => {
        const textAreaWrapper = textAreaRef.current;
        if (textAreaWrapper) {
          const textArea = textAreaWrapper.getElementsByTagName('textarea')[0]
    
          textArea.style.height = "0px";
          const scrollHeight = textArea.scrollHeight;

          setChatWindowHeight(Math.max(58, 74 - (100 * scrollHeight / window.innerHeight)))
          textArea.style.height = scrollHeight + "px";
        }
      }, [newMessage]);

      useEffect(() => {
        const agentIdFromUrl = searchParams.get("agent");
        const selectedAgent = authStore.agents.find(agent => agent.id === agentIdFromUrl);
        const conciergeAgent = authStore.agents.find(agent => agent.domain === 'concierge');
        if (selectedAgent) {
            handleAgentSelect(selectedAgent.name, selectedAgent.id);
        } else if (conciergeAgent) {
            handleAgentSelect(conciergeAgent.name, conciergeAgent.id);
        }
    }, [searchParams, authStore.agents]);

    const handleSendMessage = async () => {
        setIsChatQuestionsVisible(false)
        if (newMessage.trim() === "") return;
        setIsSendMessageClicked(true);

        setNewMessage("");
        const params = {...agent_params, 
            message: newMessage, 
            customer_id: selectedCustomer, 
            attachment: JSON.stringify({}),
            create_new_thread: shouldCleanThread
        };

        if(attachment){
            params['attachment'] = JSON.stringify({file_name: attachment['fileName'], data: attachment['data']})
        }

        try {

            let openAiAnswer = null;

            // Try sending the request a maximum of maxRetries times
            let local_msgs = []
            local_msgs = _.union(messages, [])


            let resultConversationOpenAi = null
            try {
                resultConversationOpenAi = await API.graphql({
                    query: getConversationChatAssistant,
                    variables: {input: params},
                });
                if (!resultConversationOpenAi.errors?.length) {
                    setAgentIsTyping(true);

                    const apiRespnse = resultConversationOpenAi.data.getConversationChatAssistant;

                    const parseResponseBody = (responseBody) => JSON.parse(responseBody);

                    local_msgs = _.union(local_msgs, [{message: newMessage, type: "sent"}])
                    let tmpMsg  = "";
                    let initialMessages = [...local_msgs];
                    let isStreaming = false;
                    setMessages(local_msgs);

                    setAttachment({});
                    // Subscribe to chat assistant response
                    let assistantResponseSub = API.graphql(
                        graphqlOperation(onChatAssistantResponse, {id: apiRespnse.id})
                    ).subscribe({
                        next: ({provider, value}) => {
                            const subApiRespnse = value.data.onChatAssistantResponse;
                            const body = parseResponseBody(
                                subApiRespnse.body
                            )
                            openAiAnswer = body.answer;
                            setShowSpinner(false);
                            setAgentIsTyping(false);

                            if (subApiRespnse.status == 'done') {
                                if(!isStreaming){
                                    const newMessages = [...initialMessages, {message: openAiAnswer, type: "received"}];
                                    setMessages(newMessages)
                                    initialMessages = [...newMessages];
                                }
                                assistantResponseSub.unsubscribe();
                                tmpMsg = '';
                                initialMessages = [...messages];
                                isStreaming = false;
                                setIsSendMessageClicked(false);
                            } else{
                                isStreaming = true;
                                tmpMsg += openAiAnswer
                                setMessages([...initialMessages, {message: tmpMsg, type: "received"}])
                            }
                        },
                        error: (error) => {
                            console.warn(error)
                            setShowSpinner(false);
                            setAgentIsTyping(false);
                            local_msgs = _.union(local_msgs, [{
                                    message:
                                        "Oops! Something went wrong. Please try again.",
                                    type: "received",
                                }])
                            setMessages(local_msgs);
                            // scrollToBottom();
                        }
                    });

                }
                setShouldCleanThread(false);
            } catch (err) {
                console.log(err)
                setMessages([
                    ...messages,
                    {
                        message:
                            "Oops! Something went wrong. Please try again.",
                        type: "received",
                    },
                ]);
                // scrollToBottom();
            }
        } catch (error) {
            console.error(error);

            if (
                error.errors &&
                error.errors[0].errorType === "Lambda:ExecutionTimeoutException"
            ) {
                setMessages([
                    ...messages,
                    {
                        message:
                            "Sorry, I'm taking longer than expected to get your answer. I'll notify my team to teach me more about this topic. main while, lets continue with other questions you have?",
                        type: "received",
                    },
                ]);
            }
        }
    };

    const handleCleanThread = () => {
        if (!isChatQuestionEditVisible) {
            setMessages([]);
            setIsChatQuestionsVisible(true);
            setAgentIsTyping(false);
            setShouldCleanThread(true);
        }
    }

    useEffect(() => {
        if(messages.length){
            scrollToBottom();
        }
    }, [messages]);

    const scrollToBottom = () => {
        bottomRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
    };

    return (
        <>
        <HomeChatWrapper>
            
            <CustomSelect
                selectedDefault={selectedAgentName}
                placeholder="Select Agent"
                width='20vw'
                margin='15px 0 15px 0'
                options={authStore.agents.map(a => ({ name: a.name, value: a.id }))}
                handleSelect={handleAgentSelect} />

            {isChatQuestionsVisible && (
                <ChatQuestionsSection onSelectQuestion={handleSelectQuestion} isLoading={!conciergeAgent} loadedQuestions={agentQuestions}/>
            )}

            {isChatQuestionEditVisible && (
                <ChatEditDefaultQuestions goBack={() => {setIsChatQuestionEditVisible(false); setIsChatQuestionsVisible(true)}}/>
            )}
            
            <ChatWindowWrapper>
                {!isChatQuestionsVisible && !isChatQuestionEditVisible && (
                    <ChatWindowComponent
                        selectedAgent={conciergeAgent}
                        showSpinner={showSpinner}
                        messages={messages}
                        bottomRef={bottomRef}
                        height={`${chatWindowHeight}vh`}
                        agentIsTyping={agentIsTyping}
                    />
                    )}
            </ChatWindowWrapper>
                
            {!isChatQuestionEditVisible && <ChatInputWrapper>
                {conciergeAgent ? (
                   <>
                        <Separator>
                            {showSpinner && <Spinner className="spinner"/>}
                        </Separator>
                        <ChatInput
                            textAreaRef={textAreaRef}
                            onChange={(e) => setNewMessage(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey && !showSpinner) {
                                    e.preventDefault();
                                    handleSendMessage();
                                }
                            }}
                            showSpinner={showSpinner}
                            newMessage={newMessage}
                            handleSendMessage={handleSendMessage}
                            onFileUploaded={setAttachment}
                            disableSend={isSendMessageClicked}
                            onCleanThread={handleCleanThread}
                        />
                        <CustomizeQButton onClick={() => {setIsChatQuestionsVisible(false); setIsChatQuestionEditVisible(!isChatQuestionEditVisible)}}>Customize <br/> default questions</CustomizeQButton>
                    </> 
                ):
                    <p>Concierge agent is not created yet.</p>
                }
            </ChatInputWrapper>}
        </HomeChatWrapper>
        {!isChatQuestionEditVisible && <ChatHistory/>}
        </>
    );
});

export default HomeChatPage;
