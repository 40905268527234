import React, {useState} from 'react'
import {
    ModalAccessHeaderTitle,
    ModalIconsAccess,
    ModalDetailsContainer,
    GroupedIcons,
    ModalAccessHeader,
    ModalTabsContainer,
    ModalAccessTabs,
    TabPanelBox,
    SharedMessage,
    TabPanelGridContainer,
    TabPanelGridItemNames,
    TabPanelGridItemNamesContainer,
    TabPanelGridItemRoles,
    IconsContainer,
    ModalAccessContainer,
    ModalAccessTab,
    Icons,
    ShowDetailsContainer
} from './AccessTabs.styled'
import {Typography} from "@mui/material";
import { stringToColor } from "../../../../../utils/uiUtils";


const AccessTabs = ({access, tabsNames, mainTitle}) =>{
    const tabContent = tabsNames && tabsNames.map(title => {
        const names = access
            .filter(user => user.role === title)
            .map(user => user.name);

        return {
            role: title,
            names: names
        };
    });

    const [showDetails, setShowDetails] = useState(false);
    const [value, setValue] = useState(0);


    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <TabPanelBox
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`tab-${index}`}
                {...other}
            >
                {value === index && (
                    <TabPanelBox>
                        <Typography>{children}</Typography>
                    </TabPanelBox>
                )}
            </TabPanelBox>
        );
    }

    return (
        <>
            <ModalAccessHeaderTitle>{mainTitle}</ModalAccessHeaderTitle>
            <ModalAccessHeader>
                <ModalIconsAccess>
                    <IconsContainer>
                        {access && access.map((person, index) => (
                            <GroupedIcons key={index} color={stringToColor(person.name)} index={index}>
                                {person.name.charAt(0)}
                            </GroupedIcons>
                        ))}
                    </IconsContainer>
                </ModalIconsAccess>
                <ModalDetailsContainer>
                    <ShowDetailsContainer onClick={toggleDetails}>
                        {showDetails ? 'Hide details' : 'More details'}
                    </ShowDetailsContainer>
                </ModalDetailsContainer>
            </ModalAccessHeader>
            {showDetails &&
                <ModalAccessContainer>
                    <ModalTabsContainer>
                        <SharedMessage>
                            Shared with {access ? access.length : 0} users
                        </SharedMessage>

                        <ModalAccessTabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="access tabs"
                        >

                            <ModalAccessTab label="All" />
                            {tabsNames.map((title) => (
                                <ModalAccessTab label={title} key={title} />
                            ))}
                        </ModalAccessTabs>
                    </ModalTabsContainer>

                    <TabPanel value={value} index={0}>
                        {access && access.map((person, index) => (
                            <TabPanelGridContainer>
                                <TabPanelGridItemNames>
                                    <TabPanelGridItemNamesContainer>
                                        <Icons key={index} color={stringToColor(person.name)} index={index}>
                                            {person.name.charAt(0)}
                                        </Icons> {person.name}
                                    </TabPanelGridItemNamesContainer>
                                </TabPanelGridItemNames>
                                <TabPanelGridItemRoles>
                                    {person.role}
                                </TabPanelGridItemRoles>
                            </TabPanelGridContainer>
                        ))}
                    </TabPanel>

                    {tabContent && tabContent.map((item, index) => {
                        return (
                            <TabPanel value={value} index={index+1}>
                                {item.names.map((person, index) => (
                                    <TabPanelGridContainer>
                                        <TabPanelGridItemNames>
                                            <TabPanelGridItemNamesContainer>
                                                <Icons key={index} color={stringToColor(person)} index={index}>
                                                    {person.charAt(0)}
                                                </Icons> {person}
                                            </TabPanelGridItemNamesContainer>
                                        </TabPanelGridItemNames>
                                        <TabPanelGridItemRoles>
                                            {item.role}
                                        </TabPanelGridItemRoles>
                                    </TabPanelGridContainer>
                                )
                            )}
                        </TabPanel>
                        )
                    })}

                </ModalAccessContainer>
            }

        </>
    )
}

export default AccessTabs
