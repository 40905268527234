import { action, makeObservable, observable, flow } from "mobx";
import { API } from "aws-amplify";
import { getCustomers, getUploadUrlFile, putCampaigns, getDefaultProjects, getContactFiles } from "../../graphql/queries";
import axios from "axios";

class NewCampaignStore {
    account = '';
    id = "";
    moduleSelect = '';
    campaignName = '';
    campaignType = '';
    message1 = '';
    startDate = null;
    endDate = null;
    messages = [];
    fileNames = [];
    columnNames = [];
    moduleList = [];
    typeList = [];
    fileList = [];
    file = '';
    moduleName = '';
    moduleType = '';
    templates = '';
    campaignTypeName = '';
    fileName = '';
    urlFile = '';
    isLoading = false;
    isError = false;
    isSuccessfullModalOpen = false;
    selectedOptions = {};
    selectedFiles = [];
    isUploading = false;
    columnContents = {};
    areAllFieldsDone = false;
    buttonUpload = false;
    showCustomSelectFirstName = false;
    showCustomSelectLastName = false;
    showCustomSelectEmail = false;
    showCustomSelectPhone = false;
    showCustomSelectZipCode = false;
    fileListLoaded = false;
    hideUploadFiles = false;
    showFirstModal = false;
    errorMessage = '';

    constructor() {
        makeObservable(this, {
            selectedFiles: observable,
            isUploading: observable,
            setIsUploading: action,
            account: observable,
            id: observable,
            moduleSelect: observable,
            campaignName: observable,
            campaignType: observable,
            message1: observable,
            startDate: observable,
            endDate: observable,
            messages: observable,
            file: observable,
            moduleList: observable,
            typeList: observable,
            fileList: observable,
            isLoading: observable,
            isError: observable,
            fileNames: observable,
            columnNames: observable,
            selectedOptions: observable,
            columnContents: observable,
            areAllFieldsDone: observable,
            buttonUpload: observable,
            moduleName: observable,
            moduleType: observable,
            templates: observable,
            fileName: observable,
            urlFile: observable,
            campaignTypeName: observable,
            showCustomSelectFirstName: observable,
            showCustomSelectLastName: observable,
            showCustomSelectEmail: observable,
            showCustomSelectPhone: observable,
            showCustomSelectZipCode: observable,
            errorMessage: observable,
            hideUploadFiles: observable,
            showFirstModal: observable,
            setShowCustomSelectFirstName: action,
            setShowCustomSelectLastName: action,
            setShowCustomSelectEmail: action,
            setShowCustomSelectPhone: action,
            setShowCustomSelectZipCode: action,
            onChangeMessage: action,
            setAreAllFieldsDone: action,
            setColumnNames: action,
            setColumnContents: action,
            getFileNamesFromPaths: action,
            handleFieldSelect: action,
            onChangeModule: action,
            onChangeType: action,
            onChangeName: action,
            onChangeAccount: action,
            onCompleteCreation: flow,
            setIsSuccessfulModalOpen: action,
            setUploadFile: action,
            setStartDate: action,
            setEndDate: action,
            setError: action,
            onClearNewCampaign: action,
            uploadFileContact: flow,
            getModules: flow,
            getContactFiles: flow,
            putCampaings: flow,
            fileListLoaded: observable,
            setIsFileListLoaded: action,
            onChangeFile: action,
            hideUploadFilesStep: action,
            onClearError: action,
            isSuccessfullModalOpen: observable
        });
    }

    onChangeModule = (name, value, type) => {
        this.typeList = [];
        this.moduleSelect = value.trim();
        this.moduleName = name;
        this.moduleType = type;
        this.campaignTypeName = "";
        this.campaignType = "";
        this.templates = "";
        this.campaignName= "";
    }

    onChangeType = (name, value, templates) => {
        this.campaignType = value.trim();
        this.campaignTypeName = name;
        this.templates = templates;
        
    }

    onChangeName = (value) => {
        this.campaignName = value;
    }

    onChangeFirstModal = (value) => {
        this.showFirstModal = value;
    }

    onChangeAccount = (value) => {
        this.account = value;
        this.moduleList = [];
        this.moduleName = '';
        this.moduleSelect = '';
        this.fileName = '';
        this.urlFile = '';
    }

    setIsSuccessfulModalOpen(value) {
        this.isSuccessfullModalOpen = value;
    };

    onValidateTextField = (field) => {
        return this[field].length > 0;
    }

    onValidateObjectField = (field) => {
        return Object.keys(this[field]).length;
    }

    *onCompleteCreation(succesCallback){
        this.isError = false;
        this.isLoading = true;
        try {
            yield this.uploadFileContact(succesCallback);
        }catch(error){
            this.isError = true;
        }finally{
            this.isLoading = false;
        }
    }

    setError(errorMessage) {
        this.errorMessage = errorMessage;
    }

    onClearNewCampaign() {
        this.isLoading = false;
        this.isError = false;
        this.campaignName = '';
        this.campaignType = '';
        this.account = '';
        this.id = "";
        this.moduleName = '';
        this.moduleType = '';
        this.campaignTypeName = '';
        this.setColumnNames([]);
        this.setColumnContents([]);
        this.setIsUploading(false);
        this.buttonUpload = false;
        this.setShowCustomSelectFirstName(false);
        this.setShowCustomSelectLastName(false);
        this.setShowCustomSelectEmail(false);
        this.setShowCustomSelectPhone(false);
        this.setShowCustomSelectZipCode(false);
        this.setAreAllFieldsDone(false);
        this.setSelectedFiles([]);
        this.showFirstModal = false;
        this.moduleList = [];
        this.fileList = [];
    }

    getFileNamesFromPaths(filePaths) {
        this.fileNames = filePaths.map((filePath) => {
            const pathParts = filePath.split('/');
            const fileName = pathParts[pathParts.length - 1];
            return { name: fileName, value: filePath };
        });
    }

    setColumnNames(names) {
        this.columnNames = names;
    }

    handleFieldSelect = (option, fieldName) => {
        this.selectedOptions[fieldName] = option.value;
    }

    setSelectedFiles(files) {
        this.selectedFiles = files;
    }

    setIsUploading(value) {
        this.isUploading = value;
    }

    setColumnContents(contents) {
        this.columnContents = contents;
    }

    setAreAllFieldsDone(value) {
        this.areAllFieldsDone = value;
    }

    onChangeMessage = (emailObject) => {
        this.messages = emailObject;
    }

    setStartDate(date) {
        this.startDate = date;
    }

    setEndDate(date) {
        this.endDate = date;
    }

    setUploadFile(value) {
        this.buttonUpload = value;
    }

    setShowCustomSelectFirstName(value) {
        this.showCustomSelectFirstName = value;
    }

    setShowCustomSelectLastName(value) {
        this.showCustomSelectLastName = value;
    }

    setShowCustomSelectEmail(value) {
        this.showCustomSelectEmail = value;
    }

    setShowCustomSelectPhone(value) {
        this.showCustomSelectPhone = value;
    }
    setShowCustomSelectZipCode(value) {
        this.showCustomSelectZipCode = value;
    }

    setIsFileListLoaded(value) {
        this.fileListLoaded = value;
    }

    onChangeFile(name, value) {
        this.fileName = name;
        this.urlFile = value;
        this.handleFileDelete();
    }

    hideUploadFilesStep(value) {
        this.hideUploadFiles = value;
    };

    onClearError = () => {
        this.isError = false;
    }

    *getModules() {
        this.isLoading = true;
        this.isError = false;
        try {
            const moduleList = [];
            const response = yield API.graphql({
                query: getDefaultProjects,
                variables: {
                    input: { customer: this.account },
                },
            });

            const data = JSON.parse(response.data?.getDefaultProjects?.body);
            data.projects.forEach(project => {
                const { id, module, types } = project;
                moduleList.push({ name: module, value: id, type: types });
                this.moduleList = moduleList;
            });
        } catch (error) {
            this.isError = true;
        } finally {
            this.isLoading = false;
        } 
    }

    getTypes() {
        this.isLoading = true;
        this.isError = false;
        try {
            const typesWithNamesValuesAndTemplates = this.moduleType.map(type => {
                const name = type.name;
                const value = type.id;
                const templates = Array.isArray(type.data) ? type.data.map(item => item.html) : [type.data.html];
                return { name, value, templates };
            });
            this.typeList = typesWithNamesValuesAndTemplates;
        } catch (error) {
            this.isError = true;
        } finally {
            this.isLoading = false;
        }
    }

    removeSpecialCharacters = (string) => {
        const withoutSpaces = string.replace(/\s/g, '_');
        const withoutSpecialChars = withoutSpaces.replace(/[^.\w\s]/gi, '');
        return withoutSpecialChars;
    };

    *uploadFileContact(succesCallback) {
        this.isLoading = true;
        try {
            for (const file of this.selectedFiles) {
                this.fileName = this.removeSpecialCharacters(file.name);
                const response = yield API.graphql({
                    query: getUploadUrlFile,
                    variables: {
                        input: {
                            customer: this.account,
                            file_name: this.fileName,
                            file_type: file.type,
                            file_route: "contact_list",
                        },
                    },
                });
    
                const uploadUrl = JSON.parse(response.data.getUploadUrlFile?.body);
                this.urlFile = uploadUrl;
                const formData = new FormData();
                formData.append("file", file);
    
                const config = {
                    headers: {
                        "Content-Type": file.type,
                    },
                };
                yield axios.put(uploadUrl, file, config);
            }
        } catch (err) {
            this.isLoading = false;
            this.isError = true;
            return;
        }
        try {
            yield this.putCampaings(succesCallback);
        } catch (err) {
            this.isLoading = false;
            this.isError = true;
        }
    }
    
    *putCampaings(succesCallback) {
        let file_data;
        this.isLoading = true;
        try {
            yield this.getContactFiles();
            file_data = this.fileList.find(item => item.name === this.fileName);
        } catch (err) {
            this.isLoading = false;
            this.isError = true;
            return;
        }
        try {
            this.isLoading = true;
            const firstMessageObject = {
                EMAIL: this.messages
            };
    
            const contactFileObject = {
                url: file_data.s3url,
                name: this.fileName
            };
    
            const externalProviderObject = {
                name: "pinpoint",
                data: {
                    project_id: this.moduleSelect,
                    flow_id: this.campaignType
                }
            };
    
            const response = yield API.graphql({
                query: putCampaigns,
                variables: {
                    input: {
                        id: "", // empty on create
                        customer: this.account,
                        name: this.campaignName,
                        start_datetime: this.startDate,
                        end_datetime: this.endDate,
                        protected_intents: JSON.stringify({}),
                        market: "",
                        status: "DRAFT",
                        channels: JSON.stringify({}),
                        metadata: JSON.stringify({}),
                        first_message: JSON.stringify(firstMessageObject),
                        contact_file: JSON.stringify(contactFileObject),
                        external_provider: JSON.stringify(externalProviderObject)
                    }
                },
            });
    
            if (response.errors && response.errors.length > 0 || response.data.putCampaigns.statusCode !== "200") {
                throw new Error(response.errors.map(error => error.message).join(", "));
            }
            this.showFirstModal = true;
            this.isError = false;
            this.isLoading = false;
        } catch (error) {
            this.isLoading = false;
            this.isError = true;
        }
    }
    

    *getContactFiles() {
        this.isLoading = true;
        this.isError = false;
        try {
         
            const response = yield API.graphql({
                query: getContactFiles,
                variables: {
                    input: { customer: this.account },
                },
            });

            const data = JSON.parse(response.data?.getContactFiles?.body);

            this.fileList = data;

        } catch (error) {
            this.isError = true;
            
        } finally {
            this.isLoading = false;
        }
    }

    handleFileDelete(){
        this.setSelectedFiles([]);
        this.setColumnNames([]);
        this.setColumnContents([]);
        this.setShowCustomSelectFirstName(false);
        this.setShowCustomSelectLastName(false);
        this.setShowCustomSelectEmail(false);
        this.setShowCustomSelectPhone(false);
        this.setShowCustomSelectZipCode(false);
        this.setAreAllFieldsDone(false);
    };
}

export default NewCampaignStore;
