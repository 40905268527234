import { API } from "aws-amplify";
import { action, flow, makeObservable, observable, toJS } from "mobx";
import {
    deleteAgentFile,
    getAgent,
    putAgent,
    getUploadUrlFile
} from "../../../graphql/queries";
import axios from "axios";

class EditAgentStore {
    name = "";
    title = "";
    persona = "";
    tones = [];
    answerExtension = "";
    instructions = "";
    account = "";
    agents = [];
    files = [];
    channels = [];
    rawFiles = [];
    addConcierge = true;
    usePrompter = false;
    type = "";
    typeName = "";
    id = "";
    createdDate = "";
    updatedDate = "";
    isDuplicating = false;
    isConcierge = false;
    isLoading = false;
    isError = false;
    showOverlay = false;
    deleteFile = "";
    updatedAgentSuccessfully = false;
    finishedInitialLoad = false;
    actions = [];
    conversationStarters = {
        "cs1": "",
        "cs2": "",
        "cs3": "",
        "cs4": ""
    };
    domain = '';
    agentPrompter = '';
    agentConcierge = '';
    uploadProgress = 0;
    filesWithStatus = [];

    constructor() {
        makeObservable(this, {
            name: observable,
            title: observable,
            persona: observable,
            tones: observable,
            conversationStarters: observable,
            answerExtension: observable,
            instructions: observable,
            account: observable,
            files: observable,
            rawFiles: observable,
            type: observable,
            typeName: observable,
            id: observable,
            createdDate: observable,
            updatedDate: observable,
            channels: observable,
            agents: observable,
            addConcierge: observable,
            usePrompter: observable,
            isConcierge: observable,
            agentPrompter: observable,
            agentConcierge: observable,
            isLoading: observable,
            isError: observable,
            deleteFile: observable,
            domain: observable,
            actions: observable,
            updatedAgentSuccessfully: observable,
            finishedInitialLoad: observable,
            uploadProgress: observable,
            filesWithStatus: observable,
            onChangeName: action,
            onChangeTitle: action,
            onChangeTones: action,
            onChangeAnswerExtension: action,
            onChangeInstructions: action,
            onChangeAccount: action,
            onChangeFiles: action,
            onChangeChannels: action,
            onChangeType: action,
            onChangeTypeName: action,
            onClearError: action,
            onSetError: action,
            clear: action,
            displayDeleteFileDialog: action,
            getAgentByID: action,
            updateConversationStarter: action,
            newConversationStarter: action,
            removeConversationStarter: action,
            getAgents: flow,
            updateAgent: flow,
            confirmRemoveFile: flow,
            getAgentPrompter: flow,
            getAgentConcierge: flow,
            uploadFile: flow,
        });
    }

    onChangeName = (value) => {
        this.name = value;
    };

    onChangeTitle = (value) => {
        this.title = value;
    };

    onChangeTones = (values) => {
        if (values.length) this.tones = values;
    };

    onChangeChannels = (values) => {
        if (values.length) this.channels = values;
    };

    onChangeActions = (value) => {
        this.actions = value;
    };

    onChangeAnswerExtension = (value) => {
        this.answerExtension = value;
    };

    onChangeInstructions = (value) => {
        this.instructions = value;
    };

    onChangeAccount = (value) => {
        this.account = value;
    };

    onChangeType = (value) => {
        this.type = value;
    };

    onChangeTypeName = (value) => {
        this.typeName = value;
    };

    onChangeFiles = (value) => {
        if (typeof value === 'string') {
            this.rawFiles = [...this.rawFiles, value];
            this.files = [
                ...this.files,
                value.replace("s3://", "").split("/").splice(-1).pop(),
            ];
        } else {
            this.isError = true;
        }
    };

    onValidateTextField = (field) => {
        return this[field].length;
    };

    onValidateObjectField = (field) => {
        return Object.keys(this[field]).length;
    };

    onChangeIncludeConcierge = (value) => {
        this.addConcierge = value === "yes";
    };

    onChangeUsePrompter = (value) => {
        this.usePrompter = value === "yes";
    };

    onClearError = () => {
        this.isError = false;
    };

    onSetError = () => {
        this.isError = true;
    };

    displayDeleteFileDialog = (value) => {
        this.deleteFile = value;
    };

    updateConversationStarter(id, value){
        this.conversationStarters[id] = value;
    }

    newConversationStarter(value){
        this.conversationStarters[`cs-new-${Math.random()}`] = value;
    }

    removeConversationStarter(idx){
        const objIdx = Object.keys(this.conversationStarters)[idx]
        delete this.conversationStarters[objIdx]
    }

    clear() {
        this.name = "";
        this.title = "";
        this.persona = "";
        this.tones = [];
        this.answerExtension = "";
        this.instructions = "";
        this.account = "";
        this.files = [];
        this.id = "";
        this.rawFiles = [];
        this.channels = [];
        this.isLoading = false;
        this.isError = false;
        this.updatedAgentSuccessfully = false;
        this.deleteFile = "";
        this.updatedDate = "";
        this.createdDate = "";
        this.finishedInitialLoad = false;
        this.addConcierge = true;
        this.usePrompter = false;
        this.actions = [];
        this.conversationStarters = {
            "cs1": "",
            "cs2": "",
            "cs3": "",
            "cs4": ""
        }
    }

    getAgentByID(agent_id) {
        let agentSelected = toJS(this.agents)[0];
        if (agent_id)
            agentSelected = toJS(this.agents)
                .filter((a) => a.id.includes(agent_id))
                .pop();
        const {
            name,
            human_instructions,
            insert_date,
            last_modified,
            knowledge_base,
            channels,
            id,
            domain,
            job_title,
            persona,
            tone,
            extended_answer,
            scope,
            actions,
            conversation_starters
        } = agentSelected;

        this.name = name;
        this.instructions = human_instructions;
        this.createdDate = insert_date;
        this.updatedDate = last_modified;
        this.rawFiles = knowledge_base;
        this.files = knowledge_base.map((filePath) =>
            filePath.replace("s3://", "").split("/").splice(-1).pop()
        );
        this.channels = channels.map((channel) => channel.toLowerCase());
        this.id = id;
        this.isConcierge = domain === "concierge";
        this.title = job_title;
        this.persona = "name" in persona ? persona["name"] === "" ? "None" : persona["name"] : "";
        this.tones = tone && Array.isArray(tone) ? tone : [];
        this.answerExtension = extended_answer ? "extensive" : "summarized";
        this.type = scope;
        this.actions = actions;
        this.domain = domain;
        if (conversation_starters.length) {
            this.conversationStarters = conversation_starters.reduce((acc, value, idx) => ({ ...acc, [`cs${idx + 1}`]: value }), {});
        } else {
            this.conversationStarters = {
                "cs1": "",
                "cs2": "",
                "cs3": "",
                "cs4": ""
            };
        }
    }

    *getAgents(customer_id, agent_id) {
        try {
            this.isLoading = true;
            this.isError = false;

            const response = yield API.graphql({
                query: getAgent,
                variables: { input: { customer_id } },
            });
            const agents = JSON.parse(response.data?.getAgent?.body);
            this.agents = agents.filter((agent) => agent.provider === "openai");

            this.getAgentByID(agent_id);
            this.finishedInitialLoad = true;
        } catch (error) {
            this.isError = true;
        } finally {
            this.isLoading = false;
        }
    }

    *getAgentPrompter(customer_id) {
        try {
            this.isLoading = true;
            this.isError = false;

            const response = yield API.graphql({
                query: getAgent,
                variables: { input: { customer_id, domain: 'prompter' } },
            });
            const agents = JSON.parse(response.data?.getAgent?.body);
            this.agentPrompter = agents[0]?.id;
        } catch (error) {
            this.isError = true;
        } finally {
            this.isLoading = false;
        }
    }

    *updateAgent() {
        if (!this.name) return;
        this.updatedAgentSuccessfully = false;
        try {
            this.showOverlay = true;
            this.isLoading = true;
            this.isError = false;
            let input = {
                channels: toJS(this.channels),
                customer_id: this.account,
                human_instructions: this.instructions,
                knowledge_base: toJS(this.rawFiles),
                name: this.name,
                prompt: this.instructions,
                job_title: this.title,
                persona: JSON.stringify({ name: '' }),
                tone: toJS(this.tones),
                extended_answer:
                    this.answerExtension.toLowerCase() === "extensive",
                scope: this.type,
                actions: this.actions,
                add_to_concierge: this.addConcierge,
                use_prompter: this.usePrompter,
                conversation_starters: Object.values(this.conversationStarters),
                domain: this.domain
            };

            if (this.isDuplicating) {
                input.id = "";
            } else {
                input.id = this.id;
            }

            yield API.graphql({
                query: putAgent,
                variables: { input },
            });
            yield this.getAgents(this.account, this.id);
            this.updatedAgentSuccessfully = true;
        } catch (error) {
            if (
                error.errors.length &&
                error.errors[0].errorType === "Lambda:ExecutionTimeoutException"
            ) {
                //Wait 10secs in case this endpoint time outs (it times out because of Appsync's 30 seconds limit)
                yield new Promise((resolve) => setTimeout(resolve, 10000));
                yield this.getAgents(this.account, this.id);
                this.updatedAgentSuccessfully = true;
            } else {
                this.isError = true;
            }
        } finally {
            this.isDuplicating = false;
            this.isLoading = false;
            this.showOverlay = false;
        }
    }

    *confirmRemoveFile() {
        this.updatedAgentSuccessfully = false;
        try {
            const filename = this.deleteFile;
            this.isLoading = true;
            this.isError = false;
            const filepath = toJS(this.rawFiles)
                .filter((file) => file.includes(filename))
                .pop();
            this.rawFiles = this.rawFiles.filter(
                (file) => !file.includes(filename)
            );
            this.files = this.files.filter((file) => !file.includes(filename));

            this.filesWithStatus = this.filesWithStatus.filter(
                (file) => file.document !== filename
            );

            const input = {
                channels: toJS(this.channels),
                customer_id: this.account,
                human_instructions: this.instructions,
                knowledge_base: toJS(this.rawFiles),
                name: this.name,
                prompt: this.instructions,
                id: this.id,
                job_title: this.title,
                persona: JSON.stringify({name: ''}),
                tone: toJS(this.tones),
                extended_answer:
                    this.answerExtension.toLowerCase() === "extensive",
                scope: this.type,
                actions: this.actions,
                add_to_concierge: this.addConcierge,
                use_prompter: this.usePrompter,
                conversation_starters: Object.values(this.conversationStarters),
                domain: this.domain
            };

            const deleteFileData = {
                customer: this.account,
                assistant_id: this.id,
                s3_file_path: filepath,
            };

            this.deleteFile = "";

            if (!this.isDuplicating) {
                yield API.graphql({
                    query: putAgent,
                    variables: { input },
                });

                yield API.graphql({
                    query: deleteAgentFile,
                    variables: {
                        input: deleteFileData,
                    },
                });
                this.updatedAgentSuccessfully = true;
            }
        } catch (error) {
            this.isError = true;
        } finally {
            this.deleteFile = "";
            this.isLoading = false;
        }
    }

    *getAgentConcierge(customer_id) {
        try {
            this.isLoading = true;
            this.isError = false;

            const response = yield API.graphql({
                query: getAgent,
                variables: { input: { customer_id, domain: 'concierge' } },
            });
            const agents = JSON.parse(response.data?.getAgent?.body);
            this.agentConcierge = agents[0]?.id;
        } catch (error) {
            this.isError = true;
        } finally {
            this.isLoading = false;
        }
    }

    *uploadFile(filesToUpload, currentUploadedFilesLength, totalFilesThreshold, customer, fileRoute, onFileUpload, onError) {
        if (!Array.isArray(filesToUpload)) {
            filesToUpload = [filesToUpload];
        }

        const filesDifference = (filesToUpload.length + currentUploadedFilesLength) - totalFilesThreshold;
        if (filesToUpload.length && currentUploadedFilesLength && filesDifference > 0) {
            this.isError = true;
            return;
        }

        for (const file of filesToUpload) {
            // Check the 512mb limit
            if (file?.size / 1e+6 > 512) {
                this.isError = true;
                return;
            }
        }

        try {
            this.uploadProgress = 0;
            this.isLoading = true;
            this.updatedAgentSuccessfully = false;

            for (const file of filesToUpload) {
                const fileName = this.removeSpecialCharacters(file.name);

                const response = yield API.graphql({
                    query: getUploadUrlFile,
                    variables: {
                        input: {
                            customer: customer,
                            file_name: fileName,
                            file_type: file.type,
                            file_route: fileRoute,
                        },
                    },
                });

                const uploadUrl = JSON.parse(response.data.getUploadUrlFile?.body);
                const s3Url = uploadUrl.split('?')[0].replace("https://", "s3://").replace(".s3.amazonaws.com", "");
                const formData = new FormData();
                formData.append("file", file);

                const config = {
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        this.uploadProgress = percentCompleted;
                    },
                    headers: {
                        "Content-Type": file.type,
                    },
                };

                yield axios.put(uploadUrl, file, config);

                if (onFileUpload) {
                    onFileUpload({
                        uploadUrl: s3Url,
                        fileName: fileName,
                    });
                }
            }

            yield this.updateAgent();

        } catch (error) {
            this.isError = true;
            if (onError) {
                onError();
            }
        } finally {
            this.isLoading = false;
        }
    }

    removeSpecialCharacters(string) {
        const withoutSpaces = string.replace(/\s/g, '_');
        const withoutSpecialChars = withoutSpaces.replace(/[^.\w\s]/gi, '');
        return withoutSpecialChars;
    };
}

export default EditAgentStore;
