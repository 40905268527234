import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useSearchParams, useNavigate } from "react-router-dom";
import {useStore} from "../../hooks";
import UserInfo from "./components/UserInfo/UserInfo";
import GarageCard from './components/GarageCard/GarageCard';

import ScheduledAppointments from "./components/ScheduledAppointments/ScheduledAppointments"
import UserOrder from './components/UserOrder/UserOrder';
import DetailedPartsInformation from "./components/DetailedPartsInformation/DetailedPartsInformation"
import { PageContainer, PageTile } from './UnifiedProfilePage.styles';
import {Assistance} from "./components/Assistance";
import UserNotifications from './components/UserNotifications/UserNotifications';
import { Loading } from "../../components/loading";
import {SuccessfulModal} from "../../elements";
import AdmirationSign from '../../elements/icon/assets/admiration_sign.svg';

const UnifiedProfilePage = observer(() => {
    const { changeTitle, authStore } = useStore();
    const { unifiedCustomerProfileStore } = useStore();
    changeTitle('Unified Customer Profile');
    const { selectedAccount } = authStore;

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        unifiedCustomerProfileStore.onClearUCP();
        const segmentId = searchParams.get('segment');
    
        if(!segmentId) {
          navigate('/contacts')
        }
        
        unifiedCustomerProfileStore.getUCP(selectedAccount.id, segmentId);
    }, []);

    return (
        <>
            {!unifiedCustomerProfileStore.isLoading ? (
                <PageContainer>
                <PageTile>
                    <UserInfo/>
                    <ScheduledAppointments/>
                    <UserNotifications/>
                </PageTile>
                <PageTile>
                    <Assistance />
                </PageTile>
                <PageTile>
                    <GarageCard/>
                    <UserOrder />
                    <DetailedPartsInformation/>
                </PageTile>
            </PageContainer>
            ) : <Loading/>}

        <SuccessfulModal
            isOpen={unifiedCustomerProfileStore.isError}
            onClose={() => navigate('/contacts')}
            imageSrc={AdmirationSign}
            title="Oops!"
            subtitle="An error occurred, please try again later."
            height='300px'
            width='500px'
            zIndex={true}
        />
        </>

    );
});

export default UnifiedProfilePage;

