import styled from "styled-components";
import { Box } from '@mui/material';

const Header = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

const Title = styled.p`
    display: inline-block;
    font-size: ${props => props.theme.fonts.size.s1};
    font-weight: 600;
    margin: 0;
`;

const AddButton = styled.span`
    cursor: pointer;
    border: 2px solid ${props => props.theme.colors.clearGray};
    border-radius: 8px;
    gap: 10px;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const IconContainer = styled(Box)`
    display: flex;
    gap: 8px;
`;

export {
    Header,
    Title,
    AddButton,
    IconContainer
}