import UserList from "./UserList";
import NewUserPage from "./NewUserPage/NewUserPage";

import React from "react";
import { Route, Routes } from "react-router-dom";

const UserPage = () => {
  return (
    <Routes>
      <Route index element={<UserList />} />
      <Route path="/new" element={<NewUserPage/>} />
    </Routes>
  );
};

export default UserPage;
