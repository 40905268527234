import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Circle, Line, SettingsItem } from './SettingsItems.Styled';

const SettingsItems = () => {
    return (
        <Container>
            <Link to="/profile/">
                <SettingsItem left={14} top={0}>Profile</SettingsItem>
            </Link>
            <Link>
                <SettingsItem left={14} top={56}>Account</SettingsItem>
            </Link>
            <Link>
                <SettingsItem left={13} top={28}>Customization</SettingsItem>

            </Link>
            <Link>
                <SettingsItem left={13} top={83}>Billing</SettingsItem>
            </Link>
            <Link to="/profile/">
                <Circle left={1} top={13} />
            </Link>
            <Link>
                <Circle left={1} top={41} />
            </Link>
            <Link>
                <Circle left={1} top={70} />
            </Link>
            <Link>
                <Circle left={1} top={96} />
            </Link>
            <Line style={{ left: '4px', top: '16px' }} />

        </Container>
    );
};

export default SettingsItems;
