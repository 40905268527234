import {observer} from "mobx-react";
import ChatBox from "./ChatBox";
import RichInput from "./RichInput";
import { AssistantWrapper } from "./Assistance.styled";
import {useStore} from "../../../../hooks";
import {useCallback, useEffect, useState} from "react";
import {API, graphqlOperation} from "aws-amplify";
import {getAgent, getConversationChatAssistant} from "../../../../graphql/queries";
import _ from "lodash";
import {onChatAssistantResponse} from "../../../../graphql/subscriptions";
import Spinner from "../../../../components/common/Spinner";

const Assistance = observer(() => {
  const { authStore, unifiedCustomerProfileStore, annUnifiedCustomerProfileStore } = useStore();
  const [agentIsTyping, setAgentIsTyping] = useState(false);
  const { customerId, isSuperAdmin, selectedAccount } = authStore;
  const [newMessage, setNewMessage] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [isLoadingConcierge, setIsLoadingConcierge] = useState(false);
  const initialMessage = { message: "What can I assist you with today?", type: "received" };
  const [messages, setMessages] = useState([initialMessage]);
  const [conciergeAgent, setConciergeAgent] = useState();
  const [agentParams, setAgentParams] = useState({
    message: "",
    campaign_id: "",
    campaign_version: "",
    agent: "",
  });
  const [attachment, setAttachment] = useState();
  const [isSendMessageClicked, setIsSendMessageClicked] = useState(false);
  const [shouldCleanThread, setShouldCleanThread] = useState(true)

  useEffect(() => {
    if (selectedAccount?.id) {
      fetchConciergeAgent(selectedAccount.id);
    }
  }, [selectedAccount])

  const fetchConciergeAgent = async (customer) => {
    try {
      setIsLoadingConcierge(true);
      const response = await API.graphql({
        query: getAgent,
        variables: {input: {customer_id: customer, domain: "ucp" }},
      });
      const agents = JSON.parse(response.data.getAgent.body).filter(agent => agent.provider === 'openai');
      const conciergeAgent = agents[0];

      if (conciergeAgent) {
        setConciergeAgent(conciergeAgent)
        setAgentParams((prevParams) => ({
          ...prevParams,
          agent: conciergeAgent?.id,
        }));
      } else {
        setMessages([{ message: "Error: UCP agent for this account does not exist.", type: "received" }])
      }
    } catch (error) {
      console.error("Error getting Agents", error);
      console.log('Error fetching agents');
    } finally {
      setIsLoadingConcierge(false);
    }
  };

  const handleCleanThread = () => {
    setMessages([initialMessage]);
    setShouldCleanThread(true);
  }

  const handleSendMessage = async () => {
    if (!newMessage) return;
    setIsSendMessageClicked(true);

    setNewMessage("");
    const isAnnUCP = authStore.selectedAccount.id.startsWith('ann')
    const ucpData = isAnnUCP ? annUnifiedCustomerProfileStore : unifiedCustomerProfileStore
    const params = {
      ...agentParams,
      message: `Context information: customer = ${ucpData.ucp.name}, ${ !isAnnUCP ? `, VIN = ${ucpData.selectedCar?.vin}` : ''}. This is a question based only on context customer information (do not query information or answer questions unrelated to it): ${newMessage}.`,
      is_concierge: false,
      metadata: JSON.stringify({
        segment_id: ucpData.ucp.segment_id,
        customer_name: ucpData.ucp.name,
        vin: ucpData.selectedCar?.vin
      }),
      customer_id: selectedAccount.id,
      attachment: JSON.stringify({}),
      create_new_thread: shouldCleanThread,
    };

    if(attachment){
      params['attachment'] = JSON.stringify({file_name: attachment['fileName'], data: attachment['data']})
    }

    try {
      let openAiAnswer = null;

      let local_msgs = []
      local_msgs = _.union(messages, [])


      let resultConversationOpenAi = null
      try {
        resultConversationOpenAi = await API.graphql({
          query: getConversationChatAssistant,
          variables: {input: params},
        });
        if (!resultConversationOpenAi.errors?.length) {
          setAgentIsTyping(true);

          const apiRespnse = resultConversationOpenAi.data.getConversationChatAssistant;

          const parseResponseBody = (responseBody) => JSON.parse(responseBody);

          local_msgs = _.union(local_msgs, [{message: newMessage, type: "sent"}])
          let tmpMsg  = "";
          let initialMessages = [...local_msgs];
          let isStreaming = false;
          setMessages(local_msgs);
          setAttachment({});

          // Subscribe to chat assistant response
          let assistantResponseSub = API.graphql(
            graphqlOperation(onChatAssistantResponse, {id: apiRespnse.id})
          ).subscribe({
            next: ({ value }) => {
              const subApiRespnse = value.data.onChatAssistantResponse;
              const body = parseResponseBody(
                  subApiRespnse.body
              )
              openAiAnswer = body.answer;
              setShowSpinner(false);
              setAgentIsTyping(false);

              if (subApiRespnse.status == 'done') {
                  if(!isStreaming){
                      const newMessages = [...initialMessages, {message: openAiAnswer, type: "received"}];
                      setMessages(newMessages)
                      initialMessages = [...newMessages];
                  }
                  assistantResponseSub.unsubscribe();
                  tmpMsg = '';
                  initialMessages = [...messages];
                  isStreaming = false;
                  setIsSendMessageClicked(false);
              } else{
                  isStreaming = true;
                  tmpMsg += openAiAnswer
                  setMessages([...initialMessages, {message: tmpMsg, type: "received"}])
              }
            },
            error: (error) => {
              console.warn(error)
              setShowSpinner(false);
              setAgentIsTyping(false);
              local_msgs = _.union(local_msgs, [{
                message:
                  "Oops! Something went wrong. Please try again.",
                type: "received",
              }])
              setMessages(local_msgs);
            }
          });

        }
        setShouldCleanThread(false);
      } catch (err) {
        console.log(err)
        setMessages([
          ...messages,
          {
            message:
              "Oops! Something went wrong. Please try again.",
            type: "received",
          },
        ]);
      }
    } catch (error) {
      console.error(error);

      if (
        error.errors &&
        error.errors[0].errorType === "Lambda:ExecutionTimeoutException"
      ) {
        setMessages([
          ...messages,
          {
            message:
              "Sorry, I'm taking longer than expected to get your answer. I'll notify my team to teach me more about this topic. main while, lets continue with other questions you have?",
            type: "received",
          },
        ]);
      }
    }
  };

  return (
    <AssistantWrapper>
      {showSpinner && <Spinner className="spinner"/>}
      <ChatBox messages={messages} agentIsTyping={agentIsTyping} />
      <RichInput
        onSend={handleSendMessage}
        isLoading={showSpinner}
        onChange={setNewMessage}
        onFileUploaded={setAttachment}
        disableSend={isSendMessageClicked || !conciergeAgent}
        onCleanThread={handleCleanThread}
      />
    </AssistantWrapper>
  );
});

export default Assistance;