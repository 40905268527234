import React from 'react';
import {
  CardWrapper,
  Avatar,
  UserInfo,
  UserName,
  UserStatus,
  StatusDot,
  StatusText,
  Divider
} from './UserProfileCard.styled';
import { IconButton } from '../../../../../../../../../elements';
import defaultImage from "../../../../../../../../../images/Jarvis.png";

const UserProfileCard = ({ avatarUrl, userName, userRole, isActive, isPublic }) => {
  return (
    <CardWrapper>
      <Avatar src={avatarUrl || defaultImage} alt={`${userName}'s avatar`} />
      <UserInfo>
        <UserName>{userName} <span>{userRole}</span></UserName>
        <UserStatus>
          <div>
            <StatusDot />
            {isActive ? 'Active' : 'Inactive'}
          </div>
          <div>
            <Divider></Divider>
            <IconButton icon="building" width="20px" height="20px" />
          </div>
          <StatusText>
            {isPublic ? 'Public' : 'Private'}
          </StatusText>
        </UserStatus>
      </UserInfo>
    </CardWrapper>
  );
};

export default UserProfileCard;
