import React, {useEffect, useRef, useState, useLayoutEffect} from 'react';
import { API, graphqlOperation } from 'aws-amplify';

import {getAgent, getConversationChatAssistant} from '../../../../graphql/queries';
import CustomerSelect from "../../../../components/common/CustomersSelect";
import {onChatAssistantResponse} from "../../../../graphql/subscriptions";

import {
    ChatInput,
    ChatContent,
    ConciergeContainer,
    CenteredText,
    Spinner,
} from './ConciergeChat.styled';
import _ from "lodash"
import {observer} from "mobx-react";
import {useStore} from "../../../../hooks";


const ConciergeChat = observer(({ isConciergeCollapsed, onClose }) => {
    const { authStore } = useStore();
    const [agentIsTyping, setAgentIsTyping] = useState(false);
    const { customerId, isSuperAdmin, selectedAccount } = authStore;
    const [newMessage, setNewMessage] = useState('');
    const [showSpinner, setShowSpinner] = useState(false);
    const [isLoadingConcierge, setIsLoadingConcierge] = useState(false);
    const initialMessage = { message: "What can I assist you with today?", type: "received" }
    const [messages, setMessages] = useState([initialMessage]);
    const [conciergeAgent, setConciergeAgent] = useState();
    const [agent_params, setAgent_params] = useState({
        message: "",
        campaign_id: "",
        campaign_version: "",
        agent: "",
    });
    const [attachment, setAttachment] = useState();
    const [chatWindowHeight, setChatWindowHeight] = useState(70);

    const [isSendMessageClicked, setIsSendMessageClicked] = useState(false);

    const textAreaRef = useRef();

    useEffect(() => {
        setMessages([initialMessage]);
        setAgent_params((prevParams) => ({
            ...prevParams,
            customer_id: selectedAccount.id,
        }));
        fetchConciergeAgent(selectedAccount.id);
    }, [selectedAccount]);

    const fetchConciergeAgent = async (customer) => {
        try {
            setIsLoadingConcierge(true);
            const response = await API.graphql({
                query: getAgent,
                variables: {input: {customer_id: customer, domain: "concierge" }},
            });
            const agents = JSON.parse(response.data.getAgent.body).filter(agent => agent.provider === 'openai');
            const conciergeAgent = agents[0];
            setConciergeAgent(conciergeAgent)
            setAgent_params((prevParams) => ({
                ...prevParams,
                agent: conciergeAgent?.id,
            }));
        } catch (error) {
            console.error("Error getting Agents", error);
            console.log('Error fetching agents');
        } finally {
            setIsLoadingConcierge(false);
        }
    };

    useLayoutEffect(() => {
        const textAreaWrapper = textAreaRef.current;
        if (textAreaWrapper) {
          const textArea = textAreaWrapper.getElementsByTagName('textarea')[0]
    
          textArea.style.height = "0px";
          const scrollHeight = textArea.scrollHeight;

          setChatWindowHeight(Math.max(58, 74 - (100 * scrollHeight / window.innerHeight)))
          textArea.style.height = scrollHeight + "px";
        }
      }, [newMessage]);


    const handleSendMessage = async () => {
        if (newMessage.trim() === "") return;

        setIsSendMessageClicked(true);

        setNewMessage("");

        const params = {
            ...agent_params, 
            message: newMessage, 
            is_concierge: true,
            attachment: JSON.stringify({})
        };

        if(attachment){
            params['attachment'] = JSON.stringify({file_name: attachment['fileName'], data: attachment['data']})
        }

        try {

            let openAiAnswer = null;

            // Try sending the request a maximum of maxRetries times
            let local_msgs = []
            local_msgs = _.union(messages, [])


            let resultConversationOpenAi = null
            try {
                resultConversationOpenAi = await API.graphql({
                    query: getConversationChatAssistant,
                    variables: {input: params},
                });
                if (!resultConversationOpenAi.errors?.length) {
                    setAgentIsTyping(true);

                    const apiRespnse = resultConversationOpenAi.data.getConversationChatAssistant;

                    const parseResponseBody = (responseBody) => JSON.parse(responseBody);

                    local_msgs = _.union(local_msgs, [{message: newMessage, type: "sent"}])
                    let tmpMsg  = "";
                    let initialMessages = [...local_msgs];
                    let isStreaming = false;
                    setAttachment({});
                    setMessages(local_msgs);

                    // Subscribe to chat assistant response
                    let assistantResponseSub = API.graphql(
                        graphqlOperation(onChatAssistantResponse, {id: apiRespnse.id})
                    ).subscribe({
                        next: ({ value }) => {
                            const subApiRespnse = value.data.onChatAssistantResponse;
                            const body = parseResponseBody(
                                subApiRespnse.body
                            )
                            openAiAnswer = body.answer;
                            setShowSpinner(false);
                            setAgentIsTyping(false);

                            if (subApiRespnse.status == 'done') {
                                if(!isStreaming){
                                    const newMessages = [...initialMessages, {message: openAiAnswer, type: "received"}];
                                    setMessages(newMessages)
                                    initialMessages = [...newMessages];
                                }
                                assistantResponseSub.unsubscribe();
                                tmpMsg = '';
                                initialMessages = [...messages];
                                isStreaming = false;
                                setIsSendMessageClicked(false);
                            } else{
                                isStreaming = true;
                                tmpMsg += openAiAnswer
                                setMessages([...initialMessages, {message: tmpMsg, type: "received"}])
                            }
                        },
                        error: (error) => {
                            console.warn(error)
                            setShowSpinner(false);
                            setAgentIsTyping(false);
                            local_msgs = _.union(local_msgs, [{
                                    message:
                                        "Oops! Something went wrong. Please try again.",
                                    type: "received",
                                }])
                            setMessages(local_msgs);
                            // scrollToBottom();
                        }
                    });

                }
            } catch (err) {
                console.log(err)
                setMessages([
                    ...messages,
                    {
                        message:
                            "Oops! Something went wrong. Please try again.",
                        type: "received",
                    },
                ]);
                // scrollToBottom();
            }
            // setIsChatQuestionsVisible(false);
        } catch (error) {
            console.error(error);

            if (
                error.errors &&
                error.errors[0].errorType === "Lambda:ExecutionTimeoutException"
            ) {
                setMessages([
                    ...messages,
                    {
                        message:
                            "Sorry, I'm taking longer than expected to get your answer. I'll notify my team to teach me more about this topic. main while, lets continue with other questions you have?",
                        type: "received",
                    },
                ]);
                // scrollToBottom();
            }
        }
    };

    const bottomRef = useRef(null);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        bottomRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
    };

    if (isConciergeCollapsed) {
        return null;
    }

    return (
        <>  
            <ConciergeContainer>
                { isLoadingConcierge ?
                    <CenteredText>{'Getting concierge agent...'}</CenteredText> :
                    conciergeAgent ?
                        <>
                            <ChatContent
                                messages={messages}
                                bottomRef={bottomRef}
                                showSpinner={showSpinner}
                                displayButtons={false}
                                width='100%'
                                agentIsTyping={agentIsTyping}
                                height={`${chatWindowHeight}vh`}
                                />
                            {showSpinner && <Spinner className="spinner"/>}
                            <ChatInput
                                textAreaRef={textAreaRef}
                                onChange={(e) => setNewMessage(e.target.value)} 
                                showSpinner={showSpinner}
                                newMessage={newMessage}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey && !showSpinner) {
                                        e.preventDefault();
                                        if(!isSendMessageClicked){
                                            handleSendMessage();
                                        }
                                    }
                            }} handleSendMessage={handleSendMessage} onFileUploaded={setAttachment} disableSend={isSendMessageClicked}/> 
                        </> :
                        <CenteredText>
                            { isSuperAdmin ?
                                `Concierge agent is not created yet for the selected customer.` :
                                'Concierge agent is not created yet.'
                            }
                        </CenteredText>
                }
            </ConciergeContainer>
        </>
    )    
});

export default ConciergeChat;