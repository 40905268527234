import { useState } from "react";
import {
    GenericListContainer,
    Header,
    Title
} from "../GenericList/GenericList.styled";
import RoundedIconButton from "../Buttons/RoundedIconButton/RoundedIconButton";
import ActionItem from "./ActionItem";
import ActionLibrary from "./components/ActionLibrary";
import { actions } from "./consts";

const ActionsList = ({agentActions, onSave}) => {
    const [openLib, setOpenLib] = useState(false);

    return (
        <div> 
            <Header>
                <Title>Actions</Title>
                <RoundedIconButton icon={'circleAdd'} width='24px' height='24px' onClick={() => setOpenLib(true)}/>
            </Header>
            <GenericListContainer>
                {agentActions && agentActions.map((action, idx) => 
                    (actions[action] && <ActionItem key={idx} icon={actions[action].icon} title={actions[action].title} subtitle={actions[action].description}/>)
                )}
            </GenericListContainer>
            <ActionLibrary isOpen={openLib} onClose={() => setOpenLib(false)} initialAgentActions={agentActions} onSave={onSave}/>
        </div>
    )
}

export default ActionsList