import React, { useState } from 'react';
import GenericActionCard from './GenericActionCard';
import TemplateEditor from "../../../../../../../elements/v2/TemplateEditor/TemplateEditor";
import { observer } from 'mobx-react';

const ActionCards = observer(({ options, openModalAddAction, openConfirmationModal }) => {

	const [isTemplateEditorOpen, setIsTemplateEditorOpen] = useState(false);

	const openTemplateEditor = () => {
		setIsTemplateEditorOpen(true);
	};

	const closeTemplateEditor = () => {
		setIsTemplateEditorOpen(false);
	};

	const customActions = [
		{ iconName: "duplicateOutline", onClick: () => { } },
		{ iconName: "editOutline", onClick: () => openModalAddAction(options.type, options.icon, options.title, options.description) },
		{ iconName: "deleteOutline", onClick: () => openConfirmationModal("Delete Confirmation", "Are you sure you want to delete this item?") }
	];

	return (
		<>
			<GenericActionCard
				options={options}
				openModalAddAction={openModalAddAction}
				openConfirmationModal={openConfirmationModal}
				customActions={customActions}
				onSettingsClick={openTemplateEditor}
			/>
			<TemplateEditor isOpen={isTemplateEditorOpen} onClose={closeTemplateEditor} />
		</>
	);
});

export default ActionCards;