import { action, makeObservable, observable, flow } from "mobx";
import { API } from "aws-amplify";
import { getCustomers } from "../../graphql/queries";
import { getUploadUrlFile, getContacts, getContactsAnn } from "../../graphql/queries";
import axios from "axios";

class ContactStore {
    account = '';
    id = "";
    moduleSelect = '';
    campaignName = '';
    campaignType = '';
    startDate = null;
    endDate = null;
    fileNames = [];
    columnNames = [];
    moduleList = [];
    typeList = [];
    file = '';
    accountName = '';
    moduleName = '';
    campaignTypeName = '';
    isLoading = false;
    isError = false;
    isSuccessfullModalOpen = false;
    selectedOptions = {};
    selectedFiles = [];
    isUploading = false;
    columnContents = {};
    areAllFieldsDone = false;
    buttonUpload = false;
    showCustomSelectFirstName = false;
    showCustomSelectLastName = false;
    showCustomSelectEmail = false;
    showCustomSelectPhone = false;
    showCustomSelectZipCode = false;
    errorMessage = '';
    contacts = []

    constructor() {
        makeObservable(this, {
            selectedFiles: observable,
            isUploading: observable,
            setIsUploading: action,
            account: observable,
            id: observable,
            moduleSelect: observable,
            campaignName: observable,
            campaignType: observable,
            startDate: observable,
            endDate: observable,
            file: observable,
            moduleList: observable,
            typeList: observable,
            isLoading: observable,
            isError: observable,
            fileNames: observable,
            columnNames: observable,
            selectedOptions: observable,
            columnContents: observable,
            areAllFieldsDone: observable,
            buttonUpload: observable,
            accountName: observable,
            moduleName: observable,
            campaignTypeName: observable,
            showCustomSelectFirstName: observable,
            showCustomSelectLastName: observable,
            showCustomSelectEmail: observable,
            showCustomSelectPhone: observable,
            showCustomSelectZipCode: observable,
            errorMessage: observable,
            setShowCustomSelectFirstName: action,
            setShowCustomSelectLastName: action,
            setShowCustomSelectEmail: action,
            setShowCustomSelectPhone: action,
            setShowCustomSelectZipCode: action,
            onChangeMessage: action,
            setAreAllFieldsDone: action,
            setColumnNames: action,
            setColumnContents: action,
            getFileNamesFromPaths: flow,
            handleFieldSelect: action,
            onChangeModule: action,
            onChangeType: action,
            onChangeName: action,
            onChangeAccount: action,
            setIsSuccessfulModalOpen: action,
            setUploadFile: action,
            setStartDate: action,
            setEndDate: action,
            setError: action,
            isSuccessfullModalOpen: observable,
            onCompleteCreation: flow,
            contacts: observable,
            getContacts: flow,
            onClearError: action,

        });
    }

    onChangeModule = (name, value) => {
        this.moduleSelect = value.trim();
        this.moduleName = name;
    }

    onClearError = () => {
        this.isError = false;
    }

    onChangeType = (name, value) => {
        this.campaignType = value.trim();
        this.campaignTypeName = name;
    }

    onChangeName = (value) => {
        this.campaignName = value;
    }

    onChangeAccount = (value) => {
        this.account = value;
    }

    setIsSuccessfulModalOpen(value) {
        this.isSuccessfullModalOpen = value;
    };

    onValidateTextField = (field) => {
        return this[field].length > 0;
    }

    onValidateObjectField = (field) => {
        return Object.keys(this[field]).length;
    }

    removeSpecialCharacters = (string) => {
        const withoutSpaces = string.replace(/\s/g, '_');
        const withoutSpecialChars = withoutSpaces.replace(/[^.\w\s]/gi, '');
        return withoutSpecialChars;
    };

    *onCompleteCreation(succesCallback){
        this.isLoading = true;
        try {
          
            for (const file of this.selectedFiles) {

                const fileName = this.removeSpecialCharacters(file.name);
                const response = yield API.graphql({
                  query: getUploadUrlFile,
                  variables: {
                    input: {
                      customer: this.account,
                      file_name: fileName,
                      file_type: file.type,
                      file_route: "contact_list",
                    },
                  },
                });
        
                const uploadUrl = JSON.parse(
                  response.data.getUploadUrlFile?.body
                );
                const formData = new FormData();
                formData.append("file", file);
        
                const config = {
                  
                  headers: {
                    "Content-Type": file.type,
                  },
                };
        
                yield axios.put(uploadUrl, file, config);

            }

            this.setIsSuccessfulModalOpen(true);
            this.onClearNewCampaign();
            this.isLoading = false;
            this.isError = false;
        } catch (err) {
            this.isLoading = false;
            this.isError = true;
        }
        finally {
            succesCallback()
        }

    }

    setError(errorMessage) {
        this.errorMessage = errorMessage;
    }

    onClearNewCampaign() {
        this.campaignName = '';
        this.campaignType = '';
        this.account = '';
        this.id = "";
        this.accountName = '';
        this.moduleName = '';
        this.campaignTypeName = '';
        this.setColumnNames([]);
        this.setColumnContents([]);
        this.setIsUploading(false);
        this.buttonUpload = false;
        this.setShowCustomSelectFirstName(false);
        this.setShowCustomSelectLastName(false);
        this.setShowCustomSelectEmail(false);
        this.setShowCustomSelectPhone(false);
        this.setShowCustomSelectZipCode(false);
        this.setAreAllFieldsDone(false);
        this.setSelectedFiles([]);
    }

    *getFileNamesFromPaths(filePaths) {
        this.fileNames = filePaths.map((filePath) => {
            const pathParts = filePath.split('/');
            const fileName = pathParts[pathParts.length - 1];
            return { name: fileName, value: filePath };
        });
    }

    setColumnNames(names) {
        this.columnNames = names;
    }

    handleFieldSelect = (option, fieldName) => {
        this.selectedOptions[fieldName] = option.value;
    }

    *getContacts(customer){
        try {
            const queryForContacts = customer.startsWith('ann') ? getContactsAnn : getContacts;
            this.isLoading = true;
            const response = yield API.graphql({
                query: queryForContacts,
                variables: {
                    input: { customer: customer, segment_id: '' },
                },
            });
            this.contacts = JSON.parse(response.data[customer.startsWith('ann') ? "getContactsAnn" : "getContacts"].body);
        } catch (error) {
            console.log(error)
            this.isError = true;
        } finally {
            this.isLoading = false;
        }

    }

    setSelectedFiles(files) {
        this.selectedFiles = files;
    }

    setIsUploading(value) {
        this.isUploading = value;
    }

    setColumnContents(contents) {
        this.columnContents = contents;
    }

    setAreAllFieldsDone(value) {
        this.areAllFieldsDone = value;
    }

    onChangeMessage(index, value) {
        this.messages[index] = value.trim();
    }

    setStartDate(date) {
        this.startDate = date;
    }

    setEndDate(date) {
        this.endDate = date;
    }

    setUploadFile(value) {
        this.buttonUpload = value;
    }

    setShowCustomSelectFirstName(value) {
        this.showCustomSelectFirstName = value;
    }

    setShowCustomSelectLastName(value) {
        this.showCustomSelectLastName = value;
    }

    setShowCustomSelectEmail(value) {
        this.showCustomSelectEmail = value;
    }

    setShowCustomSelectPhone(value) {
        this.showCustomSelectPhone = value;
    }
    setShowCustomSelectZipCode(value) {
        this.showCustomSelectZipCode = value;
    }
}

export default ContactStore;
