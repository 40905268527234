import {Icon, RoundedButton} from "../../../../../elements";
import React, {useCallback, useMemo, useState} from "react";
import {
  FilterByOptionsWrapper,
  ButtonCloseWrapper,
  FilterByWrapper,
  HeaderOptions,
  FilterOptions,
  FilterOption,
} from "./FilterBy.styled";
import CustomSelect from "../../../../../elements/select/CustomSelect";

const FilterBy = ({onSelect}) => {
  const [openFilterOptions, setOpenFilterOptions] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    segment: "",
    zipCode: "",
    status: "",
  });

  const options = useMemo(() => [
    {name: "Option 1", value: "option1"},
    {name: "Option 2", value: "option2"},
    {name: "Option 3", value: "option3"},
    {name: "Option 4", value: "option4"},
    {name: "Option 5", value: "option5"},
  ], []);

  const onHandleClick = () => {
    if(openFilterOptions === false) { setOpenFilterOptions(true); }
  };

  const onCloseFilter = () => {
    setOpenFilterOptions(false);
  };

  const onHandleSelectedFilter = () => {
    setOpenFilterOptions(false);
    onSelect && onSelect(selectedFilter);
  };

  const onSelectSegment = useCallback((value) => {
    setSelectedFilter({
      ...selectedFilter,
      segment: value
    });
  }, [selectedFilter, openFilterOptions]);

  const onSelectZipCode = useCallback((value) => {
    setSelectedFilter({
      ...selectedFilter,
      zipCode: value
    });
  }, [selectedFilter]);

  const onSelectStatus = useCallback((value) => {
    setSelectedFilter({
      ...selectedFilter,
      status: value
    });
  }, [selectedFilter]);

  return (
    <FilterByWrapper onClick={onHandleClick}>
      <Icon name={"tuneIcon"} height={'24px'} width={'24px'} />
      <FilterByOptionsWrapper open={openFilterOptions}>
        <ButtonCloseWrapper onClick={onCloseFilter}><Icon name={'close'} height={'16x'} width={'16px'} /></ButtonCloseWrapper>
        <HeaderOptions>Filters</HeaderOptions>
        <FilterOptions>
          <FilterOption>
            <h3>By Segments</h3>
            <CustomSelect
              placeholder={"Search for Segment"}
              handleSelect={onSelectSegment}
              options={options}
            />
          </FilterOption>
          <FilterOption>
            <h3>By Zip Code</h3>
            <CustomSelect
              placeholder={"Search for Postal / Zip Code"}
              handleSelect={onSelectZipCode}
              options={options}
            />
          </FilterOption>
          <FilterOption>
            <h3>By Status</h3>
            <CustomSelect
              placeholder={"Search a Status"}
              handleSelect={onSelectStatus}
              options={options}
            />
          </FilterOption>
        </FilterOptions>
        <RoundedButton width={"302px"} height={"46px"} onClick={onHandleSelectedFilter}>Apply</RoundedButton>
      </FilterByOptionsWrapper>
    </FilterByWrapper>
  );
}

export default FilterBy;