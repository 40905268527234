import {observer} from "mobx-react";
import React, {useCallback, useEffect} from "react";
import {
  ButtonCloseModal,
  CreateCustomerStepperWrapper, ErrorWrapper,
  LogoImageWrapper,
  ModalFrame,
  ModalWrapper
} from "./CreateCustomerStepper.styled";
import {DefaultStepper, Icon, IconButton} from "../../../elements";

import CompanyStep from "./components/CompanyStep";
import LocationStep from "./components/LocationStep";
import DomainStep from "./components/DomainStep";
import {useStore} from "../../../hooks";
import {useNavigate} from "react-router-dom";

const CreateCustomerStepper: React.FC = observer(() => {
  const { customer } = useStore();
  const navigate = useNavigate();

  customer.setCurrentPage("create");

  const onHandleFinish = useCallback(() => {
    customer.currentPage.onSubmitData();
  }, [customer.currentPage]);

  const onCloseModal = useCallback(() => {
    customer.currentPage.reset();
    navigate('/customers');
  }, []);

  return (
    <CreateCustomerStepperWrapper>
      <ModalWrapper $isOpen={customer.currentPage.status === 'done'}>
        <ModalFrame>
          <ButtonCloseModal>
            <IconButton icon="close" width="20px" height="20px" onClick={onCloseModal} />
          </ButtonCloseModal>
          <Icon name="warning" width="59px" height="59px" />
          <h2>Congratulations</h2>
          <p>A new account has been successfully created.</p>
        </ModalFrame>
      </ModalWrapper>
      <LogoImageWrapper>
        <Icon name="brain" width="194px" height="194px" />
      </LogoImageWrapper>
      {customer.currentPage.status === 'error' && <ErrorWrapper variant="outlined" severity="error">Error submitting customer form!</ErrorWrapper>}
      <DefaultStepper
        stepperActionsWidth={"494px"}
        stepperErrorWidth={"494px"}
        onFinish={onHandleFinish}
        status={customer.currentPage.isLoading ? 'loading' : customer.currentPage.isError ? 'error' : 'idle'}
        >
        <DefaultStepper.Step
          isBack={false}
          isComplete={customer.currentPage.isNameComplete}
          validationErrorLabel="Company Name is required!">
          <CompanyStep />
        </DefaultStepper.Step>
        <DefaultStepper.Step
          isComplete={customer.currentPage.isLocationComplete}
          validationErrorLabel="Company location is required!">
          <LocationStep />
        </DefaultStepper.Step>
        <DefaultStepper.Step
          isComplete={customer.currentPage.isDomainComplete}
          labelNext="Complete"
          validationErrorLabel="Company domain is required!">
          <DomainStep />
        </DefaultStepper.Step>
      </DefaultStepper>
    </CreateCustomerStepperWrapper>
  );
});

export default CreateCustomerStepper;
