import styled from "styled-components";
import { Fab } from '@mui/material';

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-template-rows: ${props => props.title ? '50px 1fr 50px' : '0px 1fr 50px'};
    grid-template-areas: "menu header" "menu content" "menu footer";
    height: 100vh;
    overflow: hidden;
`;

const MenuWrapper = styled.div`
    grid-area: menu;
    overflow-x: hidden;
    width: ${(props) => (props.isCollapsed ? '60px' : '250px')};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.1s ease-in-out;
    z-index: 99;
`;

const FloatingChatWrapper = styled.div`
    background-color: ${(props) => props.theme.colors.white};
    overflow-x: hidden;
    overflow-y: hidden;
    width: ${(props) => (props.isCollapsed ? '0px' : '25rem')};
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
    box-shadow: -9px 0px 9.100000381469727px 0px #0000001A;
`;

const OpenConciergeButton = styled(Fab)`
    && {
        position: fixed;
        right: 0;
        top: 5%;
    }
`;

const CloseConciergeButton = styled(Fab)`
    && {
        position: fixed;
        display: block;
        margin-left: -2rem;
        top: 50%;
    }
`;

const Menu = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-top: 100px;
    width: ${(props) => (props.isCollapsed ? '60px' : '250px')};
    transition: background 0.1s;
    background: linear-gradient(166deg, rgba(5,74,92,1) 0%, rgba(18,26,41,1) 30%, rgba(4,37,91,1) 100%);
    color: ${(props) => props.theme.colors.white};

    ul {
        width: 100%;
        list-style: none;
        padding: 0;
        overflow: ${(props) => (props.isCollapsed ? 'none' : 'auto')};
        height: 100vh;
    }

    li {
        display: flex;
        padding: 7px 0;
        box-sizing: border-box;
        height: 50px;
        align-items: center;

        a {
            width: 100%;
            display: flex;
            flex-direction: row;
            text-decoration: none;
            color: white;
            padding: 0 15px;

            & .link-menu {
                padding: 0 15px;
                line-height: 2;
                ${(props) => props.isCollapsed ? 'display: none' : 'display: inherit'};
                transition: display 0.1s ease-in-out;
            }
        }

        &:hover {
            background: ${(props) => props.theme.colors.activeBlue};
            border-radius: 10px;
        }
    }
`;

const AccountSelectorWrapper = styled.div`
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: row;

    &:hover {
        background: ${(props) => props.theme.colors.activeBlue};
        border-radius: 10px;
        cursor: pointer;
    }
`;

const AccountSelectorInitials = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 3px 10px;
    border-radius: 10px;
    ${(props) => props.theme.fonts.variant.extraBold}
`;

const AccountSelectorContent = styled.div`
    display: ${(props) => (props.isCollapsed ? 'none' : 'flex')};
    flex-direction: row;
    width: calc(100% - 70px);
    margin-right: 10px;
    align-items: center;
    justify-content: space-between;
`;

const AccountSelectorContentData = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    font-size: ${(props) => props.theme.fonts.size.small};
    box-sizing: border-box;
    width: calc(100% - 15px);

    & div {
        ${(props) => props.theme.fonts.variant.regular};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

const SubMenu = styled.div`
    position: absolute;
    width: 250px;
    height: 100vh;
    top: 0;
    right: -250px;
    display: none;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    background-color: ${(props) => props.theme.colors.greyNine};
    overflow-x: hidden;
    overflow-y: scroll;

    ul {
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        padding: 0 20px;
        height: 50px;
    }

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 5px;
        background-color: ${(props) => props.theme.colors.primary};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.colors.grey};
        border-radius: 5px;
        pointer-events: none;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: ${(props) => props.theme.colors.grey};
    }

    ${AccountSelectorWrapper}:hover & {
        display: block;
    }
`;

const MenuFooter = styled.div`
    background: linear-gradient(166deg, rgba(5,74,92,1) 0%, rgba(18,26,41,1) 30%, rgba(4,37,91,1) 100%);
    position: fixed;
    left: 0;
    bottom: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-end;
    cursor: pointer;
    width: ${(props) => (props.isCollapsed ? '60px' : '250px')};

    ul {
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        display: flex;
        padding: 7px 0;
        box-sizing: border-box;
        height: 50px;
        align-items: center;
        width: 100%;

        a {
            width: 100%;
            display: flex;
            flex-direction: row;
            text-decoration: none;
            color: white;
            padding: 0 15px;
            margin: 0;

            & .link-menu {
                padding: 0 15px;
                line-height: 2;
                ${(props) => props.isCollapsed ? 'display: none' : 'display: inherit'};
                transition: display 0.1s ease-in-out;
            }
        }
        transition: background 0.1s ease-in-out;
        &:hover {
            background: #585858;
        }
    }
`;

const Content = styled.div`
    grid-area: content;
    overflow-y: auto;
`;

const Header = styled.div`
    grid-area: header;
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: ${(props) => props.showDivision ? '1px' : '0'} solid ${(props) => props.theme.colors.border};
`;

const TitlePage = styled.div`
    ${(props) => props.theme.fonts.variant.bold}
    font-size: ${(props) => props.theme.fonts.size.medium};
    line-height: 23px;
    margin-left: 50px;
    letter-spacing: -0.57px;
`;

const MenuHeaderContainer = styled.ul`
    margin-top: 50%;
`;

const PageFooter = styled.footer`
    grid-area: footer;
    height: 50px;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.primary};
`;

export {
  Wrapper,
  MenuWrapper,
  Menu,
  SubMenu,
  MenuFooter,
  Content,
  Header,
  TitlePage,
  FloatingChatWrapper,
  OpenConciergeButton,
  CloseConciergeButton,
  PageFooter,
  AccountSelectorWrapper,
  AccountSelectorInitials,
  AccountSelectorContent,
  AccountSelectorContentData,
  MenuHeaderContainer
};
