import {action, makeObservable, observable} from "mobx";
import ListDataStore from "./page/ListDataStore";

const NotificationPageStrategy = {
  'list': new ListDataStore()
}

class NotificationStore {
  currentPage = undefined;

  constructor() {
    makeObservable(this, {
      currentPage: observable,
      setCurrentPage: action
    });

    this.setCurrentPage('list');
  }

  setCurrentPage(page) {
    this.currentPage = NotificationPageStrategy[page];
  }
}

export default NotificationStore;